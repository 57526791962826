import { Checkbox, Container, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Box, Grid, styled } from "@mui/material";
import axios from "axios";
import {
  AutoCompleteComponent,
  CheckboxInput,
  ModalError,
  ModalSuccess,
  MonthYearRangePicker,
  Page,
  PrimaryButton,
  SkeletonComponent,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { arrayToCommaSeparatedString, getCookie, getErrors } from "utils";
import { hardBaseUrl } from "../../../services/urlConstant";

function DownloadCCID() {
  const classes = useStyles();
  const token = localStorage.getItem("token");
  const userLogin = getCookie("auth", `${process.env.REACT_APP_NAME}_user`);
  const { publisher_id } = userLogin?.publisher || {};
  const state = useSelector(data => data?.publisherId);
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingPage, setLoadingPage] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    per_page: 10,
    publisher_id: publisher_id || state?.publisherId,
    dsp_id: "",
    start_date: moment().startOf("year"),
    end_date: moment().startOf("month"),
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);
  const [optionDsp, setOptionDsp] = useState([]);
  const [selectedCCID, setSelectedCCID] = useState([]);

  const allCCIDSelected = dataTable?.every(option =>
    selectedCCID?.some(selected => selected?.file_path === option?.file_path)
  );

  const handleSelectAllCCID = event => {
    const { checked } = event?.target || false;
    setSelectedCCID(checked ? dataTable : []);
  };

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };
  const handleSelectCCID = (event, option) => {
    const { checked } = event?.target || false;
    setSelectedCCID(prev =>
      checked
        ? [...prev, option]
        : prev.filter(item => item.file_path !== option.file_path)
    );
  };

  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/ccid/list`, {
        headers,
        params: {
          ...queryParams,
          start_date: formatDateFilter(queryParams?.start_date),
          end_date: formatDateFilter(queryParams?.end_date),
        },
      });
      const { data, meta } = res?.data;
      setDataTable(data || []);
      const pageCount = getTotalPage(meta?.total, queryParams?.per_page);
      setTableTotalPage(pageCount || 1);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const getOptionDsp = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(
        `${hardBaseUrl}/publishers/list/${publisher_id ||
          state?.publisherId}/dsps`,
        { headers }
      );
      const { data } = res?.data;
      const modifiedData = data?.map(item => ({
        ...item,
        id: item?.dsp_id,
        label: item?.name,
      }));
      setOptionDsp(modifiedData);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    getOptionDsp();
  }, []);
  useEffect(() => {
    if ((publisher_id || state?.publisherId) && queryParams?.dsp_id) {
      getDataTable();
    }
  }, [queryParams]);

  const createLinkCCID = url => {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    link.click();
  };

  const handleDownloadCCID = () => {
    const downloadUrl = `${hardBaseUrl}/ccid/download?files=${encodeURIComponent(
      arrayToCommaSeparatedString(selectedCCID, "file_path")
    )}&publisher_id=${publisher_id || state?.publisherId}`;

    createLinkCCID(downloadUrl);
    ModalSuccess("CCID data successfully downloaded");
  };

  const CustomCheckbox = styled(Checkbox)(({ customColor }) => ({
    "&.MuiCheckbox-root": {
      color: customColor || "#111827",
      "&.Mui-checked": {
        color: customColor || "#111827",
      },
    },
  }));

  const columnTable = [
    {
      name: "all",
      title: (
        <Box>
          <CheckboxInput
            checked={allCCIDSelected}
            onChange={handleSelectAllCCID}
          />
        </Box>
      ),
      renderText: item => (
        <Box>
          <CheckboxInput
            checked={selectedCCID?.some(
              selected => selected?.file_path === item?.file_path
            )}
            onChange={e => handleSelectCCID(e, item)}
          />
        </Box>
      ),
    },
    {
      name: "file_name",
      title: "File Name",
      renderText: item => item || "-",
    },
    {
      name: "file_size",
      title: "File Size",
      renderText: item => item || "-",
    },
  ];

  return (
    <Page className={classes.root} title="Download CCID">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle title="Download CCID" breadcrumbData={breadcrumbData} />
          <Divider className={classes.divider} />
          <Grid container columnSpacing={2} justifyContent="right" mb="16px">
            <Grid item>
              <AutoCompleteComponent
                label="DSP"
                options={optionDsp}
                value={
                  optionDsp.find(option => option.id === queryParams?.dsp_id) ||
                  null
                }
                onChange={value => handleChangeQueryParams(value, "dsp_id")}
                size="small"
                width={250}
              />
            </Grid>
            <Grid item>
              <MonthYearRangePicker
                label="Period"
                startDate={queryParams?.start_date}
                handleChangeStartDate={date =>
                  handleChangeQueryParams(date, "start_date")
                }
                endDate={queryParams?.end_date}
                handleChangeEndDate={date =>
                  handleChangeQueryParams(date, "end_date")
                }
                syncYear
              />
            </Grid>
          </Grid>
          <InnoTableV2
            isLoading={false}
            columns={columnTable}
            items={dataTable}
            page={queryParams?.page}
            rowsPerPage={queryParams?.per_page}
            totalPage={tableTotalPage}
            handleChangePage={(_, page) =>
              handleChangeQueryParams(page, "page")
            }
            handleChangeRowsPerPage={e =>
              handleChangeQueryParams(e?.target?.value, "per_page")
            }
          />
          {selectedCCID?.length > 0 && (
            <Grid container justifyContent="right" mt="16px">
              <PrimaryButton label="Download" onClick={handleDownloadCCID} />
            </Grid>
          )}
        </Container>
      )}
    </Page>
  );
}

const formatDateFilter = date => date.format("YYYY-MM-DD");
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
}));
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Download CCID",
    active: true,
  },
];

export default DownloadCCID;
