import { Container, Divider } from "@material-ui/core";
import { Box, Grid, Typography } from "@mui/material";
import axios from "axios";
import {
  CurrencyDisplay,
  CustomTable,
  ModalError,
  Page,
  PrimaryButton,
  SkeletonComponent,
  SongUsageTrendChart,
  SongUsageTrendSummarytCard,
} from "components";
import { HeaderTitle } from "layouts";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { globalStyles } from "styles";
import { arrayToCommaSeparatedString, getErrors } from "utils";
import { hardBaseUrl } from "../../../../services/urlConstant";

const PenciptaDetailReviewLagu = () => {
  const classes = globalStyles();
  const { id } = useParams();
  const { state } = useLocation();
  const { song, queryParams } = state;
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingPage, setLoadingPage] = useState(false);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [dataTable, setDataTable] = useState([]);

  const [csvArray, setCsvArray] = useState([]);
  const [songDetail, setSongDetail] = useState({
    composers: [],
    iswc: "",
    publisherName: "",
  });
  const [chartReport, setChartReport] = useState({
    revenue: 0,
    traffic: 0,
    month: "",
    totalRevenue: 0,
    totalTraffic: 0,
  });
  const params = {
    year: queryParams?.year,
    dsp_id: queryParams?.dsp_id,
  };
  const handleChangeChartReport = (value, key) => {
    setChartReport(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const onClickChartAxis = data => {
    if (chartData?.datasets.length > 0) {
      const { dataIndex, axisValue } = data;
      const monthName = moment(axisValue, "MMM YY").format("MMMM");
      const monthTotalTraffic = chartData?.datasets[0]?.listener?.[dataIndex];
      const monthTotalRevenue = chartData?.datasets[0]?.data?.[dataIndex];

      handleChangeChartReport(monthTotalTraffic, "traffic");
      handleChangeChartReport(monthTotalRevenue, "revenue");
      handleChangeChartReport(monthName, "month");
    }
  };

  const getResponse = async () => {
    try {
      const res = await axios.get(`${hardBaseUrl}/publisher/song-usage/${id}`, {
        headers,
        params,
      });
      const { data } = res?.data;
      const { usages } = data;
      setSongDetail({
        composers: data?.composers,
        iswc: data?.song?.iswc_code || "-",
        publisherName: arrayToCommaSeparatedString(data?.publishers),
      });
      setChartReport(currentState => ({
        ...currentState,
        totalRevenue: data?.total_royalty,
        totalTraffic: data?.total_listener,
      }));
      setDataTable(usages);

      const labels = usages?.map(item => moment(item.date).format("MMM YY"));
      const datasets = [
        {
          label: "",
          data: usages?.map(chartItem => chartItem?.base_currency_revenue),
          listener: usages?.map(chartItem => chartItem?.listener),
          color: "#9545EB",
          showMark: false,
        },
      ];
      setChartData({
        labels,
        datasets,
      });
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };

  const getCsvArray = async () => {
    try {
      const res = await axios.get(
        `${hardBaseUrl}/publisher/song-usage/${id}/download`,
        {
          headers,
          params,
        }
      );
      setCsvArray(res?.data?.data || []);
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };
  useEffect(() => {
    getResponse();
    getCsvArray();
  }, []);

  return (
    <Page className={classes.root} title="Song Usage Detail">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title={
              <Box>
                <Typography fontSize="24px">
                  Song Title:{" "}
                  <Typography component="span" fontSize="24px" fontWeight={700}>
                    {song?.title}
                  </Typography>
                </Typography>
                <Typography fontWeight={400} fontSize="18px">
                  {`Year of Song Usage: ${queryParams?.year}`}
                </Typography>
              </Box>
            }
            breadcrumbData={breadcrumbData}
            backButton
          />
          <Divider className={classes.divider} />
          <Box border="1px solid #D1D5DC" borderRadius="6px" p="24px">
            {songDetailList(songDetail)?.map((item, index) => (
              <Grid container key={index}>
                <Grid item xs={2}>
                  <Typography>{item?.title}</Typography>
                </Grid>
                <Grid item xs={10}>
                  <Box display="flex">
                    {item?.title === "Composer"
                      ? (item?.name || [])?.map((composer, index) => (
                          <Typography
                            key={index}
                            fontWeight={composer?.is_on_this_publisher && 700}
                          >
                            {`${index > 0 ? "," : ""} ${composer?.name}`}
                          </Typography>
                        ))
                      : item?.name || "-"}
                  </Box>
                </Grid>
              </Grid>
            ))}
          </Box>
          <Typography fontSize="24px" fontWeight={500} my="24px">
            Trend of Song Usage
          </Typography>
          <Box py="20px" border="1px solid #D1D5DC" borderRadius="6px">
            <SongUsageTrendChart
              chartData={chartData}
              onAxisClick={(_, data) => onClickChartAxis(data)}
            />
          </Box>
          <SongUsageTrendSummarytCard chartReport={chartReport} />
          <Grid container columnSpacing={2} my={2} justifyContent="right">
            <Grid item>
              <PrimaryButton
                label="Download PDF"
                onClick={() =>
                  history.push({
                    pathname: "/admin/review-lagu/detail/print-report",
                    state: {
                      song,
                      queryParams,
                      songDetailList: songDetailList(songDetail),
                      chartData,
                      chartReport,
                      dataTable,
                    },
                  })
                }
              />
            </Grid>
            <Grid item>
              <CSVLink data={csvArray} filename="song-detail.csv">
                <PrimaryButton label="Download CSV" />
              </CSVLink>
            </Grid>
          </Grid>
          <CustomTable columnTable={columnTable} data={dataTable} />
        </Container>
      )}
    </Page>
  );
};

const breadcrumbData = [
  {
    label: "Home",
    link: "/pencipta/dashboard-pencipta",
  },
  {
    label: "Song Usage",
    link: "/pencipta/review-lagu",
  },
  {
    label: "Song Detail",
    active: true,
  },
];
const songDetailList = songDetail => [
  {
    title: "ISWC Code",
    name: songDetail?.iswc,
  },
  {
    title: "Publisher",
    name: songDetail?.publisherName,
  },
  {
    title: "Composer",
    name: songDetail?.composers,
  },
];
const columnTable = [
  {
    name: "date",
    title: "Month",
    renderText: value => moment(value).format("MMMM"),
  },
  {
    name: "date",
    title: "Year",
    renderText: value => moment(value).format("YYYY"),
  },
  {
    name: "listener",
    title: "Traffic",
    headerAlign: "right",
    renderText: item => <CurrencyDisplay value={item} />,
  },
  {
    name: "base_currency_revenue",
    title: "Revenue",
    headerAlign: "right",
    renderText: item => <CurrencyDisplay value={item} prefix="Rp" />,
  },
];
export default PenciptaDetailReviewLagu;
