import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import axios from "axios";
import {
  AutoCompleteComponent,
  FormLabel,
  ModalError,
  ModalSuccess,
  PasswordInput,
  PrimaryButton,
  SecondaryButton,
  SelectInput,
  TextInput,
} from "components";
import { Fragment, useEffect, useState } from "react";
import { hardBaseUrl } from "services/urlConstant";
import { getErrors, validateEmail } from "utils";

const ModalFormUserSociety = ({ open, onClose, preload, getData }) => {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingButton, setLoadingButton] = useState(false);
  const [optionRole, setOptionRole] = useState([]);
  const [optionSociety, setOptionSociety] = useState([]);
  const [passwordError, setPasswordError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [payload, setPayload] = useState({
    username: "",
    email: "",
    phone: "",
    role_id: "",
    society_id: "",
    ...(!preload && {
      password: "",
      confirm_password: "",
    }),
  });
  const buttonDisabled =
    !payload?.name ||
    !payload?.email ||
    !payload?.role_id ||
    !payload?.society_id ||
    (preload && (!payload?.confirm_password || !payload?.password));

  const handleChangePayload = (value, key) => {
    setPayload(currentState => ({
      ...currentState,
      [key]: value,
    }));
    if (!preload && (key === "password" || key === "confirm_password")) {
      setPasswordError(null);
    }
    if (key === "email") {
      setEmailError(null);
    }
  };
  const getPreload = () => {
    setPayload(preload);
  };

  const getOptionRole = async () => {
    try {
      const res = await axios.get(`${hardBaseUrl}/role`, {
        headers,
        params: {
          page: 1,
          per_page: -1,
          type: "society",
        },
      });
      setOptionRole(res?.data?.data);
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    }
  };
  const getOptionSociety = async () => {
    try {
      const res = await axios.get(`${hardBaseUrl}/admin/society/`, {
        headers,
        params: {
          page: 1,
          per_page: -1,
          sort: "",
          search: "",
        },
      });
      const modifiedData = res?.data?.data?.map(item => ({
        ...item,
        label: item?.society_name,
      }));
      setOptionSociety(modifiedData);
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    }
  };
  const handleSubmit = async () => {
    const method = preload ? axios.put : axios.post;
    const { password, confirm_password, email } = payload;
    let hasError = false;
    setPasswordError(null);
    setEmailError(null);

    if (!preload && password !== confirm_password) {
      setPasswordError("Password and Confirm Password does not match");
      hasError = true;
    }

    if (!validateEmail(email)) {
      setEmailError(
        "Invalid email, please enter your email address in format: yourname@example.com"
      );
      hasError = true;
    }

    if (hasError) return;

    try {
      setLoadingButton(true);
      await method(
        `${hardBaseUrl}/user${preload ? `/update/${preload?.user_id}` : "/register"
        }`,
        payload,
        { headers }
      );
      onClose();
      ModalSuccess(
        `Successfully ${preload ? "Update" : "Create"} User Society`
      ).then(() => {
        setPayload({});
        getData();
      });
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    } finally {
      setLoadingButton(false);
    }
  };

  useEffect(() => {
    getOptionRole();
    getOptionSociety();
  }, []);

  useEffect(() => {
    getPreload();
  }, [preload]);

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{`${preload ? "Edit" : "Add"} User Society`}</DialogTitle>
      <DialogContent dividers>
        <Grid container direction="column" rowSpacing={2}>
          <Grid item>
            <FormLabel label="User Name" required />
            <TextInput
              placeholder="User Name"
              value={payload?.username}
              onChange={e => handleChangePayload(e?.target?.value, "username")}
              disabled={loadingButton}
            />
          </Grid>
          <Grid item>
            <FormLabel label="Email" required />
            <TextInput
              placeholder="Email"
              value={payload?.email}
              type="email"
              onChange={e => handleChangePayload(e?.target?.value, "email")}
              error={!!emailError}
              helperText={emailError}
              disabled={loadingButton}
            />
          </Grid>
          <Grid item>
            <FormLabel label="Phone Number" required />
            <TextInput
              placeholder="Phone Number"
              value={payload?.phone}
              onChange={e => handleChangePayload(e?.target?.value, "phone")}
              type="number"
              disabled={loadingButton}
            />
          </Grid>
          <Grid item>
            <FormLabel label="Society" required />
            <AutoCompleteComponent
              label={!payload?.society_id ? "Society" : ""}
              options={optionSociety}
              value={
                optionSociety.find(
                  option => option.id === Number(payload?.society_id)
                ) || null
              }
              onChange={value => handleChangePayload(value, "society_id")}
              width="100%"
              size="small"
            />
          </Grid>
          <Grid item>
            <FormLabel label="Role" required />
            <SelectInput
              label={!payload?.role_id ? "Role" : ""}
              options={optionRole}
              optionKey="role_id"
              optionLabel="name"
              value={payload?.role_id || ""}
              onChange={e => handleChangePayload(e?.target?.value, "role_id")}
              width="100%"
            />
          </Grid>
          {!preload && (
            <Fragment>
              <Grid item>
                <FormLabel label="Password" required />
                <PasswordInput
                  placeholder="Password"
                  value={payload?.password}
                  onChange={e =>
                    handleChangePayload(e?.target?.value, "password")
                  }
                  autoComplete="new-password"
                  error={!!passwordError}
                  helperText={passwordError}
                  disabled={loadingButton}
                />
              </Grid>
              <Grid item>
                <FormLabel label="Confirm Password" required />
                <PasswordInput
                  placeholder="Confirm Password"
                  value={payload?.confirm_password}
                  error={!!passwordError}
                  helperText={passwordError}
                  onChange={e =>
                    handleChangePayload(e?.target?.value, "confirm_password")
                  }
                  disabled={loadingButton}
                />
              </Grid>
            </Fragment>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <SecondaryButton label="Cancel" onClick={onClose} />
        <PrimaryButton
          label={
            loadingButton
              ? preload
                ? "Updating"
                : "Submitting"
              : preload
                ? "Update"
                : "Add"
          }
          loading={loadingButton}
          disabled={buttonDisabled || loadingButton}
          onClick={handleSubmit}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ModalFormUserSociety;
