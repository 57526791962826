import { Container, Divider } from "@material-ui/core";
import axios from "axios";
import {
  ButtonGroupTop,
  DateTimeDisplay,
  ModalError,
  ModalSuccess,
  Page,
  SkeletonComponent,
  StatusChip,
  TableAction,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { hardBaseUrl } from "services/urlConstant";
import { globalStyles } from "styles";
import { getErrors } from "utils";
import { breadcrumbData } from "../Components/SongClaimMenu";

function UnclaimMonitoring() {
  const classes = globalStyles();
  const history = useHistory();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const [loadingPage, setLoadingPage] = useState(false);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    size: 10,
    type: "UM",
  });
  const [tableData, setTableData] = useState([]);
  const [tablePageCount, setTablePageCount] = useState(1);

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };

  const getDataTable = async () => {
    setLoadingPage(true);
    const url = `${hardBaseUrl}/process/datatable`;
    try {
      const res = await axios.get(url, { headers, params: queryParams });
      const resData = res?.data?.data;
      const resTotalSize = res?.data?.meta?.total;
      setTableData(resData);
      setTablePageCount(getTotalPage(resTotalSize, queryParams?.size));
    } catch (error) {
      ModalError(getErrors(error));
    } finally {
      setLoadingPage(false);
    }
  };
  const handleRetryUnclaimProcess = async id => {
    setLoadingPage(true);
    await axios
      .post(`${hardBaseUrl}/unclaim/retry/${id}`, {}, { headers })
      .then(() => {
        ModalSuccess("Successfully Retry Unclaim Process").then(() =>
          getDataTable()
        );
      })
      .catch(error => ModalError(getErrors(error)))
      .finally(() => setLoadingPage(false));
  };

  useEffect(() => {
    getDataTable();
  }, [queryParams]);

  return (
    <Page className={classes?.root} title="Unclaim Monitoring">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title="Unclaim Monitoring"
            breadcrumbData={breadcrumbData}
          />
          <Divider className={classes?.divider} />
          <ButtonGroupTop />
          <InnoTableV2
            isLoading={false}
            columns={columnTable}
            items={tableData || []}
            page={queryParams?.page}
            rowsPerPage={queryParams?.size}
            totalPage={tablePageCount}
            handleChangePage={(_, page) =>
              handleChangeQueryParams(page, "page")
            }
            handleChangeRowsPerPage={e =>
              handleChangeQueryParams(e?.target?.value, "size")
            }
            isHaveAction
            renderAction={item => (
              <TableAction
                handleView={() =>
                  history.push(
                    `/admin/publisher/unclaim-monitoring/${item?.id}`
                  )
                }
                tooltipView="View Detail"
                handleRestore={
                  item.status === "Error"
                    ? () => handleRetryUnclaimProcess(item?.id)
                    : null
                }
                tooltipRestore="Retry Process"
              />
            )}
          />
        </Container>
      )}
    </Page>
  );
}

const columnTable = [
  {
    name: "id",
    title: "Unclaim ID",
  },
  {
    name: "start_date_time",
    title: "Start Date",
    renderText: item => <DateTimeDisplay date={item} />,
  },
  {
    name: "end_date_time",
    title: "End Date",
    renderText: item => <DateTimeDisplay date={item} />,
  },
  {
    name: "status",
    title: "Status",
    renderText: status => (
      <StatusChip
        type={
          status === "Finish"
            ? "success"
            : status === "Error"
            ? "danger"
            : status === "Running"
            ? "warning"
            : ""
        }
        label={status === "Error" ? "Request Time Out" : status}
      />
    ),
  },
];
export default UnclaimMonitoring;
