import { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useHistory, useLocation, useParams } from "react-router";
import { Page } from "components";
import {
  Button,
  Container,
  Divider,
  FormControl,
  Box,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import axios from "axios";
import { InnoImage } from "inno-ui";
import { HeaderTitle } from "layouts";
import { PhotoshopPicker } from "react-color";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import UnggahLogo from "assets/img/upload.svg";
import { hardBaseUrl } from "services/urlConstant";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  textTitle: {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "32px",
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  colorPickerArea: {
    marginTop: "6px",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "10px"
  },
  button: {
    marginBottom: "20px",
    marginTop: "20px",
    backgroundColor: "black",
    alignSelf: "flex-end",
    color: "white",
    width: "135px",
    height: "40px",
    textTransform: "none",
    "&:hover": {
      color: "white",
      backgroundColor: "black",
    },
  },
  colorIdentity: props => ({
    backgroundColor: props.colorIdentity,
    cursor: "pointer"
  }),
  buttonCancel: {
    marginBottom: "20px",
    marginTop: "20px",
    border: "black solid 1px",
    backgroundColor: "white",
    alignSelf: "flex-end",
    width: "135px",
    height: "40px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  imageWrapper: {
    borderRadius: 5,
    backgroundColor: "#F9FAFB",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    alignItems: "center",
    padding: "1rem"
  },
  imgPreview: {
    maxWidth: "150px",
    maxHeight: "150px",
  },
  fileUploadArea: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  title: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "28px",
  },
  subTitle: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#6e6e6e",
  },
  label: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "500px",
    fontSize: "14px",
    lineHeight: "20px",
  },
  inputFieldsTlp: {
    borderLeft: "1px solid #D1D5DC",
    marginRight: 10,
    paddingLeft: "5px",
  },
  myTextField: {
    height: "44px",
    paddingBottom: "0px",
    paddingTop: "0px",
  },
  inputFields: {
    height: "44px",
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  inputFieldsUrl: {
    height: "44px",
    paddingTop: "0px",
    paddingBottom: "0px",
    borderRight: "1px solid #D1D5DC",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  rootSwitch: {
    width: "40px",
    height: "20px",
    padding: "0px",
    borderRadius: "10px",
    marginRight: "8px",
    position: "relative",
  },
  switchBase: {
    padding: "10px",
    color: "white",
    "&.Mui-checked": {},
    "&.Mui-checked + $track": {
      border: "none",
      color: "white",
      opacity: 1,
    },
  },
  checked: {},
  thumb: {
    color: "white",
    position: "absolute",
    width: "16px",
    height: "16px",
  },
  track: {
    backgroundColor: "#E4E7EB",
    borderRadius: "25px",
    border: "0px solid #606060",
    height: "auto",
    opacity: 1,
  },
  selectPadding: {
    "& .MuiSelect-outlined": {
      paddingTop: "11px",
      paddingBottom: "11px",
      paddingLeft: "20px",
      paddingRight: "30px",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const clientTypeList = [
  {
    id: 1,
    name: "DSP",
    value: "dsp",
  },
  {
    id: 2,
    name: "Non DSP",
    value: "non_dsp",
  },
];

const EditDsp = () => {
  const { id } = useParams();
  const history = useHistory();
  const { state } = useLocation();
  const [color, setColor] = useState("#000");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [file, setFile] = useState(null);
  const [file2, setFile2] = useState(null);
  const [objectUrl, setObjectUrl] = useState(null);
  const [dsp, setDsp] = useState("");
  const [nama, setNama] = useState("");
  const [telepon, setTelepon] = useState("");
  const [email, setEmail] = useState("");
  const inputFile = useRef(null);
  const [clientType, setClientType] = useState("");
  const [alamat, setAlamat] = useState("");
  const [currencyList, setCurrencyList] = useState([]);
  const [curr_id, setCurr_id] = useState("");
  const [trans_fee, setTrans_fee] = useState(0);
  const [domicilytype, setDomicilytype] = useState("");
  const [classification, setClassification] = useState("");
  const [listClassification, setListClassification] = useState([]);
  const [tempDataValueCurrencyFee, setTempDataValueCurrencyFee] = useState({
    currency_id: "",
    transfer_fee: 0,
  });
  const [uploadType, setUploadType] = useState("");
  const listDomicile = [
    { id: 1, name: "Domestic" },
    { id: 2, name: "Overseas" },
  ];
  const classes = useStyles({ colorIdentity: color });
  const theme = createTheme({
    typography: {
      fontFamily: ["Helvetica Neue"].join(","),
    },
  });
  const onChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case "dsp":
        setDsp(value);
        break;
      case "nama":
        setNama(value);
        break;
      case "color":
        setColor(value);
        break;
      case "telepon":
        setTelepon(value);
        break;
      case "email":
        setEmail(value);
        break;
      default:
        break;
    }
  };
  const onChangeFile = e => {
    const name = e.target.name;
    const data = e.target.files[0];
    const object = URL.createObjectURL(data);
    if (name === "photo") {
      setFile(data);
      setObjectUrl(object);
      setFile2(data);
    }
  };
  const getCurr = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/currency/datatable`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const params = {
      per_page: 99,
      page: 1,
    };
    axios
      .get(url, { headers, params })
      .then(res => {
        setCurrencyList(res?.data?.data);
      })
      .catch(err => {
        throw err;
      });
  };
  const getDetail = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/dsp/${state.customer.master_client_id}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(url, { headers })
      .then(res => {
        const data = res?.data?.data;
        setDsp(data?.name);
        setUploadType(data?.upload_type);
        setColor(data?.color);
        setNama(data?.person_name);
        setEmail(data?.person_email);
        setTelepon(data?.person_phone);
        setAlamat(data?.address);
        setTrans_fee(data?.transaction_fee);
        setClassification(data?.client_classification.client_classification_id);
        setClientType(data?.type);
        setCurr_id(data?.currency_id);
        setTempDataValueCurrencyFee({
          currency_id: data?.currency_id,
          transfer_fee: data?.transaction_fee,
        });
        setDomicilytype(
          listDomicile?.[
            listDomicile?.findIndex(
              el => el.name.toLowerCase() === res?.data?.data?.domicile
            )
          ]?.id || ""
        );
        if (
          data?.profile_image === null ||
          data?.profile_image === ""
        ) {
          setFile(null);
        } else {
          setFile(data?.profile_image);
          setObjectUrl(data?.profile_image);
        }
      })
      .catch(err => {
        console.log("err detail", err);
      });
  };
  const getListClient = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/publisher/classification/index`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(url, { headers })
      .then(res => {
        setListClassification(res?.data?.data);
      })
      .catch(err => {
        console.log("err client", err);
      });
  };
  useEffect(() => {
    getListClient();
    getDetail();
    getCurr();
  }, []);

  useEffect(() => {
    if (curr_id === tempDataValueCurrencyFee.currency_id) {
      setTrans_fee(tempDataValueCurrencyFee.transfer_fee);
    } else {
      setTrans_fee(0);
    }
  }, [curr_id]);

  const onSubmit = e => {
    e.preventDefault();
    if (nama !== "" && domicilytype) {
      const urlAddress = `${hardBaseUrl}/dsp/${id}`;
      let theToken = localStorage.getItem("token");
      const headers = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${theToken}`,
        },
      };
      const formData = new FormData();
      formData.append("color", color);
      formData.append("name", dsp);
      formData.append("address", alamat);
      formData.append("person_name", nama);
      formData.append("person_email", email);
      formData.append("person_phone", Number(telepon));
      if (file2 !== null) {
        formData.append("image_logo", file);
      }
      formData.append("type", clientType);
      formData.append("type_upload", uploadType);
      formData.append("currency_id", curr_id);
      formData.append("transaction_fee", trans_fee);
      formData.append("client_classification_id", classification);
      formData.append(
        "domicile",
        listDomicile?.[
          listDomicile?.findIndex(e => e.id === domicilytype)
        ]?.name?.toLowerCase()
      );

      axios
        .put(urlAddress, formData, headers)
        .then(() => {
          Swal.fire({
            title: "Success",
            text: "Data added successfully",
            icon: "success",
            confirmButtonText: "Ok",
          }).then(result => {
            if (result.isConfirmed) {
              history.push("/admin/master/dsp");
            }
          });
        })
        .catch(err => {
          console.log({ err });
          Swal.fire({
            title: "Errors",
            text: `${err.response.data.errors[0].message}`,
            icon: "error",
            confirmButtonText: "Ok",
          });
        });
    }
  };
  const handleChangePhotoButton = e => {
    e.preventDefault();
    inputFile.current.click();
  };
  const handleChangeUploadType = e => {
    setUploadType(e.target.value);
  };
  const handleChangeClientType = e => {
    setClientType(e.target.value);
  };
  const handleChangeCurrency = e => {
    setCurr_id(e.target.value);
  };

  const handleChangeAddress = event => {
    const { value } = event.target;
    setAlamat(value);
  };
  const changeValueTotalClaim = e => {
    let newVal = e;
    let resultVal = e;
    if (newVal.includes(",")) {
      resultVal = Number(newVal.replaceAll(",", ""));
    } else {
      resultVal = Number(resultVal);
    }
    setTrans_fee(resultVal);
  };

  const breadcrumbData = [
    {
      label: "Home",
    },
    {
      label: "Parameter",
      link: "/admin/parameter",
    },
    {
      label: "DSP",
      link: "/admin/parameter/dsp",
    },
    {
      label: "Edit DSP",
      active: true
    }
  ];

  console.log({ clientType });
  return (
    <Page className={classes.root}>
      <Container maxWidth={false}>
        <HeaderTitle title="Edit DSP" backButton breadcrumbData={breadcrumbData} />
        <Divider className={classes.divider} />
        <Box marginBottom={2}>
          <ThemeProvider theme={theme}>
            <Box>
              <Typography variant="h5" className={classes.title}>
                DSP Information
              </Typography>
              <Typography variant="subtitle1" className={classes.subTitle}>
                Information about DSP that has been joined to us
              </Typography>
            </Box>
          </ThemeProvider>
        </Box>
        <Box component="form" onSubmit={onSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <Box marginBottom="10px">
                    <ThemeProvider theme={theme}>
                      <Typography className={classes.label}>
                        Client Type
                      </Typography>
                    </ThemeProvider>
                  </Box>
                  <Box margin="10px 0px">
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      fullWidth={true}
                      margin="dense"
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        shrink={false}
                      >
                        {clientType !== "" ? null : "Client Type"}
                      </InputLabel>
                      <Select
                        id="outlined-basic"
                        name="publisher"
                        value={clientType}
                        onChange={event => handleChangeClientType(event)}
                      >
                        {clientTypeList?.map((item, index) => (
                          <MenuItem
                            key={index}
                            value={item?.value}
                          >
                            {item?.name}
                          </MenuItem>
                        ))}

                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <Box marginBottom="10px">
                    <ThemeProvider theme={theme}>
                      <Typography className={classes.label}>
                        Client Classification
                      </Typography>
                    </ThemeProvider>
                  </Box>
                  <Box margin="10px 0px">
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      fullWidth={true}
                      margin="dense"
                    >
                      <Select
                        id="outlined-basic"
                        variant="outlined"
                        fullWidth={true}
                        name="publisher"
                        value={classification}
                        onChange={event => setClassification(event?.target?.value)}
                      >
                        {listClassification.map((item, index) => (
                          <MenuItem key={index} value={item?.client_classification_id}>
                            {item?.classification_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>
              <Box
                border="1px solid #9AA2B1"
                borderRadius="8px"
                padding="16px"
                marginTop="16px"
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <Typography component="label" className={classes.label} htmlFor="dsp">
                      Client Name
                    </Typography>
                    <TextField
                      className={classes.myTextField}
                      fullWidth
                      onChange={onChange}
                      value={dsp}
                      id={"dsp"}
                      name="dsp"
                      type="text"
                      margin="dense"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <Typography component="label" className={classes.label}>
                      Domicile
                    </Typography>
                    <Box>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        fullWidth={true}
                        margin="dense"
                      >
                        <InputLabel
                          id="demo-simple-select-outlined-label"
                          shrink={false}
                        >
                          {domicilytype !== "" ? null : "Domicile"}
                        </InputLabel>
                        <Select
                          id="outlined-basic"
                          variant="outlined"
                          fullWidth={true}
                          name="domicilyType"
                          value={domicilytype}
                          onChange={event =>
                            setDomicilytype(event.target.value)
                          }
                        >
                          {listDomicile.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item?.id}>
                                {item?.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography component="label" className={classes.label} htmlFor="theme">
                      Color Identity
                    </Typography>
                    <Box
                      textAlign="center"
                      borderRadius="6px"
                      marginTop="6px"
                    >
                      <Box
                        className={classes.colorIdentity}
                        height={25}
                        width={50}
                        onClick={() => setShowColorPicker(showColorPicker => !showColorPicker)}
                      />
                      {showColorPicker && (
                        <PhotoshopPicker
                          color={color}
                          onChange={updateColor =>
                            setColor(updateColor.hex)
                          }
                          onAccept={() =>
                            setShowColorPicker(
                              showColorPicker => !showColorPicker
                            )
                          }
                          onCancel={() =>
                            setShowColorPicker(
                              showColorPicker => !showColorPicker
                            )
                          }
                        />
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      className={classes.label}
                      component="label"
                      htmlFor="photo"
                    >
                      Logo
                    </Typography>
                    <Box className={classes.imageWrapper} margin="dense" onClick={handleChangePhotoButton}>
                      {objectUrl ? (
                        <InnoImage
                          src={objectUrl}
                          alt="Preview"
                          className={classes.imgPreview}

                          styleImageContainer={{
                            border: "1px solid #D1D5DC",
                            borderRadius: "6px",
                            padding: 0,
                          }}
                          styleImage={{
                            maxWidth: "150px",
                            maxHeight: "150px",
                          }}
                          fill={true}
                        />
                      ) : (
                        <img src={UnggahLogo} alt="logo-upload" />
                      )}
                      <input
                        onChange={onChangeFile}
                        ref={inputFile}
                        hidden
                        type="file"
                        name="photo"
                      />
                    </Box>
                    <Typography className={classes.subTitle}>Make sure the document format is JPG, PNG or JPEG</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <ThemeProvider theme={theme}>
                <Box>
                  <Typography variant="h5" className={classes.title}>
                    PIC
                  </Typography>
                  <Typography variant="subtitle1" className={classes.subTitle}>
                    Information about PIC
                  </Typography>
                </Box>
              </ThemeProvider>
              <Box
                border="1px solid #9AA2B1"
                borderRadius="8px"
                padding="16px"
                marginTop="10px"
              >
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography component="label" className={classes.label} htmlFor="nama">
                      PIC Name
                    </Typography>
                    <TextField
                      fullWidth
                      onChange={onChange}
                      id="nama"
                      margin="dense"
                      name="nama"
                      value={nama}
                      type="text"
                      variant="outlined"
                      className={classes.myTextField}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography component="label" className={classes.label} htmlFor="telepon">
                      Phone Number
                    </Typography>
                    <TextField
                      fullWidth
                      className={classes.myTextField}
                      onChange={onChange}
                      id="telepon"
                      name="telepon"
                      margin="dense"
                      value={telepon}
                      type="number"
                      variant="outlined"
                      InputProps={{
                        classes: { input: classes.inputFieldsTlp },
                        startAdornment: (
                          <InputAdornment position="start">
                            +62
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className={classes.label} htmlFor="email">
                      Email
                    </Typography>
                    <TextField
                      fullWidth
                      onChange={onChange}
                      id="email"
                      name="email"
                      value={email}
                      type="email"
                      margin="dense"
                      variant="outlined"
                      className={classes.myTextField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography component="label" className={classes.label} htmlFor="telepon">
                      Address
                    </Typography>
                    <TextField
                      id="outlined-basic"
                      name="alamat"
                      variant="outlined"
                      multiline
                      fullWidth={true}
                      rows={4}
                      margin="dense"
                      onChange={event => handleChangeAddress(event)}
                      placeholder="Address"
                      value={alamat}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <ThemeProvider theme={theme}>
                <Typography variant="h5" className={classes.title}>
                  Transfer & Fee Currency
                </Typography>
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <Typography component="p" className={classes.subTitle}>
                  To Settings Transfer Fee & Currency of Client
                </Typography>
              </ThemeProvider>
              <Box
                border="1px solid #9AA2B1"
                borderRadius="8px"
                padding="16px"
                marginTop="16px"
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <Typography className={classes.label}>
                      Currency
                    </Typography>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      fullWidth={true}
                      margin="dense"
                    >
                      <Select
                        displayEmpty={true}
                        id="outlined-basic"
                        variant="outlined"
                        fullWidth={true}
                        name="publisher"
                        className={classes.selectPadding}
                        value={curr_id}
                        onChange={event => handleChangeCurrency(event)}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {currencyList?.length > 0 &&
                          currencyList?.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item?.id}>
                                {item?.currency_iso_code}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <Typography className={classes.label}>
                      Transfer Fee
                    </Typography>
                    <NumberFormat
                      disabled={false}
                      customInput={TextField}
                      variant="outlined"
                      margin="dense"
                      thousandSeparator={true}
                      onChange={e => changeValueTotalClaim(e.target.value)}
                      autoComplete="off"
                      className={classes.inputNum}
                      size="small"
                      fullWidth
                      value={trans_fee}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <Typography className={classes.label}>
                      Flat Fee
                    </Typography>
                    <NumberFormat
                      disabled={false}
                      customInput={TextField}
                      variant="outlined"
                      thousandSeparator={true}
                      onChange={e => changeValueTotalClaim(e.target.value)}
                      autoComplete="off"
                      className={classes.inputNum}
                      margin="dense"
                      size="small"
                      fullWidth
                      value={trans_fee}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.buttonWrapper}>
                <Button
                  variant="contained"
                  className={classes.buttonCancel}
                  onClick={() => history.goBack()}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  className={classes.button}
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};
export default EditDsp;
