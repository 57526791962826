import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Box
} from "@material-ui/core";
import { FormLabel } from "components";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/styles";
import { PrimaryButton } from "components";


const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  gap4: {
    display: "flex",
    gap: "4px",
    marginTop: "10px",
  },
}));

const DialogEditUserPencipta = ({
  open,
  onClose,
  onSubmit,
  handleChangeSelectRole,
  handleChange,
  role,
  name,
  email,
  phone,
  roleItems,
  composer,
  handleChangeSelectPencipta,
  penciptaItems,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth={true}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <Box component="form">
        <DialogTitle id="responsive-dialog-title">
          {"Edit User Composer"}
        </DialogTitle>

        <DialogContent>
          <FormLabel required>User Name</FormLabel>
          <FormControl
            fullWidth
            className={classes.margin}
            variant="outlined"
          >
            <OutlinedInput
              id="outlined-adornment-amount"
              onChange={handleChange}
              name="name"
              type="text"
              fullWidth={false}
              value={name}
            />
          </FormControl>
        </DialogContent>
        <DialogContent>
          <FormLabel>Composer</FormLabel>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth={true}
          >
            <InputLabel
              id="demo-simple-select-outlined-label"
              shrink={false}
            >
              {composer !== null ? null : "Composer"}
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              onChange={handleChangeSelectPencipta}
              autoWidth={true}
              fullWidth={true}
              defaultValue=""
              value={composer}
            >
              <MenuItem value={null}>Composer</MenuItem>
              {penciptaItems.map(item => {
                return (
                  <MenuItem key={item.composer_id} value={item.composer_id}>
                    {item.sure_name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogContent>
          <FormLabel required>Role</FormLabel>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth={true}
          >
            <InputLabel
              id="demo-simple-select-outlined-label"
              shrink={false}
            >
              {role !== null ? null : "Role"}
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              onChange={handleChangeSelectRole}
              autoWidth={true}
              fullWidth={true}
              value={role}
              defaultValue=""
            >
              <MenuItem value={null}>Role</MenuItem>
              {roleItems.map(item => {
                return (
                  <MenuItem key={item.role_id} value={item.role_id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </DialogContent>

        <DialogContent>
          <FormLabel required>Phone Number</FormLabel>
          <FormControl
            fullWidth
            className={classes.margin}
            variant="outlined"
          >
            <OutlinedInput
              id="outlined-adornment-amount"
              onChange={handleChange}
              name="phone"
              type="number"
              fullWidth={true}
              value={phone}
            />
          </FormControl>
        </DialogContent>

        <DialogContent>
          <FormLabel required>Email</FormLabel>
          <FormControl
            fullWidth
            className={classes.margin}
            variant="outlined"
          >
            <OutlinedInput
              id="outlined-adornment-amount"
              onChange={handleChange}
              name="email"
              type="text"
              fullWidth={true}
              value={email}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Box className={classes.gap4}>
            <PrimaryButton size="small" onClick={onClose} label="Cancel" />
            <PrimaryButton size="small" onClick={onSubmit} label="Save" />
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default DialogEditUserPencipta;
