import { Container, Divider } from "@material-ui/core";
import { Box, Grid } from "@mui/material";
import axios from "axios";
import {
  AutoCompleteComponent,
  FormLabel,
  ModalError,
  ModalSuccess,
  Page,
  PrimaryButton,
  SectionLabel,
  SkeletonComponent,
  TextInput,
} from "components";
import { HeaderTitle } from "layouts";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { hardBaseUrl } from "services/urlConstant";
import { globalStyles } from "styles";
import { getErrors } from "utils";

const CreateOrEditCountry = () => {
  const classes = globalStyles();
  const history = useHistory();
  const { state } = useLocation();
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const pageTitle = `${id ? "Edit" : "Add"} Country`;

  const [loadingPage, setLoadingPage] = useState(false);
  const [optionCurrency, setOptionCurrency] = useState([]);
  const [payload, setPayload] = useState({
    country_name: state?.country?.country_name || "",
    country_iso_code: state?.country?.country_iso_code || "",
    currency_id: state?.country?.currency?.id || 0,
  });

  const handleChangePayload = (value, key) => {
    setPayload(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };

  const getOptionCurrency = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(
        `${hardBaseUrl}/currency/datatable?page=1&per_page=10&search=`,
        { headers }
      );
      const modifiedData = res?.data?.data?.map(item => ({
        ...item,
        label: item?.currency_name,
      }));
      setOptionCurrency(modifiedData);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const handleSubmit = async () => {
    const method = id ? axios.put : axios.post;
    try {
      setLoadingPage(true);
      await method(
        `${hardBaseUrl}/country/${id ? `update/${id}` : "create"}`,
        payload,
        { headers }
      );
      ModalSuccess(
        `Country ${id ? "updated" : "created"} successfully`
      ).then(() => history.goBack());
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    getOptionCurrency();
  }, []);

  return (
    <Page className={classes.root} title={pageTitle}>
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title={pageTitle}
            breadcrumbData={breadcrumbData({ pageTitle })}
            backButton
          />
          <Divider className={classes.divider} />
          <SectionLabel
            title="Country"
            subTitle="Customize and manage Country for transaction."
          />
          <Box
            border="1px solid #ebebeb"
            borderRadius="5px"
            p="24px"
            my="16px"
            width="60%"
          >
            <Grid container direction="column" rowSpacing={2}>
              <Grid item>
                <FormLabel label="Country Code" required/>
                <TextInput
                  placeholder="Country Code"
                  value={payload?.country_iso_code}
                  onChange={e =>
                    handleChangePayload(e?.target?.value, "country_iso_code")
                  }
                />
              </Grid>
              <Grid item>
                <FormLabel label="Country Name" required/>
                <TextInput
                  placeholder="Country Name"
                  value={payload?.country_name}
                  onChange={e =>
                    handleChangePayload(e?.target?.value, "country_name")
                  }
                />
              </Grid>
              <Grid item>
                <FormLabel label="Currency" required/>
                <AutoCompleteComponent
                  options={optionCurrency}
                  placeholder="Currency"
                  value={
                    optionCurrency.find(
                      option => option.id === payload?.currency_id
                    ) || null
                  }
                  onChange={value => handleChangePayload(value, "currency_id")}
                  width="100%"
                  size="small"
                />
              </Grid>
            </Grid>
          </Box>
          <Grid container justifyContent="right" width="60%">
            <PrimaryButton label="Save" onClick={handleSubmit} />
          </Grid>
        </Container>
      )}
    </Page>
  );
};

const breadcrumbData = ({ pageTitle }) => [
  {
    label: "Parameter",
    link: "/admin/parameter/admin-country",
  },
  {
    label: "Country",
    link: "/admin/parameter/admin-country",
  },
  {
    label: pageTitle,
    active: true,
  },
];
export default CreateOrEditCountry;
