import { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Box,
} from "@mui/material";
import {
  AutoCompleteComponent,
  FormLabel,
  ModalError,
  ModalSuccess,
  PasswordInput,
  PrimaryButton,
  SecondaryButton,
  SelectInput,
  TextInput,
} from "components";
import axios from "axios";
import { makeStyles } from "@material-ui/styles";
import { hardBaseUrl } from "services/urlConstant";
import { getErrors } from "utils";


const useStyles = makeStyles({
  dialog: {
    zIndex: 1
  }
});

const ModalFormUserPublisher = ({
  open,
  onClose,
  optionRole,
  getData,
}) => {
  const classes = useStyles();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingButton, setLoadingButton] = useState(false);
  const [optionPublisher, setOptionPublisher] = useState([]);
  const [passwordError, setPasswordError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [payload, setPayload] = useState({
    name: "",
    email: "",
    phone: "",
    role_id: "",
    publisher_id: "",
    password: "",
    confirm_password: "",
  });

  const handleChangePayload = (value, key) => {
    setPayload(currentState => ({
      ...currentState,
      [key]: value,
    }));
    if (key === "password" || key === "confirm_password") {
      setPasswordError(null);
    }
    if (key === "email") {
      setEmailError(null);
    }
  };

  const getOptionPublisher = async () => {
    try {
      const res = await axios.get(`${hardBaseUrl}/publisher`, { headers });
      const modifiedData = res?.data?.data?.map(item => ({
        ...item,
        id: item?.publisher_id,
        label: item?.name,
      }));
      setOptionPublisher(modifiedData);
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    }
  };
  const handleSubmit = async (e) => {
    e?.preventDefault();
    const { password, confirm_password, email, ...restPayload } = payload;
    const modifiedPayload = { password, email, ...restPayload };
    if (password !== confirm_password) {
      setPasswordError("Password and Confirm Password does not match");
    } else {
      try {
        setLoadingButton(true);
        await axios.post(
          `${hardBaseUrl}/user`,
          modifiedPayload,
          { headers }
        );
        onClose();
        ModalSuccess(
          "Successfully Create User Publisher"
        ).then(() => {
          getData();
          setPayload({});
        });
      } catch (error) {
        ModalError(error?.response ? getErrors(error?.response) : error);
      } finally {
        setLoadingButton(false);
      }
    }

  };

  useEffect(() => {
    getOptionPublisher();
  }, []);

  useEffect(() => {
    setPayload({});
  }, [onClose]);

  return (
    <Dialog fullWidth open={open} onClose={onClose} className={classes.dialog}>
      <Box fullWidth component="form" onSubmit={handleSubmit} autoComplete="off">
        <DialogTitle>Add User Publisher</DialogTitle>
        <DialogContent dividers>
          <Grid container direction="column" rowSpacing={2}>
            <Grid item>
              <FormLabel label="User Name" required />
              <TextInput
                placeholder="User Name"
                name="name publisher"
                autoComplete="new-username"
                value={payload?.name}
                onChange={e => handleChangePayload(e?.target?.value, "name")}
                disabled={loadingButton}
              />
            </Grid>
            <Grid item>
              <FormLabel label="Role" required />
              <SelectInput
                label={!payload?.role_id ? "Role" : ""}
                options={optionRole}
                optionKey="role_id"
                optionLabel="name"
                value={payload?.role_id || ""}
                onChange={e => handleChangePayload(e?.target?.value, "role_id")}
                width="100%"
              />
            </Grid>
            <Grid item>
              <FormLabel label="Publisher" required />
              <AutoCompleteComponent
                label={!payload?.publisher_id ? "Publisher" : ""}
                options={optionPublisher}
                value={
                  optionPublisher.find(
                    option => option.id === Number(payload?.publisher_id)
                  ) || null
                }
                onChange={value => handleChangePayload(value, "publisher_id")}
                width="100%"
                size="small"
                name="publisher"
                renderOption={(props, option) => {
                  return (
                    <Box component='li' {...props} key={option?.publisher_id}>
                      {option?.name}
                    </Box>
                  );
                }}
              />
            </Grid>
            <Grid item>
              <FormLabel label="Password" required />
              <PasswordInput
                placeholder="New Password"
                value={payload?.password}
                autoComplete="new-password"
                onChange={e => handleChangePayload(e?.target?.value, "password")}
                error={!!passwordError}
                helperText={passwordError}
                disabled={loadingButton}
              />
            </Grid>
            <Grid item>
              <FormLabel label="Confirm Password" required />
              <PasswordInput
                placeholder="Confirm Password"
                value={payload?.confirm_password}
                error={!!passwordError}
                helperText={passwordError}
                onChange={e =>
                  handleChangePayload(e?.target?.value, "confirm_password")
                }
                disabled={loadingButton}
              />
            </Grid>
            <Grid item>
              <FormLabel label="Phone Number" />
              <TextInput
                placeholder="Phone Number"
                value={payload?.phone}
                onChange={e => handleChangePayload(e?.target?.value, "phone")}
                type="number"
                disabled={loadingButton}
              />
            </Grid>
            <Grid item>
              <FormLabel label="Email" required />
              <TextInput
                placeholder="Email"
                value={payload?.email}
                type="email"
                onChange={e => handleChangePayload(e?.target?.value, "email")}
                error={!!emailError}
                helperText={emailError}
                disabled={loadingButton}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SecondaryButton label="Cancel" onClick={onClose} />
          <PrimaryButton
            label="Add"
            loading={loadingButton}
            disabled={loadingButton}
            onClick={handleSubmit}
          />
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ModalFormUserPublisher;
