import { Container, Divider } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Grid } from "@mui/material";
import axios from "axios";
import {
  ButtonGroupTop,
  ModalError,
  ModalSuccess,
  Page,
  PrimaryButton,
  SearchTextInput,
  SkeletonComponent,
} from "components";
import { InnoTable } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { debounce } from "lodash";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { globalStyles } from "styles";
import { getErrors } from "utils";
import { hardBaseUrl } from "../../../../services/urlConstant";

function MasterRevenueType() {
  const classes = globalStyles();
  const history = useHistory();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingPage, setLoadingPage] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    per_page: 10,
    search: "",
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };

  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/dsp-revenue-type/table`, {
        headers,
        params: queryParams,
      });
      const { data, meta } = res?.data;
      setDataTable(data || []);
      const pageCount = getTotalPage(meta?.total, queryParams?.per_page);
      setTableTotalPage(pageCount || 1);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );
  const handleDelete = async item => {
    try {
      await axios.delete(
        `${hardBaseUrl}/dsp-revenue-type/delete/${item.dsp_id}`,
        { headers }
      );
      ModalSuccess("Data has been deleted").then(() => getDataTable());
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };

  useEffect(() => {
    const isSearching = Boolean(queryParams?.search);
    if (isSearching) {
      debounceDataTable();
      return () => {
        debounceDataTable.cancel();
      };
    } else {
      getDataTable();
    }
  }, [queryParams, debounceDataTable]);

  return (
    <Fragment>
      <Page className={classes.root} title="DSP Type Revenue">
        {loadingPage ? (
          <SkeletonComponent variant="wave" />
        ) : (
          <Container maxWidth={false}>
            <HeaderTitle
              title="Configuration"
              breadcrumbData={breadcrumbData}
            />
            <ButtonGroupTop />
            <Divider className={classes?.flatDivider} />
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              my="16px"
            >
              <Grid item>
                <SearchTextInput
                  placeholder="Search"
                  value={queryParams?.search}
                  onChange={e =>
                    handleChangeQueryParams(e?.target?.value, "search")
                  }
                />
              </Grid>
              <Grid item>
                <PrimaryButton
                  label="Add DSP Revenue Type"
                  startIcon={<Add />}
                  onClick={() =>
                    history.push("/admin/konfigurasi/dsp-type-revenue/add")
                  }
                  size="large"
                />
              </Grid>
            </Grid>
            <InnoTable
              isLoading={false}
              columns={columnTable}
              items={dataTable}
              page={queryParams?.page}
              rowsPerPage={queryParams?.per_page}
              totalPage={tableTotalPage}
              handleChangePage={(_, page) =>
                handleChangeQueryParams(page, "page")
              }
              handleChangeRowsPerPage={e =>
                handleChangeQueryParams(e?.target?.value, "per_page")
              }
              isHaveAction
              handleEdit={item =>
                history.push({
                  pathname: `/admin/konfigurasi/dsp-type-revenue/edit/${item.dsp_id}`,
                  state: { dsp: item },
                })
              }
              handleDelete={handleDelete}
              isUsingDeleteConfirmation
              deleteName="name"
            />
          </Container>
        )}
      </Page>
    </Fragment>
  );
}

const breadcrumbData = [
  {
    label: "Configuration",
    link: "/admin/konfigurasi/dsp-type-revenue",
  },
  {
    label: "DSP Type Revenue",
    active: true,
  },
];
const columnTable = [
  {
    name: "name",
    title: "DSP Name",
  },
  {
    name: "dsp_revenue_types",
    title: "Revenue Type",
    renderText: item =>
      item
        ?.map(revenue => revenue?.revenue_type?.name)
        ?.filter(name => name)
        ?.join(", "),
  },
];
export default MasterRevenueType;
