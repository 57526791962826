import {
  Container,
  Divider,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@material-ui/core";
import { HeaderTitle } from "layouts";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Page } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
import Results from "./Results";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Transaction",
    link: "/admin/advance",
  },
  {
    label: "Cost Allocation",
    link: "/admin/cost-allocation",
  },
  {
    label: "Detail Cost Allocation",
    active: true,
  },
];

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  formControl: {
    width: "340px",
    border: "0px solid grey",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#687083",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },
  mypadding2: {
    "& .MuiSelect-outlined": {
      paddingTop: "15.5px",
      paddingBottom: "10.5px",
    },
    width: "200px",
  },
  subtitle: {
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: "28px",
  },
}));

const DetailCostAllocation = () => {
  const classes = useStyles();

  const { state } = useLocation();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(1);
  const [response, setResponse] = useState([]);

  const [search, setSearch] = useState("");
  const [dataSummary, setDataSummary] = useState({});

  const [loadingTable, setLoadingTable] = useState(false);

  const handleChangeRowsPerPage = event => {
    event.preventDefault();
    setRowsPerPage(event.target.value);

    setPage(1);
  };

  const getDataTable = () => {
    setLoadingTable(true);
    const token = localStorage.getItem("token");

    const url = `${hardBaseUrl}/publisher/cost-allocation/detail/${state.customer.cost_allocation_id}?page=${page}&per_page=${rowsPerPage}&search=${search}`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        const newData = (res?.data?.data?.data || []).map(({ period, ...item }) => {
          const [start, end] = period.split(" - ");
          const formattedStart = moment(start, "MMMM YYYY").format("MMM YYYY");
          const formattedEnd = moment(end, "MMMM YYYY").format("MMM YYYY");
          const formattedPeriod = `${formattedStart} - ${formattedEnd}`;
          return {
            ...item,
            period: formattedPeriod,
          };
        });
        setTotalData(res.data.data.total_page);
        setResponse(newData);
        setLoadingTable(false);
      })
      .catch(() => {
        setLoadingTable(false);
      });
  };

  const getDataSummary = () => {
    const token = localStorage.getItem("token");

    const url = `${hardBaseUrl}/publisher/cost-allocation/${state.customer.cost_allocation_id}`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        setDataSummary(res.data.data);
      })
      .catch(() => { });
  };

  useEffect(() => {
    getDataSummary();
  }, [search]);

  useEffect(() => {
    getDataTable();
  }, [search, rowsPerPage, page]);

  const pageSizePagination = () => {
    return (
      <div className={classes.pageSize}>
        <select
          style={{
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            marginTop: "4px",
          }}
          value={rowsPerPage}
          onChange={handleChangeRowsPerPage}
        >
          {[10, 20, 50].map(pageSize => {
            return (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  const handleChangePage = (event, data) => {
    setPage(data);
  };
  return (
    <Page className={classes.root} title="Cost Allocation">
      <Container maxWidth={false}>
        <HeaderTitle breadcrumbData={breadcrumbData} title="Cost Allocation" backButton />
        <Divider className={classes.divider} />
        <div
          style={{
            marginTop: "6px",
          }}
        >
          <FormControl fullWidth className={classes.margin} variant="outlined">
            <OutlinedInput
              placeholder="Search"
              id="outlined-adornment-amount"
              style={{
                height: "44px",
                border: "1px solid #D1D5DC",
                borderRadius: "6px",
                width: "266px",
              }}
              value={search}
              onChange={e => setSearch(e.target.value)}
              inputProps={{
                classes: {
                  input: classes.placeholder,
                },
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "#9AA2B1" }} />
                </InputAdornment>
              }
              fullWidth={false}
            />
          </FormControl>
        </div>

        <ThemeProvider theme={theme}>
          <p
            style={{
              fontSize: "16px",
              fontWeight: "400",
              color: "#687083",
              marginTop: "24px",
            }}
          >
            Feature to view the detail of cost allocation
          </p>
        </ThemeProvider>
        <div
          style={{
            display: "flex",
          }}
        >
          <div
            style={{
              marginTop: "16px",
              border: "1px solid #D1D5DC",
              borderRadius: "10px",
              padding: "16px",
              width: "340px",
              backgroundColor: "#111827",
              position: "relative",
            }}
          >
            <img
              style={{
                position: "absolute",
                right: 40,
              }}
              src={
                require("assets/image-public/images/rectangle_opacity.png")
                  .default
              }
              alt=""
            />
            <img
              style={{
                position: "absolute",
                right: -20,
                height: "64px",
              }}
              src={
                require("assets/image-public/images/rectangle_opacity.png")
                  .default
              }
              alt=""
            />
            <img
              style={{
                position: "absolute",
                right: -2,
                height: "64px",
                top: -15,
              }}
              src={
                require("assets/image-public/images/rectangle_opacity.png")
                  .default
              }
              alt=""
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div>
                <img
                  alt="Logo"
                  src={
                    require("assets/image-public/images/icon_images/ri_money-dollar-circle-fill.svg")
                      .default
                  }
                />
              </div>
              <div
                style={{
                  marginLeft: "18px",
                }}
              >
                <div>
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "20px",
                      color: "#9AA2B1",
                    }}
                  >
                    Total Value
                  </span>
                </div>
                <div
                  style={{
                    marginTop: "8px",
                  }}
                >
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "24px",
                      lineHeight: "32px",
                      color: "white",
                    }}
                  >
                    {dataSummary?.value_total || "Rp 0.-"}
                  </span>
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            ></div>
          </div>
          <div
            style={{
              marginTop: "16px",
              border: "1px solid #D1D5DC",
              borderRadius: "10px",
              padding: "16px",
              width: "340px",
              backgroundColor: "#111827",
              position: "relative",
            }}
          >
            <img
              style={{
                position: "absolute",
                right: 40,
              }}
              src={
                require("assets/image-public/images/rectangle_opacity.png")
                  .default
              }
              alt=""
            />
            <img
              style={{
                position: "absolute",
                right: -20,
                height: "64px",
              }}
              src={
                require("assets/image-public/images/rectangle_opacity.png")
                  .default
              }
              alt=""
            />
            <img
              style={{
                position: "absolute",
                right: -2,
                height: "64px",
                top: -15,
              }}
              src={
                require("assets/image-public/images/rectangle_opacity.png")
                  .default
              }
              alt=""
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div>
                <img
                  alt="Logo"
                  src={
                    require("assets/image-public/images/icon_images/ri_money-dollar-circle-fill.svg")
                      .default
                  }
                />
              </div>
              <div
                style={{
                  marginLeft: "18px",
                }}
              >
                <div>
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "20px",
                      color: "#9AA2B1",
                    }}
                  >
                    Allocated
                  </span>
                </div>
                <div
                  style={{
                    marginTop: "8px",
                  }}
                >
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "24px",
                      lineHeight: "32px",
                      color: "white",
                    }}
                  >
                    {dataSummary?.allocated || "Rp 0.-"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ThemeProvider theme={theme}>
          <Typography
            variant="h3"
            className={classes.subtitle}
            style={{
              marginTop: "24px",
            }}
          >
            {dataSummary?.composer_name || "-"}
          </Typography>
        </ThemeProvider>
        <ThemeProvider theme={theme}>
          <p
            style={{
              marginTop: "5px",
              color: "#8f8f8f",
              fontSize: "14px",
            }}
          >
            Transport Cost for Content Creator
          </p>
        </ThemeProvider>

        {response && (
          <Results
            isLoading={loadingTable}
            className={classes.results}
            items={response}
            pageSizePagination={pageSizePagination()}
            totalData={totalData}
            page={page}
            handleChangePage={handleChangePage}
          />
        )}
      </Container>
    </Page>
  );
};

export default DetailCostAllocation;
