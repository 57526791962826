import { Button, Card, CardContent, Grid, Typography, Box } from "@material-ui/core";
import { useStyles } from "../../style";
import { InnoTableV2 } from "inno-ui";
import moment from "moment";
import NumberFormat from "react-number-format";
import { StatusChip } from "components";

const ImportCatalogue = ({
  sizeChipForImportCatalogue,
  client,
  columnTable,
  handleChangePage,
  handleChangeRowsPerPage,
  listError,
  tablePagination,
  pagesCount,
  activeTab,
  setActiveTab,
}) => {
  const classes = useStyles();
  const formatedDate = date => moment(date).format("DD MMM YYYY");
  return (
    <Grid container>
      <Grid item xs={12} md={8} lg={8} xl={8}>
        <Card id="card-of-success" className={classes.cardCatalogue}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12} xl={12}>
                <Typography id="tes-pdf" className={classes.fontSize}>File Name</Typography>
                <Typography className={classes.fontSize}>
                  {client?.process_files?.find(
                    file => file?.id === activeTab
                  )?.filename || client?.process_name}
                </Typography>
              </Grid>

              <Grid item xs={6} md={6} lg={6} xl={6}>
                <Typography className={classes.fontSize}>Upload ID</Typography>
                <Typography className={classes.fontSize}>
                  {client?.id || 10}
                </Typography>
              </Grid>

              <Grid item xs={6} md={6} lg={6} xl={6}>
                <Typography className={classes.fontSize}>
                  Upload Date
                </Typography>
                <Typography className={classes.fontSize}>
                  {formatedDate(client?.created_at)}
                </Typography>
              </Grid>

              <Grid
                item
                xs={sizeChipForImportCatalogue}
                md={sizeChipForImportCatalogue}
                lg={sizeChipForImportCatalogue}
                xl={sizeChipForImportCatalogue}
              >
                <Box alignItems="center" display="flex" height="100%">
                  <StatusChip
                    label={client?.status}
                    type={
                      client?.status === "Finish"
                        ? "success"
                        : client?.status === "Error"
                          ? "danger"
                          : client?.status === "Started" ||
                            client?.status === "Initialize" ||
                            client?.status === "Running"
                            ? "warning"
                            : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={6} md={6} lg={6} xl={6}>
                <Typography className={classes.fontSize}>
                  Number of Record
                </Typography>
                <NumberFormat
                  displayType="text"
                  value={client?.record_count}
                  thousandSeparator=","
                  thousandsGroupStyle="thousand"
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                xl={12}
                className={classes.table}
              >
                <Grid container spacing={2}>
                  {client?.process_files?.map(({ id }, index) => {
                    const isActive = activeTab === id;
                    return (
                      <Grid item key={id}>
                        <Button
                          onClick={() => setActiveTab(id)}
                          className={isActive ? classes?.blackButton : ""}
                          variant="outlined"
                        >
                          {`File ${index + 1}`}
                        </Button>
                      </Grid>
                    );
                  })}
                  <Grid item>
                    <InnoTableV2
                      columns={columnTable}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                      idColumnName={"id"}
                      isLoading={false}
                      isUsingCheckbox={false}
                      items={listError?.data || []}
                      loadingColor={""}
                      page={tablePagination?.page}
                      rowsPerPage={tablePagination?.size}
                      totalPage={pagesCount}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container justifyContent="space-between"></Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} md={4} lg={4} xl={4}></Grid>
    </Grid>
  );
};

export default ImportCatalogue;
