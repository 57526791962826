import { useState, useEffect } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Box
} from "@mui/material";
import {
    FormLabel,
    PrimaryButton,
    SecondaryButton,
    TextInput,
} from "components";

const ModalAssetId = ({
    open,
    onClose,
    preload,
    handleSubmit,
}) => {
    const [value, setValue] = useState("");
    const isEditing = Boolean(preload);
    useEffect(() => {
        setValue(preload?.value);
    }, []);
    const onSubmit = (e) => {
        e?.preventDefault();
        console.log({ preload });
        handleSubmit({
            ...preload,
            value
        });
    };
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
            <DialogTitle>{isEditing ? "Edit" : "Add"} Asset ID</DialogTitle>
            <DialogContent dividers>
                <Box component="form" onSubmit={onSubmit}
                >
                    <Grid container direction="column" rowSpacing={1}>
                        <Grid item>
                            <FormLabel label="Asset ID" />
                            <TextInput
                                placeholder="Asset ID"
                                onChange={e => setValue(e?.target?.value)}
                                value={value}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent="flex-end" padding="10px 16px" gap={2}>
                    <Grid item>
                        <SecondaryButton label="Cancel" onClick={onClose} />
                    </Grid>
                    <Grid item>
                        <PrimaryButton
                            label={isEditing ? "Save" : "Add"}
                            onClick={onSubmit}
                            disabled={!value}
                            color={value ? "black" : "white"}
                            textColor={value ? "white" : "black"}
                        />
                    </Grid>
                </Grid>
            </DialogActions>

        </Dialog>
    );
};

export default ModalAssetId;
