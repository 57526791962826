import { Container, Divider } from "@material-ui/core";
import { Grid, Typography } from "@mui/material";
import axios from "axios";
import {
  ModalError,
  ModalSuccess,
  Page,
  PrimaryButton,
  SectionLabel,
  SkeletonComponent,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { hardBaseUrl } from "services/urlConstant";
import { globalStyles } from "styles";
import { formatPeriodDate, getErrors } from "utils";

function DetailUnclaimMonitoring() {
  const classes = globalStyles();
  const history = useHistory();
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const [loadingPage, setLoadingPage] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    size: 10,
  });
  const [tableData, setTableData] = useState([]);
  const [detailUnclaimMonitoring, setDetailUnclaimMonitoring] = useState({});
  const [tablePageCount, setTablePageCount] = useState(1);

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };

  const getDetail = async () => {
    setLoadingPage(true);
    const url = `${hardBaseUrl}/process/detail/${id}`;
    try {
      const res = await axios.get(url, { headers });
      const { data } = res?.data;
      setDetailUnclaimMonitoring(data);
    } catch (error) {
      ModalError(getErrors(error));
    } finally {
      setLoadingPage(false);
    }
  };
  const getDataTable = async () => {
    setLoadingPage(true);
    const url = `${hardBaseUrl}/process/results`;
    const params = {
      ...queryParams,
      process_id: id,
    };
    try {
      const res = await axios.get(url, { headers, params });
      const { data, meta } = res?.data;
      setTableData(data);
      setTablePageCount(getTotalPage(meta?.total, queryParams?.size));
    } catch (error) {
      ModalError(getErrors(error));
    } finally {
      setLoadingPage(false);
    }
  };
  const handleRetryUnclaimProcess = async () => {
    setButtonLoading(true);
    await axios
      .post(`${hardBaseUrl}/unclaim/retry/${id}`, {}, { headers })
      .then(() => {
        ModalSuccess("Successfully Retry Unclaim Process").then(() =>
          history.goBack()
        );
      })
      .catch(error => ModalError(getErrors(error)))
      .finally(() => setButtonLoading(false));
  };

  useEffect(() => {
    getDetail();
  }, []);
  useEffect(() => {
    getDataTable();
  }, [queryParams]);

  return (
    <Page className={classes?.root} title="Song Claim">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title="Detail Unclaim Monitoring"
            breadcrumbData={breadcrumbData}
            backButton
          />
          <Divider className={classes?.divider} />
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            my="16px"
          >
            <Grid item>
              <SectionLabel
                title="Detail Of Unclaim Monitoring"
                subTitle="View the detail of unclaim monitoring"
              />
            </Grid>
            {detailUnclaimMonitoring?.status === "Error" && (
              <Grid item>
                <PrimaryButton
                  label={
                    buttonLoading ? "Retrying ..." : "Retry Unclaim Process"
                  }
                  onClick={handleRetryUnclaimProcess}
                  loading={buttonLoading}
                  disabled={buttonLoading}
                />
              </Grid>
            )}
          </Grid>
          <InnoTableV2
            isLoading={false}
            columns={columnTable}
            handleChangePage={(_, value) =>
              handleChangeQueryParams(value, "page")
            }
            handleChangeRowsPerPage={e => {
              handleChangeQueryParams(e?.target?.value, "size");
            }}
            items={tableData || []}
            page={queryParams?.page}
            rowsPerPage={queryParams?.size}
            totalPage={tablePageCount}
          />
        </Container>
      )}
    </Page>
  );
}

const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Unclaim Monitoring",
    link: "/admin/publisher/unclaim-monitoring",
  },
  {
    label: "View Unclaim Monitoring",
    active: true,
  },
];
const columnTable = [
  {
    name: "data_content",
    title: "Song Title",
    renderText: item => (
      <Typography fontSize={14} fontWeight={500}>
        {item?.song_title}
      </Typography>
    ),
  },
  {
    name: "data_content",
    title: "ISRC Code",
    renderText: item => item?.isrc_code,
  },
  {
    name: "data_content",
    title: "Number Of Stream",
    renderText: item => item?.number_of_streams,
  },
  {
    name: "data_content",
    title: "DSP Name",
    renderText: item => item?.dsp_name,
  },
  {
    name: "data_content",
    title: "Period Date",
    renderText: item => formatPeriodDate(item?.start_date, item?.end_date),
  },
];
export default DetailUnclaimMonitoring;
