import { Container, Divider, Box } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Grid } from "@mui/material";
import axios from "axios";
import {
  ModalError,
  ModalSuccess,
  Page,
  PrimaryButton,
  SearchTextInput,
  SelectInput,
  SkeletonComponent,
  StatusChip,
  ButtonGroupTop,
  ArrayChip,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { debounce } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { globalStyles } from "styles";
import { formatDate, getErrors, textCapitalization } from "utils";
import { hardBaseUrl } from "../../../services/urlConstant";

function OriginalPublisherContract() {
  const classes = globalStyles();
  const history = useHistory();
  const location = useLocation();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const urlParams = new URLSearchParams(location.search);
  const paramsSearch = urlParams.get("search");
  const paramsPage = urlParams.get("page");
  const paramsSize = urlParams.get("limit");
  const paramsCategory = urlParams.get("category");
  const paramsStatus = urlParams.get("status");
  const paramsApprovalStatus = urlParams.get("approval");

  const [loadingPage, setLoadingPage] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    v: 2,
    page: Number(paramsPage) || 1,
    limit: Number(paramsSize) || 10,
    offset: 0,
    search: paramsSearch || "",
    composer: "",
    category: paramsCategory || "",
    status: paramsStatus || "",
    approval: paramsApprovalStatus || "",
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);

  const handleChangePageParams = (value, key) => {
    urlParams.set(key, value);
    history.push({ search: urlParams.toString() });
  };
  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
    handleChangePageParams(value, key);
    if (key !== "page") {
      handleChangePageParams(1, "page");
    }
  };

  const getDataTable = async () => {
    const options = {
      headers,
      params: queryParams,
    };
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/publisher/contract`, options);
      const { data, meta } = res?.data;
      setDataTable(data?.contracts);
      const pageCount = getTotalPage(meta?.total, queryParams?.limit);
      setTableTotalPage(pageCount || 1);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );
  const handleDelete = async item => {
    const { category, contract_id } = item;
    const mainContract = category === "main";
    const url = `${hardBaseUrl}/publisher/contract${mainContract ? "/composer-main" : ""
      }/${contract_id}`;
    setLoadingPage(true);
    await axios
      .delete(url, { headers })
      .then(() =>
        ModalSuccess("Delete Contract Success").then(result => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        })
      )
      .catch(error =>
        ModalError(getErrors(error?.response)).then(result => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        })
      )
      .finally(() => setLoadingPage(false));
  };

  useEffect(() => {
    debounceDataTable();
    return () => {
      debounceDataTable.cancel();
    };
  }, [queryParams, debounceDataTable]);

  return (
    <Page className={classes.root} title="Contract Composer/Author">
      <Container maxWidth={false}>
        <HeaderTitle
          title="Contract Composer/Author"
          breadcrumbData={breadcrumbData}
        />
        <ButtonGroupTop />
        <Box marginBottom="20px">
          <Divider />
        </Box>
        <Grid container justifyContent="space-between" spacing={1}>
          <Grid item>
            <SearchTextInput
              placeholder="Search"
              value={queryParams?.search}
              onChange={e =>
                handleChangeQueryParams(e?.target?.value, "search")
              }
            />
          </Grid>
          <Grid item>
            <Grid container columnSpacing={1} alignItems="center">
              <Grid item>
                <SelectInput
                  placeholder="None"
                  label="Category"
                  value={queryParams?.category}
                  onChange={e =>
                    handleChangeQueryParams(e?.target?.value, "category")
                  }
                  options={categoryList}
                  optionKey="value"
                  optionLabel="label"
                  width={150}
                />
              </Grid>
              <Grid item>
                <SelectInput
                  placeholder="None"
                  label="Status"
                  value={queryParams?.status}
                  onChange={e =>
                    handleChangeQueryParams(e?.target?.value, "status")
                  }
                  options={statusList}
                  optionKey="value"
                  optionLabel="label"
                  width={150}
                />
              </Grid>
              <Grid item>
                <SelectInput
                  placeholder="None"
                  label="Approval Status"
                  value={queryParams?.approval}
                  onChange={e =>
                    handleChangeQueryParams(e?.target?.value, "approval")
                  }
                  options={approvalStatusList}
                  optionKey="value"
                  optionLabel="label"
                  width={200}
                />
              </Grid>
              <Grid item>
                <PrimaryButton
                  label="Create Contract"
                  onClick={() => history.push("/admin/kontrak-composer/tambah")}
                  startIcon={<AddIcon />}
                  size="large"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        {loadingPage ? (
          <SkeletonComponent variant="wave" />
        ) : (
          <InnoTableV2
            isLoading={loadingPage}
            columns={columnTable}
            items={dataTable}
            page={queryParams?.page}
            rowsPerPage={queryParams?.limit}
            totalPage={tableTotalPage}
            handleChangePage={(_, page) =>
              handleChangeQueryParams(page, "page")
            }
            handleChangeRowsPerPage={e =>
              handleChangeQueryParams(e?.target?.value, "limit")
            }
            isHaveAction={true}
            handleEdit={item =>
              history.push({
                pathname: `/admin/kontrak-composer/edit/${item.contract_id}`,
                state: { customer: item },
              })
            }
            handleView={item =>
              history.push({
                pathname: `/admin/kontrak-composer/${item.contract_id}`,
                state: { customer: item },
              })
            }
            isUsingDeleteConfirmation={true}
            handleDelete={handleDelete}
            deleteName={"contract_number"}
          />
        )}
      </Container>
    </Page>
  );
}

const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Contract Composer/Author",
    active: true,
  },
];
const categoryList = [
  {
    label: "Main",
    value: "main",
  },
  {
    label: "Song",
    value: "song",
  },
];
const statusList = [
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Non-active",
    value: "non-active",
  },
];
const approvalStatusList = [
  {
    label: "Approved",
    value: "approved",
  },
  {
    label: "Rejected",
    value: "rejected",
  },
  {
    label: "Waiting for Approval",
    value: "waiting_for_approval",
  },
  {
    label: "Waiting for Editing Approval",
    value: "waiting_for_editing_approval",
  },
];
const columnTable = [
  {
    name: "contract_number",
    title: "Contract Number",
    componentType: "text",
  },
  {
    name: "category",
    title: "Category",
    componentType: "text",
    renderText: item => textCapitalization(item, "category"),
  },
  {
    name: "songs",
    title: "Song Title",
    renderText: items => {
      const modifiedItems = (items || [])?.map(item => ({
        ...item,
        name: item?.song_title || "-",
      }));
      return <ArrayChip list={modifiedItems} max={3} />;
    },
  },

  {
    name: "all",
    title: "Composer / Author",
    renderText: item =>
      item?.category === "main"
        ? item?.composer?.sure_name
        : item?.composers?.map(item => item?.sure_name).join(", "),
  },
  {
    name: "start_date",
    title: "Start Date",
    componentType: "text",
    renderText: item =>
      item ? formatDate(moment(item, "DD-MM-YYYY HH:mm")) : "-",
  },
  {
    name: "end_date",
    title: "End Date",
    componentType: "text",
    renderText: item =>
      item ? formatDate(moment(item, "DD-MM-YYYY HH:mm")) : "-",
  },
  {
    name: "is_active_flag",
    title: "Contract Status",
    componentType: "text",
    renderText: status => (
      <StatusChip
        type={status && "success"}
        label={status ? "Active" : "Inactive"}
      />
    ),
  },
  {
    name: "updated_at",
    title: "Last Update",
    componentType: "text",
    renderText: item => (item ? formatDate(moment(item, "DD-MMM-YYYY")) : "-"),
  },
  {
    name: "approval_status",
    title: "Approval Status",
    componentType: "text",
    renderText: status => approvalStatusList?.find(item => item.value === status)?.label || "-",
  },
];
export default OriginalPublisherContract;
