import { Divider } from "@material-ui/core";
import { Container, Grid } from "@mui/material";
import axios from "axios";
import {
  ButtonGroupTop,
  ModalError,
  ModalSuccess,
  Page,
  PrimaryButton,
  SectionLabel,
  SkeletonComponent,
  TableAction,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { globalStyles } from "styles";
import { emptyText, getErrors, stringTruncate } from "utils";
import { hardBaseUrl } from "../../../../services/urlConstant";
import { Add } from "@material-ui/icons";

function Pembayaran() {
  const classes = globalStyles();
  const history = useHistory();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingPage, setLoadingPage] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    per_page: 10,
    search: "",
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };

  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/currency/datatable`, {
        headers,
        params: queryParams,
      });
      const { data, meta } = res?.data;
      setDataTable(data || []);
      const pageCount = getTotalPage(meta?.total, queryParams?.per_page);
      setTableTotalPage(pageCount || 1);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const handleDelete = async item => {
    try {
      await axios.delete(`${hardBaseUrl}/currency/delete/${item?.id}`, {
        headers,
      });
      ModalSuccess("Data has been deleted").then(() => getDataTable());
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };

  useEffect(() => {
    getDataTable();
  }, [queryParams]);

  return (
    <Page className={classes.root} title="Currency">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle title="Parameter" breadcrumbData={breadcrumbData} />
          <ButtonGroupTop />
          <Divider className={classes?.flatDivider} />
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            my="16px"
          >
            <Grid item>
              <SectionLabel
                title="Currency"
                subTitle="Feature to add currency information"
              />
            </Grid>
            <Grid item>
              <PrimaryButton
                label="Add Currency"
                onClick={() =>
                  history.push("/admin/parameter/admin-currency/tambah")
                }
                startIcon={<Add />}
                size="large"
              />
            </Grid>
          </Grid>
          <InnoTableV2
            isLoading={false}
            columns={columnTable}
            items={dataTable}
            page={queryParams?.page}
            rowsPerPage={queryParams?.per_page}
            totalPage={tableTotalPage}
            handleChangePage={(_, page) =>
              handleChangeQueryParams(page, "page")
            }
            handleChangeRowsPerPage={e =>
              handleChangeQueryParams(e?.target?.value, "size")
            }
            isHaveAction
            renderAction={item => (
              <TableAction
                handleEdit={() =>
                  history.push({
                    pathname: `/admin/parameter/admin-currency/edit/${item?.id}`,
                    state: { customer: item },
                  })
                }
                tooltipEdit="Update"
                deleteConfirmation
                handleDelete={() => handleDelete(item)}
                deleteConfirmationTitle="Remove Currency"
                deleteConfirmationKey={item?.currency_name}
                tooltipDelete="Remove Currency"
              />
            )}
          />
        </Container>
      )}
    </Page>
  );
}

const breadcrumbData = [
  {
    label: "Parameter",
    link: "/admin/parameter/admin-currency",
  },
  {
    label: "Currency",
    active: true,
  },
];
const columnTable = [
  {
    name: "currency_iso_code",
    title: "ISO Code",
    renderText: item => emptyText(item),
  },
  {
    name: "currency_name",
    title: "Name",
    renderText: item => emptyText(item),
  },
  {
    name: "currency_symbol_code",
    title: "Symbol",
    renderText: item => emptyText(item),
  },
  {
    name: "description",
    title: "Description",
    renderText: item => stringTruncate(item, 50) || "-",
  },
];

export default Pembayaran;
