import { Container, Divider, Typography } from "@material-ui/core";
import { Box, Grid } from "@mui/material";
import axios from "axios";
import {
  CurrencyDisplay,
  FormLabel,
  ModalError,
  Page,
  PrimaryButton,
  SectionLabel,
  SkeletonComponent,
  StatusChip,
} from "components";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { HeaderTitle } from "layouts";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { globalStyles } from "styles";
import { formatDate, getErrors, textCapitalization } from "utils";
import { hardBaseUrl } from "../../../../services/urlConstant";

const PenciptaDetailPayment = ({ userLogin }) => {
  const classes = globalStyles();
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingPage, setLoadingPage] = useState(false);
  const [pageDetail, setPageDetail] = useState({});

  const getDetail = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(
        `${hardBaseUrl}/composer/transaction/payment/${id}`,
        { headers }
      );
      setPageDetail(res?.data?.data);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  useEffect(() => {
    getDetail();
  }, []);

  const inputRef = useRef(null);

  const chipLabel = status => {
    if (status === "waiting_for_approval") {
      return "Waiting for approval";
    } else {
      return textCapitalization(status);
    }
  };
  const chipColor = status => {
    if (status === "paid" || status === "approved") {
      return "success";
    } else if (status === "unpaid" || status === "rejected") {
      return "danger";
    } else if (status === "waiting_for_approval") {
      return "warning";
    }
  };

  const handlePrintPDF = () => {
    html2canvas(inputRef.current, { useCORS: true }).then(canvas => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "px", "a4");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("download.pdf");
    });
  };

  const detailPayment = [
    { label: "Date:", value: formatDate(pageDetail?.date) },
    { label: "Composer:", value: pageDetail?.composer_name || "-" },
    {
      label: "Value",
      value: (
        <CurrencyDisplay
          decimalScale={2}
          prefix="Rp"
          value={pageDetail?.transaction_value || 0}
        />
      ),
    },
    {
      label: "Remaining Balance",
      value: (
        <CurrencyDisplay
          decimalScale={2}
          prefix="Rp"
          value={pageDetail?.loan_balance_number || 0}
        />
      ),
    },
  ];

  return (
    <Page className={classes.root} title="Payment Detail">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title="Payment Transaction"
            breadcrumbData={breadcrumbData}
            backButton
          />
          <Divider className={classes.divider} />
          <Grid container justifyContent="space-between">
            <Grid item>
              <SectionLabel
                title="Payment Detail"
                subTitle="This is detail credit note information, in payment transaction"
              />
            </Grid>
            <Grid item>
              <StatusChip
                label={chipLabel(pageDetail?.status)}
                type={chipColor(pageDetail?.status)}
              />
            </Grid>
          </Grid>
          <Box
            border="1px solid #ebebeb"
            borderRadius="5px"
            p="24px"
            my="16px"
            ref={inputRef}
          >
            <Grid container justifyContent="space-between">
              <Grid item xs={12} md={6}>
                <img
                  src={userLogin?.publisher?.profile_image}
                  alt="img"
                  height="50px"
                />
              </Grid>
              <Grid item xs={12} md={6} textAlign="right">
                <FormLabel label={pageDetail?.publisher_name} />
                <SectionLabel subTitle={pageDetail?.publisher_address} />
              </Grid>
            </Grid>
            <Divider className={classes?.divider} />
            <Grid container columnSpacing={1}>
              <Grid item xs={12} md={6}>
                <Typography>Request Payment to:</Typography>
                <FormLabel label={pageDetail?.publisher_name} />
              </Grid>
              <Grid item xs={12} md={6}>
                {detailPayment?.map((item, index) => (
                  <Grid container justifyContent="space-between" key={index}>
                    <Grid item>{item?.label}</Grid>
                    <Grid item textAlign="right">
                      {item?.value}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Divider className={classes?.divider} />
            <FormLabel label="Notes:" />
            <Typography>{pageDetail?.notes}</Typography>
            <Box bgcolor="#f2f2f2" borderRadius="5px" p="16px" my="16px">
              <Grid container justifyContent="space-between">
                <Grid item>Value</Grid>
                <Grid item>
                  <CurrencyDisplay
                    decimalScale={2}
                    prefix="Rp"
                    value={pageDetail?.transaction_value}
                    fontWeight={600}
                  />
                </Grid>
              </Grid>
              <Divider className={classes?.divider} />
              {Boolean(
                pageDetail?.transaction_payment_detail?.taxes?.length
              ) && (
                <Box>
                  {pageDetail?.transaction_payment_detail?.taxes?.map(
                    (tax, index) => (
                      <Grid
                        container
                        justifyContent="space-between"
                        key={index}
                      >
                        <Grid
                          item
                        >{`${tax?.code} ${tax?.rate}% (${tax?.method})`}</Grid>
                        <Grid item>
                          <CurrencyDisplay
                            decimalScale={2}
                            prefix="Rp"
                            value={tax?.value}
                            fontWeight={600}
                          />
                        </Grid>
                      </Grid>
                    )
                  )}
                  <Divider className={classes?.divider} />
                </Box>
              )}
              {pageDetail?.transaction_payment_detail?.cost_allocation &&
                pageDetail?.transaction_payment_detail?.cost_allocation
                  .cost_allocation_percentage && (
                  <Box>
                    <Grid container justifyContent="space-between">
                      <Grid
                        item
                      >{`Cost Allocation ${pageDetail?.transaction_payment_detail?.cost_allocation.cost_allocation_percentage}`}</Grid>
                      <Grid item>
                        <CurrencyDisplay
                          decimalScale={2}
                          prefix="Rp"
                          value={
                            pageDetail?.transaction_payment_detail
                              .cost_allocation
                          }
                          fontWeight={600}
                        />
                      </Grid>
                    </Grid>
                    <Divider className={classes?.divider} />
                  </Box>
                )}
              {Boolean(
                pageDetail?.transaction_payment_detail?.agent_fee?.length
              ) && (
                <Box>
                  {pageDetail?.transaction_payment_detail?.agent_fee?.length?.map(
                    (agent, index) => (
                      <Grid
                        container
                        justifyContent="space-between"
                        key={index}
                      >
                        <Grid
                          item
                        >{`Agent Fee (${agent?.agent_percentage}%)`}</Grid>
                        <Grid item>
                          <CurrencyDisplay
                            decimalScale={2}
                            prefix="Rp"
                            value={agent?.agent_value}
                          />
                        </Grid>
                      </Grid>
                    )
                  )}
                  <Divider className={classes?.divider} />
                </Box>
              )}

              <Grid container justifyContent="space-between">
                <Grid item>Total Value</Grid>
                <Grid item>
                  <CurrencyDisplay
                    decimalScale={2}
                    prefix="Rp"
                    value={
                      pageDetail?.transaction_payment_detail?.total_revenue
                    }
                    fontWeight={600}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
          {pageDetail?.status === "paid" && (
            <Grid container justifyContent="right" columnSpacing={1}>
              <PrimaryButton label="Download As PDF" onClick={handlePrintPDF} />
            </Grid>
          )}
        </Container>
      )}
    </Page>
  );
};

const breadcrumbData = [
  {
    label: "Transaction",
    link: "/pencipta/payment",
  },
  {
    label: "Payment",
    link: "/pencipta/payment",
  },
  {
    label: "Payment Detail",
    active: true,
  },
];
export default PenciptaDetailPayment;
