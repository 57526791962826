import { Divider } from "@material-ui/core";
import { Box, Container, Grid } from "@mui/material";
import axios from "axios";
import {
  FormLabel,
  ModalError,
  ModalSuccess,
  MultipleSelectInputWithTags,
  Page,
  PrimaryButton,
  SectionLabel,
  SkeletonComponent,
  TextInput,
} from "components";
import { HeaderTitle } from "layouts";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { hardBaseUrl } from "services/urlConstant";
import { globalStyles } from "styles";
import { getErrors } from "utils";

const FormParameterGroup = () => {
  const classes = globalStyles();
  const history = useHistory();
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const pageTitle = `${id ? "Edit" : "Add"} Group`;

  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [optionComposer, setOptionComposer] = useState([]);
  const [selectedComposer, setSelectedComposer] = useState([]);
  const [payload, setPayload] = useState({
    group_name: "",
    composer_id: [],
  });
  const [queryParams, setQueryParams] = useState({
    page: 1,
    size: 10,
    search: "",
  });
  const [hasMore, setHasMore] = useState(true);

  const handleChangePayload = (value, key) => {
    setPayload(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };
  const handleChangeMember = (event, option) => {
    const { checked } = event?.target || false;
    setSelectedComposer(prev =>
      checked
        ? [...prev, option]
        : prev.filter(item => item.composer_id !== option.composer_id)
    );
  };
  const getOptionComposer = async () => {
    try {
      setLoadingFilter(true);
      const res = await axios.get(`${hardBaseUrl}/publisher/composer`, {
        headers,
        params: queryParams,
      });
      const { data, meta } = res?.data;
      const newOptions = (data || [])?.map(item => ({
        ...item,
        id: item?.composer_id,
      }));
      setOptionComposer(prev =>
        queryParams.page === 1 ? newOptions : [...prev, ...newOptions]
      );
      setHasMore(meta?.total > newOptions.length);
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    } finally {
      setLoadingFilter(false);
    }
  };
  const getDetail = async () => {
    try {
      const res = await axios.get(
        `${hardBaseUrl}/publisher/group/detail/${id}`,
        {
          headers,
        }
      );
      const { group_name, composers } = res?.data?.data;
      setPayload({
        group_name,
        composer_id: composers?.map(composer => composer?.composer_id),
      });
      setSelectedComposer(composers);
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    }
  };
  const handleSubmit = async () => {
    const method = id ? axios.put : axios.post;
    const { group_name } = payload;
    const modifiedPayload = {
      group_name,
      composer_id: selectedComposer?.map(composer => composer?.composer_id),
    };
    try {
      setLoadingPage(true);
      await method(
        `${hardBaseUrl}/publisher/group${id ? `/update/${id}` : "/create"}`,
        modifiedPayload,
        { headers }
      );
      ModalSuccess(
        `Successfully ${id ? "update" : "save"} group`
      ).then(() => history.goBack());
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };

  const fetchData = async () => {
    setLoadingPage(true);
    const promises = id ? [getOptionComposer()] : [];
    if (id) promises.push(getDetail());

    try {
      await Promise.all(promises);
    } catch (error) {
      ModalError(error, "Error fetching data");
    } finally {
      setLoadingPage(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    getOptionComposer();
  }, [queryParams]);

  console.log({ selectedComposer: selectedComposer });

  return (
    <Page className={classes.root} title={pageTitle}>
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title={pageTitle}
            breadcrumbData={breadcrumbData({ pageTitle })}
            backButton
          />
          <Divider className={classes?.divider} />
          <SectionLabel
            title="Group Information"
            subTitle={`To ${id ? "edit" : "add"} Group's data.`}
          />
          <Box width="70%">
            <Box
              border="1px solid #ebebeb"
              borderRadius="5px"
              p="24px"
              my="16px"
            >
              <Grid container columnSpacing={2} rowSpacing={1}>
                <Grid item xs={12} md={6}>
                  <FormLabel label="Name" required />
                  <TextInput
                    placeholder="Name"
                    value={payload?.group_name}
                    onChange={e =>
                      handleChangePayload(e?.target?.value, "group_name")
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormLabel label="Member" required />
                  <MultipleSelectInputWithTags
                    placeholder={"Select Member"}
                    value={selectedComposer}
                    options={optionComposer}
                    optionKey="composer_id"
                    optionLabel="sure_name"
                    onChange={handleChangeMember}
                    search
                    searchValue={queryParams?.search}
                    onChangeSearch={value =>
                      handleChangeQueryParams(value, "search")
                    }
                    setQueryParams={setQueryParams}
                    hasMore={hasMore}
                    loading={loadingFilter}
                  />
                </Grid>
              </Grid>
            </Box>
            <Grid container justifyContent="right">
              <PrimaryButton label="Save" onClick={handleSubmit} />
            </Grid>
          </Box>
        </Container>
      )}
    </Page>
  );
};

const breadcrumbData = ({ pageTitle }) => [
  {
    label: "Parameter",
    link: "/admin/parameter/performer",
  },
  {
    label: "Performer",
    link: "/admin/parameter/performer",
  },
  {
    label: pageTitle,
    active: true,
  },
];
export default FormParameterGroup;
