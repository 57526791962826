import {
  Backdrop,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import moment from "moment";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import updateIcon from "../../../../assets/img/pencilVector.svg";
import deleteIcon from "../../../../assets/img/trashVector.svg";
import {
  AutoCompleteComponent,
  DatePicker,
  ModalError,
  ModalSuccess,
  Page,
  PrimaryButton,
} from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
import { getErrors } from "../../../../utils";
import { HeaderTitle } from "layouts";
import { useHistory } from "react-router";
import { Box } from "@mui/material";
const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  btnSubmit: {
    backgroundColor: "black",
    marginRight: "10px",
    color: "white",
    width: "106px",
    height: "40px",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  btnPreview: {
    backgroundColor: "black",
    marginRight: "10px",
    color: "white",
    width: "106px",
    height: "40px",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  btnDialog: {
    backgroundColor: "black",
    marginRight: "10px",
    marginBottom: "10px",
    color: "white",
    width: "150px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  headText: {
    fontSize: "12px",
    color: "#687083",
    fontWeight: "bold",
  },
  bodyTextBlack: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#111827",
    fontStyle: "normal",
  },
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  btnEdit: {
    backgroundColor: "#111827",
    color: "white",
    width: "30px",
    height: "35px",
    padding: "6px",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "#111827",
      color: "white",
    },
  },
  btnDelete: {
    backgroundColor: "#111827",
    color: "white",
    width: "35px",
    height: "35px",
    padding: "6px",
    borderRadius: "6px",
    marginLeft: "10px",
    cursor: "pointer",
  },
  required: {
    color: "red",
  },
  mt5: {
    marginTop: "5px",
  },
}));
function TambahPencipta(props) {
  const ref = useRef(null);
  const classes = useStyles();
  const history = useHistory();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [alias, setAlias] = useState("");
  const [publisher, setPublisher] = useState("");
  const [nik, setNik] = useState("");
  const [ipi_name_number, set_ipi_name_number] = useState("");
  const [ipi_base_number, set_ipi_base_number] = useState("");
  const [npwp, setNpwp] = useState("");
  const [identification_type, setIdentification_type] = useState("");
  const [getColor, setGetColor] = useState("");
  const [alamat, setAlamat] = useState("");
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [sharePercentage, setSharePercentage] = useState(0);
  const [contractNumber, setContractNumber] = useState("");
  const [openModalSelectPerformer, setOpenModalSelectPerformer] = useState(
    false
  );
  const [statusDialog, setStatusDialog] = useState("add");
  const [aliasName, setAliasName] = useState("");
  const [selectedAliasIndex, setSelectedAliasIndex] = useState(null);
  const [delLabel, setDelLabel] = useState("");
  const [tableAliasName, setTableAliasName] = useState([]);
  const [confirmDel, setConfirmDel] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [optionPublisher, setOptionPublisher] = useState([]);
  let token = localStorage.getItem("token");
  const handleChangeStartDate = date => {
    if (endDate && moment(date).isAfter(endDate)) {
      setEndDate(date);
    }
    setStartDate(date);
  };
  const handleChangeEndDate = date => {
    if (startDate && moment(date).isBefore(startDate)) {
      setStartDate(date);
    }
    setEndDate(date);
  };
  const handleChange = event => {
    const { name, value } = event.target;
    if (name === "name") {
      setName(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "phone") {
      setPhone(value);
    } else if (name === "nik") {
      setNik(value);
    } else if (name === "alias") {
      setAlias(value);
    } else if (name === "npwp") {
      setNpwp(value);
    } else if (name === "alamat") {
      setAlamat(value);
    } else if (name === "sharePercentage") {
      setSharePercentage(value);
    } else if (name === "contractNumber") {
      setContractNumber(value);
    } else if (name === "aliasName") {
      setAliasName(value);
    } else if (name === "identification_type") {
      setIdentification_type(value);
    } else if (name === "publisher") {
      setPublisher(value);
    }
  };

  const getOptionPublisher = async () => {
    try {
      const res = await axios.get(`${hardBaseUrl}/publisher`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const modifiedData = res?.data?.data?.map(item => ({
        ...item,
        id: item?.publisher_id,
        label: item?.name,
      }));
      setOptionPublisher(modifiedData);
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    }
  };

  const getDateFormatForFilter = date => date.format("YYYY-MM-DD");
  const notifError = text =>
    Swal.fire({
      title: "Oops…",
      icon: "error",
      text: text,
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
    });
  const regexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

  const handleButtonSimpan = async () => {
    if (!name) {
      return notifError("Composer/Author name can't be empty");
    }
    if (email?.length > 0) {
      if (!regexEmail.test(email)) {
        return notifError(
          "Please enter your email address in format: yourname@example.com"
        );
      }
    }
    if (!sharePercentage) {
      return notifError("Please enter the general share percentage");
    }
    if (nik && !identification_type) {
      return notifError("Identification Type can't be empty");
    }
    if (identification_type && !nik) {
      return notifError("Identification Number can't be empty");
    }
    if (name !== "" && sharePercentage) {
      const payload = {
        composer_id: 0,
        sure_name: name,
        alias_names: tableAliasName,
        song_aliases: tableAliasName,
        email: email,
        phone_number: phone,
        npwp: npwp,
        nik: nik,
        address: alamat,
        ipi_base_number,
        ipi_name_number,
        identification_type: identification_type,
        composer_publisher: {
          composer_publisher_id: 0,
          contract_number: contractNumber,
          publisher_id: publisher,
          contract_start_period: getDateFormatForFilter(startDate),
          contract_end_period: getDateFormatForFilter(endDate),
          share_percentage: Number(sharePercentage),
        },
      };

      setSubmitting(true);
      try {
        setSubmitting(true);
        await axios.post(`${hardBaseUrl}/publisher/composer`, payload, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        ModalSuccess("Composer data was successfully added").then(() =>
          history.goBack()
        );
      } catch (error) {
        ModalError(error?.response ? getErrors(error?.response) : error);
      } finally {
        setSubmitting(false);
      }
    }
  };
  const getMainDsp = async () => {
    try {
      const token = localStorage.getItem("token");
      const url = `${hardBaseUrl}/me`;
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setGetColor(res.data.data.publisher.theme_color);
    } catch (e) {
      new Error(e);
    }
  };
  useEffect(() => {
    getMainDsp();
    getOptionPublisher();
  }, []);
  const onClickAddAlias = () => {
    setStatusDialog("add");
    setAliasName("");
    setOpenModalSelectPerformer(true);
  };
  const handleResetDialogPerformer = () => {
    setOpenModalSelectPerformer(false);
    setAliasName("");
  };
  const onClickDialogAlias = e => {
    e.preventDefault();
    if (aliasName !== "") {
      let newAlias = aliasName;
      let newArr = tableAliasName;
      newArr.push(newAlias);
      setTableAliasName(newArr);
      setOpenModalSelectPerformer(false);
      setAliasName("");
    } else {
      setOpenModalSelectPerformer(false);
      Swal.fire({
        title: "Error",
        text: "Please Fill Alias Name.",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      }).then(result => {
        if (result.value) {
          setOpenModalSelectPerformer(true);
        }
      });
    }
  };
  const goDelete = () => {
    let currentAlias = tableAliasName;
    let afterRemove = _.remove(currentAlias, function(n) {
      return n !== delLabel;
    });

    setTableAliasName(afterRemove);
    setConfirmDel(false);
  };
  const onDel = e => {
    setDelLabel(e);
    setConfirmDel(true);
  };
  const onClickUpdateDialogAlias = e => {
    e.preventDefault();
    let currentTable = tableAliasName;
    if (aliasName !== "") {
      currentTable[selectedAliasIndex] = aliasName;
      setTableAliasName(currentTable);
      setOpenModalSelectPerformer(false);
    } else {
      setOpenModalSelectPerformer(false);
      Swal.fire({
        title: "Error",
        text: "Please Fill Alias Name.",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      }).then(result => {
        if (result.value) {
          setOpenModalSelectPerformer(true);
        }
      });
    }
  };
  const onEdit = (e, index) => {
    setStatusDialog("edit");

    setAliasName(e);
    setSelectedAliasIndex(index);
    setOpenModalSelectPerformer(true);
  };

  const breadcrumbData = [
    {
      label: "Parameter",
      link: "/admin/parameter/pencipta",
    },
    {
      label: "Composer",
      link: "/admin/parameter/pencipta",
    },
    {
      label: "Add Composer/Author",
      active: true,
    },
  ];
  return (
    <Page className={classes.root} title="Add Composer">
      <Backdrop className={classes.backdrop} open={submitting}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth={false}>
        <form ref={ref}>
          <HeaderTitle
            title="Add Composer/Author"
            breadcrumbData={breadcrumbData}
            backButton
          />
          <Divider className={classes.divider} />
          <Grid container>
            <div>
              <ThemeProvider theme={theme}>
                <Typography
                  component="h1"
                  variant="h3"
                  className={classes.subTitle}
                >
                  Account Information
                </Typography>
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <p
                  style={{
                    marginTop: "5px",
                    color: "#8f8f8f",
                    fontSize: "14px",
                  }}
                >
                  Fetaures to add composer/author&apos;s account
                </p>
              </ThemeProvider>
            </div>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={8} lg={8} xl={8}>
              <Card
                style={{
                  border: "1px solid #9AA2B1",
                  borderRadius: "6px",
                  boxShadow: "none",
                }}
              >
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid
                      justifyContent="space-between"
                      item
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography className={classes.label}>
                          Composer/Author Name{" "}
                          <span className={classes.required}>*</span>
                        </Typography>
                      </ThemeProvider>
                      <TextField
                        id="outlined-basic"
                        name="name"
                        variant="outlined"
                        fullWidth={true}
                        value={name}
                        margin="dense"
                        onChange={event => handleChange(event)}
                        placeholder="Composer/Author Name"
                      />
                    </Grid>
                    <Grid
                      item
                      justifyContent="space-between"
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography className={classes.label}>
                          Phone Number{" "}
                          <span className={classes.required}>*</span>
                        </Typography>
                      </ThemeProvider>
                      <TextField
                        id="outlined-basic"
                        name="phone"
                        type="number"
                        variant="outlined"
                        value={phone}
                        fullWidth={true}
                        margin="dense"
                        onChange={event => handleChange(event)}
                        placeholder="Phone Number"
                      />
                    </Grid>
                    <Grid
                      item
                      justifyContent="space-between"
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography className={classes.label}>
                          Email <span className={classes.required}>*</span>
                        </Typography>
                      </ThemeProvider>
                      <TextField
                        id="outlined-basic"
                        name="email"
                        value={email}
                        variant="outlined"
                        fullWidth={true}
                        margin="dense"
                        type="email"
                        onChange={event => handleChange(event)}
                        placeholder="Email"
                      />
                    </Grid>
                    <Grid
                      item
                      justifyContent="space-between"
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography className={classes.label}>
                          Identification Number{" "}
                          <span className={classes.required}>*</span>
                        </Typography>
                      </ThemeProvider>
                      <TextField
                        id="outlined-basic"
                        name="nik"
                        variant="outlined"
                        placeholder="Identification Number"
                        fullWidth={true}
                        value={nik}
                        required={identification_type}
                        margin="dense"
                        type={
                          identification_type === "KITAS" ? "text" : "number"
                        }
                        onChange={event => handleChange(event)}
                        onInput={e => {
                          let inputValue = e.target.value;
                          if (identification_type === "KITAS") {
                            inputValue = inputValue.replace(
                              /[^a-zA-Z0-9]/g,
                              ""
                            );
                            inputValue = inputValue.slice(0, 11);
                          } else {
                            inputValue = Math.max(0, parseInt(inputValue))
                              .toString()
                              .slice(0, 16);
                          }
                          e.target.value = inputValue;
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      justifyContent="space-between"
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography className={classes.label}>
                          NPWP <span className={classes.required}>*</span>
                        </Typography>
                      </ThemeProvider>
                      <TextField
                        id="outlined-basic"
                        name="npwp"
                        variant="outlined"
                        margin="dense"
                        value={npwp}
                        type="number"
                        placeholder="NPWP"
                        fullWidth={true}
                        onChange={event => handleChange(event)}
                        onInput={e => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 15);
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      justifyContent="space-between"
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography className={classes.label}>
                          Identification Type{" "}
                          <span className={classes.required}>*</span>
                        </Typography>
                      </ThemeProvider>
                      <Select
                        style={{
                          width: "100%",
                          height: 37,
                          margin: "8px 0px 4px 0px",
                        }}
                        id="outlined-basic"
                        name="identification_type"
                        value={identification_type}
                        onChange={e => handleChange(e)}
                        displayEmpty
                        required={nik}
                        variant="outlined"
                        placeholder="bebas"
                        autoWidth
                        fullWidth
                      >
                        <MenuItem value="NIK">NIK</MenuItem>
                        <MenuItem value="KITAS">KITAS</MenuItem>
                      </Select>
                    </Grid>
                    <Grid
                      item
                      justifyContent="space-between"
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography className={classes.label}>
                          IPI Name Number{" "}
                          <span className={classes.required}>*</span>
                        </Typography>
                      </ThemeProvider>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        margin="dense"
                        type="number"
                        placeholder="IPI Name Number"
                        fullWidth
                        name="ipi_name_number"
                        onChange={event =>
                          set_ipi_name_number(event?.target?.value)
                        }
                        value={ipi_name_number}
                        // onInput={e => {
                        //   e.target.value = Math.max(0, parseInt(e.target.value))
                        //     .toString()
                        //     .slice(0, 15);
                        // }}
                      />
                    </Grid>
                    <Grid
                      item
                      justifyContent="space-between"
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography className={classes.label}>
                          IPI Base Number
                        </Typography>
                      </ThemeProvider>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        margin="dense"
                        type="number"
                        placeholder="IPI Base Number"
                        name="ipi_base_number"
                        fullWidth
                        value={ipi_base_number}
                        onChange={event =>
                          set_ipi_base_number(event?.target?.value)
                        }
                        // onInput={e => {
                        //   e.target.value = Math.max(0, parseInt(e.target.value))
                        //     .toString()
                        //     .slice(0, 15);
                        // }}
                      />
                    </Grid>
                    <Grid item justifyContent="space-between" xs={12}>
                      <ThemeProvider theme={theme}>
                        <Typography className={classes.label}>
                          Address
                        </Typography>
                      </ThemeProvider>
                      <TextField
                        id="outlined-basic"
                        name="alamat"
                        variant="outlined"
                        multiline
                        rows={4}
                        fullWidth={true}
                        margin="dense"
                        onChange={event => handleChange(event)}
                        placeholder="Address"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <Typography
                      component="h1"
                      variant="h3"
                      className={classes.subTitle}
                    >
                      Alias Name
                    </Typography>
                  </ThemeProvider>
                </div>
                <div>
                  <PrimaryButton
                    size="small"
                    onClick={() => onClickAddAlias()}
                    label="Add Alias Name"
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
              <Card>
                <div>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <ThemeProvider theme={theme}>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Action
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Alias Name
                            </Typography>
                          </TableCell>
                        </ThemeProvider>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableAliasName?.length > 0 ? (
                        tableAliasName.map((item, index) => {
                          return (
                            <TableRow key={`${index}`}>
                              <ThemeProvider theme={theme}>
                                <TableCell
                                  style={{
                                    width: "10%",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <IconButton
                                      onClick={() => onEdit(item, index)}
                                      className={classes.btnEdit}
                                      style={{
                                        backgroundColor: getColor || "black",
                                      }}
                                    >
                                      <div>
                                        <img
                                          src={updateIcon}
                                          alt="update-icon"
                                          style={{
                                            filter: " brightness(0) invert(1)",
                                          }}
                                        />
                                      </div>
                                    </IconButton>
                                    <IconButton
                                      onClick={() => onDel(item)}
                                      className={classes.btnDelete}
                                      style={{
                                        backgroundColor: getColor || "black",
                                      }}
                                    >
                                      <div>
                                        <img
                                          src={deleteIcon}
                                          alt="delete-icon"
                                          style={{
                                            filter: " brightness(0) invert(1)",
                                          }}
                                        />
                                      </div>
                                    </IconButton>
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{
                                    width: "30%",
                                  }}
                                >
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.bodyTextBlack}
                                  >
                                    {item}
                                  </Typography>
                                </TableCell>
                              </ThemeProvider>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell colSpan={3}>
                            <div
                              style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Typography
                                style={{
                                  color: "#687083",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}
                              >
                                No Data
                              </Typography>
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
              </Card>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <div>
                <Typography
                  component="h1"
                  variant="h3"
                  className={classes.subTitle}
                >
                  Contract Information
                </Typography>
                <ThemeProvider theme={theme}>
                  <p
                    style={{
                      marginTop: "5px",
                      color: "#8f8f8f",
                      fontSize: "14px",
                    }}
                  >
                    Fetaures to add composer/author’s contract
                  </p>
                </ThemeProvider>
              </div>
              <Card
                style={{
                  border: "1px solid #9AA2B1",
                  borderRadius: "6px",
                  boxShadow: "none",
                }}
              >
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6} xl={6}>
                      <div
                        style={{
                          marginTop: "10px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography className={classes.label}>
                            Contract Number
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div>
                        <TextField
                          id="outlined-basic"
                          name="contractNumber"
                          variant="outlined"
                          placeholder="Contract Number"
                          fullWidth={true}
                          margin="dense"
                          type="number"
                          onChange={event => handleChange(event)}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} xl={6}></Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6} xl={6}>
                      <ThemeProvider theme={theme}>
                        <label>Start Date</label>
                      </ThemeProvider>
                      <DatePicker
                        value={startDate}
                        onChange={handleChangeStartDate}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} xl={6}>
                      <ThemeProvider theme={theme}>
                        <label>End Date</label>
                      </ThemeProvider>
                      <DatePicker
                        value={endDate}
                        onChange={handleChangeEndDate}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}></Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <div>
                <Typography
                  component="h1"
                  variant="h3"
                  className={classes.subTitle}
                >
                  General Share Percentage
                </Typography>
                <ThemeProvider theme={theme}>
                  <p
                    style={{
                      marginTop: "5px",
                      color: "#8f8f8f",
                      fontSize: "14px",
                    }}
                  >
                    Set the percentage between Publisher and Composer{" "}
                  </p>
                </ThemeProvider>
              </div>
              <Card
                style={{
                  border: "1px solid #9AA2B1",
                  borderRadius: "6px",
                  boxShadow: "none",
                }}
              >
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6} xl={6}>
                      <div
                        style={{
                          marginTop: "10px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography className={classes.label}>
                            Composer Share Percentage{" "}
                            <span className={classes.required}>*</span>
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div>
                        <TextField
                          id="outlined-basic"
                          name="sharePercentage"
                          variant="outlined"
                          placeholder="Share Percentage"
                          fullWidth={true}
                          margin="dense"
                          type="number"
                          onChange={event => handleChange(event)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {"%"}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} xl={6}>
                      <div
                        style={{
                          marginTop: "10px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography className={classes.label}>
                            Publisher{" "}
                            <span className={classes.required}>*</span>
                          </Typography>
                        </ThemeProvider>
                        <AutoCompleteComponent
                          label={!publisher ? "Publisher" : ""}
                          options={optionPublisher}
                          value={
                            optionPublisher.find(
                              option => option.id === Number(publisher)
                            ) || null
                          }
                          onChange={value => setPublisher(value)}
                          width="100%"
                          size="small"
                          className={classes.mt5}
                          name="publisher"
                          renderOption={(props, option) => {
                            return (
                              <Box
                                component="li"
                                {...props}
                                key={option?.publisher_id}
                              >
                                {option?.name}
                              </Box>
                            );
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} xl={6}></Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}></Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}></Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}></Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              lg={8}
              xl={8}
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <PrimaryButton
                label={submitting ? "Saving" : "Save"}
                onClick={handleButtonSimpan}
                disabled={submitting}
                loading={submitting}
              />
            </Grid>
          </Grid>
        </form>
        <Dialog
          open={openModalSelectPerformer}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {statusDialog === "add" ? <>{"Add"}</> : <>{"Edit"}</>} Alias Name
          </DialogTitle>
          <DialogContent dividers>
            <div
              style={{
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <ThemeProvider theme={theme}>
                <Typography className={classes.label}>Alias Name</Typography>
              </ThemeProvider>
            </div>
            <div
              style={{
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              {statusDialog === "add" ? (
                <>
                  <TextField
                    id="outlined-basic"
                    placeholder="Alias Name"
                    variant="outlined"
                    fullWidth={true}
                    name="aliasName"
                    onChange={event => handleChange(event)}
                    value={aliasName}
                  />
                </>
              ) : (
                <>
                  <TextField
                    id="outlined-basic"
                    placeholder="Alias Name"
                    variant="outlined"
                    fullWidth={true}
                    name="aliasName"
                    onChange={event => handleChange(event)}
                    value={aliasName}
                  />
                </>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handleResetDialogPerformer()}
              className={classes.btnDialog}
              style={{
                backgroundColor: getColor,
              }}
            >
              <ThemeProvider theme={theme}>
                <Typography
                  style={{
                    textTransform: "none",
                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "20px",
                  }}
                >
                  Cancel
                </Typography>
              </ThemeProvider>
            </Button>
            {statusDialog === "add" ? (
              <>
                <Button
                  onClick={e => onClickDialogAlias(e)}
                  className={classes.btnDialog}
                  style={{
                    backgroundColor: getColor,
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <Typography
                      style={{
                        textTransform: "none",
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "20px",
                      }}
                    >
                      Add Alias Name
                    </Typography>
                  </ThemeProvider>
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={e => onClickUpdateDialogAlias(e)}
                  className={classes.btnDialog}
                  style={{
                    backgroundColor: getColor,
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <Typography
                      style={{
                        textTransform: "none",
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "20px",
                      }}
                    >
                      Update
                    </Typography>
                  </ThemeProvider>
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>
        <Dialog open={confirmDel} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirm Delete</DialogTitle>
          <DialogContent dividers>
            <div
              style={{
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <ThemeProvider theme={theme}>
                <Typography className={classes.label}>
                  Are You Sure want to Delete {delLabel} ?
                </Typography>
              </ThemeProvider>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setConfirmDel(false)}
              className={classes.btnDialog}
              style={{
                backgroundColor: getColor,
              }}
            >
              <ThemeProvider theme={theme}>
                <Typography
                  style={{
                    textTransform: "none",
                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "20px",
                  }}
                >
                  Cancel
                </Typography>
              </ThemeProvider>
            </Button>
            <Button
              onClick={e => goDelete(e)}
              className={classes.btnDialog}
              style={{
                backgroundColor: getColor,
              }}
            >
              <ThemeProvider theme={theme}>
                <Typography
                  style={{
                    textTransform: "none",
                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "20px",
                  }}
                >
                  Yes
                </Typography>
              </ThemeProvider>
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
}

export default TambahPencipta;
