import { Container } from "@material-ui/core";
import { Box, Grid, Typography } from "@mui/material";
import axios from "axios";
import {
  CurrencyDisplay,
  CustomTable,
  ModalError,
  Page,
  PrimaryButton,
  PrimaryTextButton,
  SkeletonComponent,
} from "components";
import { HeaderTitle } from "layouts";
import { useEffect, useState } from "react";
import { ChevronLeft } from "react-feather";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { hardBaseUrl } from "services/urlConstant";
import { globalStyles } from "styles";
import { arrayToCommaSeparatedString, getErrors } from "utils";

const DetailLagu = () => {
  const classes = globalStyles();
  const { id } = useParams();
  const { state } = useLocation();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const { year, dsp_id, month } = state?.queryParams;

  const [loadingPage, setLoadingPage] = useState(true);
  const [detailPublisher, setDetailPublisher] = useState(false);
  const [pageDetail, setPageDetail] = useState({
    song: "",
    composer_names: [],
    song_usages: [],
  });
  const [dataTable, setDataTable] = useState([]);

  const getResponse = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(
        `${hardBaseUrl}/composer/dashboard/song-detail/${id}`,
        {
          headers,
          params: {
            year,
            dsp_id,
            month,
          },
        }
      );
      const { data } = res?.data;
      setPageDetail(data);
      setDataTable(data?.song_usages);
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    } finally {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    getResponse();
  }, []);

  return (
    <Page className={classes.root} title="Composer's Song Usage Review">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title={
              <Grid container direction="column">
                <Grid item>
                  <Typography fontSize={24}>
                    Song Title:{" "}
                    <Typography component="span" fontSize={24} fontWeight={700}>
                      {pageDetail?.song?.song_title}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    fontSize={18}
                  >{`Year of Song Usage: ${year}`}</Typography>
                </Grid>
              </Grid>
            }
            breadcrumbData={breadcrumbData}
            backButton
          />
          <Box border="1px solid #ebebeb" borderRadius="5px" p="24px" my="16px">
            {songDetailList({ songDetail: pageDetail })?.map((item, index) => (
              <Grid container key={index}>
                <Grid item xs={2}>
                  <Typography>{item?.title}</Typography>
                </Grid>
                <Grid item xs={10}>
                  <Box display="flex">
                    <Typography>
                      {item?.title === "Composer"
                        ? arrayToCommaSeparatedString(item?.name)
                        : item?.name || "-"}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            ))}
          </Box>
          {detailPublisher && (
            <Box my="16px">
              <PrimaryTextButton
                onClick={() => {
                  setDetailPublisher(false);
                  setDataTable(pageDetail?.song_usages);
                }}
                label="Back"
                startIcon={<ChevronLeft />}
              />
            </Box>
          )}
          <CustomTable
            columnTable={columnTable({ detailPublisher })}
            data={dataTable}
            isHaveAction={!detailPublisher}
            renderAction={item => (
              <PrimaryButton
                label="See Details"
                onClick={() => {
                  setDetailPublisher(true);
                  setDataTable(item?.detail);
                }}
              />
            )}
          />
        </Container>
      )}
    </Page>
  );
};

const breadcrumbData = [
  {
    label: "Home",
    link: "/pencipta/dashboard-pencipta",
  },
  {
    label: "Analysis of Song Usage",
    link: "/pencipta/dashboard-pencipta",
  },
  {
    label: "Song Details",
    active: true,
  },
];
const songDetailList = ({ songDetail }) => [
  {
    title: "ISRC Code",
    name: songDetail?.song?.isrc_code,
  },
  {
    title: "Composer",
    name: songDetail?.composer_names,
  },
];
const columnTable = ({ detailPublisher }) =>
  detailPublisher
    ? [
        {
          name: "dsp_name",
          title: "DSP",
          renderText: item => item || "-",
        },
        {
          name: "listener",
          title: "Listener Traffic",
          headerAlign: "right",
          renderText: item => <CurrencyDisplay value={item} decimalScale={2} />,
        },
        {
          name: "base_currency_revenue",
          title: "Revenue",
          headerAlign: "right",
          renderText: item => (
            <CurrencyDisplay value={item} prefix="Rp " decimalScale={2} />
          ),
        },
      ]
    : [
        {
          name: "publisher_name",
          title: "Publisher",
          renderText: item => item || "-",
        },
        {
          name: "listener",
          title: "Listener Traffic",
          headerAlign: "right",
          renderText: item => <CurrencyDisplay value={item} decimalScale={2} />,
        },
        {
          name: "base_currency_revenue",
          title: "Revenue",
          headerAlign: "right",
          renderText: item => (
            <CurrencyDisplay value={item} prefix="Rp " decimalScale={2} />
          ),
        },
      ];
export default DetailLagu;
