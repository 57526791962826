import { Chip, Container, Divider } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecordRounded";
import { Box } from "@mui/material";
import axios from "axios";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import {
  ArrayChip,
  CurrencyDisplay,
  CustomTable,
  ModalError,
  Page,
  PrimaryButton,
  SecondaryButton,
  SkeletonComponent,
  SongUsageTrendSummarytCard,
} from "components";
import { HeaderTitle } from "layouts";
import { Fragment, useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { hardBaseUrl } from "services/urlConstant";
import { globalStyles } from "styles";
import { getErrors } from "utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const PrintReportReview = () => {
  const classes = globalStyles();
  const history = useHistory();
  const { state } = useLocation();
  const [dataTable, setDataTable] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const { chartReport, queryParams, dspName, dspColor, dataSet } = state;
  const queryParamsPrint = {
    sort: queryParams.sort,
    dsp_id: queryParams.dsp_id,
    publisher_id: queryParams.publisher_id,
    month: queryParams.month,
    year: queryParams.year,
  };
  const inputRef = useRef(null);
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const handlePrintPDF = useReactToPrint({
    content: () => inputRef.current,
    onBeforeGetContent: () => setLoadingButton(true),
    onAfterPrint: () => setLoadingButton(false),
  });
  const options = {
    parsing: {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      delimiter: ",",
    },
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    hover: {
      mode: "nearest",
      intersect: true,
    },
    plugins: {
      layout: {},
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
      subtitle: {
        display: false,
        text: "Custom Chart Subtitle",
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  const getDataTable = async () => {
    try {
      const res = await axios.get(`${hardBaseUrl}/publisher/song-usage`, {
        headers,
        params: queryParamsPrint,
      });
      setDataTable(res?.data?.data || []);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    getDataTable();
  }, []);

  return (
    <Page className={classes.root} title="Song Usage Review">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Fragment>
          <Container maxWidth={false} ref={inputRef}>
            <HeaderTitle title="Review of Song Usage" />
            <Divider className={classes.divider} />
            <Box
              border="1px solid #ebebeb"
              borderRadius="5px"
              padding="50px 20px"
            >
              <Box display="flex" justifyContent="start" marginBottom="20px">
                {dspName && (
                  <Chip
                    size="small"
                    label={dspName}
                    icon={
                      <FiberManualRecordIcon
                        className={classes?.chipIndicator}
                        style={{ color: dspColor }}
                      />
                    }
                    style={{
                      color: dspColor,
                      backgroundColor: `${dspColor}10`,
                    }}
                  />
                )}
              </Box>
              <Bar options={options} data={dataSet} plugins={[]} />
            </Box>
            <SongUsageTrendSummarytCard chartReport={chartReport} />
            <Box my="16px">
              <CustomTable data={dataTable || []} columnTable={columnTable} />
            </Box>
          </Container>
          <Box justifyContent="flex-end" display="flex" gap={1} m={3}>
            <SecondaryButton label="Back" onClick={() => history.goBack()} />
            <PrimaryButton
              label="Print Report"
              onClick={handlePrintPDF}
              disabled={loadingButton}
              loading={loadingButton}
            />
          </Box>
        </Fragment>
      )}
    </Page>
  );
};
const columnTable = [
  {
    name: "title",
    title: "Song Title",
    renderText: item => item || "-",
  },
  {
    name: "composer_names",
    title: "Composer/Author",
    renderText: item => <ArrayChip list={item} max={3} />,
  },
  {
    name: "listener",
    title: "Traffic",
    headerAlign: "right",
    renderText: item => <CurrencyDisplay value={item} />,
  },
  {
    name: "royalty",
    title: "Revenue",
    headerAlign: "right",
    renderText: item => <CurrencyDisplay value={item} prefix="Rp " />,
  },
];

export default PrintReportReview;
