import { Container, Divider } from "@material-ui/core";
import { Box, Grid } from "@mui/material";
import axios from "axios";
import {
  AutoCompleteComponent,
  DatePicker,
  FormLabel,
  ModalError,
  ModalSuccess,
  NumberInput,
  Page,
  PrimaryButton,
  SectionLabel,
  TextInput,
} from "components";
import { HeaderTitle } from "layouts";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { globalStyles } from "styles";
import { getErrors } from "utils";
import { hardBaseUrl } from "../../../../services/urlConstant";

const AddLoanPencipta = () => {
  const classes = globalStyles();
  const history = useHistory();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const [loadingPage, setLoadingPage] = useState(false);
  const [listComposer, setListComposer] = useState([]);
  const [payload, setPayload] = useState({
    date: moment(),
    composer_id: 0,
    value: 0,
    tenor: 0,
    note: "",
  });

  const handleChangePayload = (value, key) => {
    setPayload(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };

  const onSubmit = async () => {
    const url = `${hardBaseUrl}/publisher/transaction-loan`;

    const modifiedPayload = {
      ...payload,
      date: payload?.date.format("YYYY-MM-DD"),
    };
    setLoadingPage(true);
    await axios
      .post(url, modifiedPayload, { headers })
      .then(res => {
        if (res.status === 200) {
          ModalSuccess("Loan has been added").then(() =>
            history.push("/admin/loan")
          );
        }
      })
      .catch(error => {
        ModalError(getErrors(error?.response));
      })
      .finally(() => setLoadingPage(false));
  };
  const getComposerList = async () => {
    const url = `${hardBaseUrl}/publisher/transaction-composer`;
    setLoadingPage(true);
    try {
      const res = await axios.get(url, { headers });
      const modifiedData = (res?.data?.data || [])
        ?.filter(({ composer_name }) => composer_name)
        ?.map(item => ({
          label: item.composer_name,
          id: item.composer_id,
        }));
      const uniqueItems = modifiedData.filter(
        (item, index, array) =>
          index === array.findIndex(el => el.label === item.label)
      );
      setListComposer(uniqueItems);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  useEffect(() => {
    getComposerList();
  }, []);

  return (
    <Page className={classes.root} title="Create Transaction">
      <Container maxWidth={false}>
        <HeaderTitle
          title="Loan Transaction"
          breadcrumbData={breadcrumbData}
          backButton
        />
        <Divider className={classes.divider} />
        <SectionLabel
          title="Create Transaction"
          subTitle="Create your loan transaction here."
        />
        <Grid container>
          <Grid item xs={12} md={8}>
            <Box
              border="1px solid #ebebeb"
              borderRadius="5px"
              p="24px"
              my="16px"
            >
              <Grid container rowSpacing={3} columnSpacing={2}>
                <Grid item xs={12} md={6}>
                  <FormLabel label="Date" required />
                  <DatePicker
                    value={payload?.date}
                    onChange={date => handleChangePayload(date, "date")}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormLabel label="Composer/ Author" required />
                  <AutoCompleteComponent
                    options={listComposer}
                    value={
                      listComposer.find(
                        option => option.id === payload?.composer_id
                      ) || null
                    }
                    onChange={value =>
                      handleChangePayload(value, "composer_id")
                    }
                    size="small"
                    width="100%"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormLabel label="Tenor" required />
                  <NumberInput
                    value={payload?.tenor}
                    onChange={event =>
                      handleChangePayload(Number(event?.target?.value), "tenor")
                    }
                    endAdornment="Months"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormLabel label="Loan Value" required />
                  <NumberInput
                    value={payload?.value}
                    onChange={event =>
                      handleChangePayload(Number(event?.target?.value), "value")
                    }
                    startAdornment="Rp"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormLabel label="Notes" />
                  <TextInput
                    placeholder="Notes"
                    value={payload?.note}
                    onChange={event =>
                      handleChangePayload(event?.target?.value, "note")
                    }
                    multiline
                    rows={4}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container columnSpacing={2} justifyContent="flex-end">
                    <Grid item>
                      <PrimaryButton
                        loading={loadingPage}
                        disabled={loadingPage}
                        label="Add"
                        onClick={onSubmit}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

const breadcrumbData = [
  {
    label: "Transaction",
    link: "/admin/loan",
  },
  {
    label: "Loan",
    link: "/admin/loan",
  },
  {
    label: "Create Loan",
    active: true,
  },
];
export default AddLoanPencipta;
