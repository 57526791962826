import { Container, Divider } from "@material-ui/core";
import { Box } from "@mui/material";
import axios from "axios";
import {
  CurrencyDisplay,
  ModalError,
  Page,
  SearchTextInput,
  SkeletonComponent,
  StatusChip,
} from "components";
import { InnoTable } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { formatDate, getErrors } from "utils";
import { hardBaseUrl } from "../../../services/urlConstant";
import { globalStyles } from "styles";

function UploadMonitoring() {
  const classes = globalStyles();
  const history = useHistory();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingPage, setLoadingPage] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    size: 10,
    search: "",
    type: "UD, IC, CU",
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };

  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/process/datatable`, {
        headers,
        params: queryParams,
      });

      const { data, meta } = res?.data;
      setDataTable(data);
      const pageCount = getTotalPage(meta?.total, queryParams?.size || 1);
      setTableTotalPage(pageCount);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );

  useEffect(() => {
    debounceDataTable();
    return () => {
      debounceDataTable.cancel();
    };
  }, [queryParams, debounceDataTable]);
  return (
    <Page className={classes.root} title="Upload Monitoring">
      <Container maxWidth={false}>
        <HeaderTitle
          title="Upload Monitoring"
          breadcrumbData={breadcrumbData}
        />
        <Divider className={classes.divider} />
        <Box mb="16px">
          <SearchTextInput
            placeholder="Search"
            value={queryParams?.search}
            onChange={e =>
              handleChangeQueryParams(e?.target?.value, "search")
            }
          />
        </Box>
        {loadingPage ? (
          <SkeletonComponent variant="rect" />
        ) : (
          <InnoTable
            isLoading={false}
            columns={columnTable}
            items={dataTable}
            page={queryParams?.page}
            rowsPerPage={queryParams?.size}
            totalPage={tableTotalPage}
            handleChangePage={(_, page) =>
              handleChangeQueryParams(page, "page")
            }
            handleChangeRowsPerPage={e =>
              handleChangeQueryParams(e?.target?.value, "size")
            }
            isHaveAction
            handleView={item =>
              history.push(`/admin/detail-upload-monitoring/${item?.id}`)
            }
          />
        )}
      </Container>

    </Page>
  );
}

const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Upload Monitoring",
    active: true,
  },
];
const columnTable = [
  {
    name: "id",
    title: "Upload ID",
  },
  {
    name: "type",
    title: "Upload Type",
    renderText: type => type || "-",
  },
  {
    name: "created_at",
    title: "Upload Date",
    renderText: date => formatDate(date),
  },
  {
    name: "header",
    title: "Currency",
    renderText: item => item?.original_currency?.currency_iso_code || "-",
  },
  {
    name: "header",
    title: "Exchange Rate",
    headerAlign: "right",
    renderText: item => (
      <CurrencyDisplay
        value={item?.exchange_rate}
        decimalScale={2}
        prefix="Rp"
      />
    ),
  },
  {
    name: "status",
    title: "Upload Status",
    renderText: status => (
      <StatusChip
        label={status}
        type={
          status === "Finish"
            ? "success"
            : status === "Error"
              ? "danger"
              : status === "Started" ||
                status === "Initialize" ||
                status === "Running"
                ? "warning"
                : ""
        }
      />
    ),
  },
];

export default UploadMonitoring;
