import { Container, Divider } from "@material-ui/core";
import { Box, Grid } from "@mui/material";
import axios from "axios";
import {
  ArrayChip,
  ArrayTagChip,
  AutoCompleteComponent,
  CurrencyDisplay,
  ModalError,
  Page,
  PrimaryButton,
  SearchTextInput,
  SectionLabel,
  SelectInput,
  SkeletonComponent,
  SongUsageTrendChart,
  SongUsageTrendSummarytCard,
  YearPicker,
} from "components";
import { month } from "constants";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { debounce, isEqual } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router";
import { globalStyles } from "styles";
import { getErrors } from "utils";
import { hardBaseUrl } from "../../../services/urlConstant";

const PenciptaReviewLagu = () => {
  const classes = globalStyles();
  const history = useHistory();
  const isFirstLoad = useRef(true);
  const typeWeb = localStorage.getItem("typeWeb");
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const urlParams = new URLSearchParams(location.search);
  const paramsSearch = urlParams.get("search");
  const paramsPage = urlParams.get("page");
  const paramsSize = urlParams.get("size");
  const paramsDsp = urlParams.get("dsp_id");
  const paramsYear = urlParams.get("year");
  const paramsMonth = urlParams.get("month");

  const [loadingPage, setLoadingPage] = useState(false);
  const [optionDsp, setOptionDsp] = useState([]);
  const [csvArray, setCsvArray] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    dsp_id: Number(paramsDsp) || localStorage.getItem("idDsp") || "",
    year: Number(paramsYear) || moment().year(),
    month: Number(paramsMonth) || 0,
  });
  const [tableParams, setTableParams] = useState({
    page: Number(paramsPage) || 1,
    size: Number(paramsSize) || 10,
    search: paramsSearch || "",
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [chartReport, setChartReport] = useState({
    revenue: 0,
    traffic: 0,
    month: "",
    totalRevenue: 0,
    totalTraffic: 0,
  });
  let selectedDSP = optionDsp?.find(
    dsp => dsp?.dsp_id === Number(queryParams?.dsp_id)
  );
  const prevQueryParams = usePrevious(queryParams);

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      [key]: value,
    }));
    handleChangePageParams(value, key);
  };
  const handleChangeTableParams = (value, key) => {
    setTableParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
    handleChangePageParams(value, key);
    if (key !== "page") {
      handleChangePageParams(1, "page");
    }
  };
  const handleChangePageParams = (value, key) => {
    urlParams.set(key, value);
    history.push({ search: urlParams.toString() });
  };
  const handleChangeChartReport = (value, key) => {
    setChartReport(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const onClickChartAxis = data => {
    if (chartData?.datasets.length > 0) {
      const { dataIndex, axisValue } = data;
      let monthTotalTraffic = 0;
      let monthTotalRevenue = 0;

      chartData?.datasets?.forEach(dataset => {
        monthTotalTraffic += dataset?.listener?.[dataIndex] || 0;
        monthTotalRevenue += dataset?.data?.[dataIndex] || 0;
      });

      const monthName = moment(axisValue, "MMM YY").format("MMMM");

      handleChangeChartReport(monthTotalTraffic, "traffic");
      handleChangeChartReport(monthTotalRevenue, "revenue");
      handleChangeChartReport(monthName, "month");
    }
  };

  const getOptionDsp = async () => {
    try {
      const res = await axios.get(
        `${hardBaseUrl}/composer/song-usage/dsp/option`,
        { headers }
      );
      const modifiedData = res?.data?.data?.dsp?.map(item => ({
        ...item,
        id: item?.dsp_id,
        label: item?.name,
      }));
      setOptionDsp(modifiedData);
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };
  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(
        `${hardBaseUrl}/composer/song-usage/dsp/data`,
        {
          headers,
          params: { ...queryParams, ...tableParams },
        }
      );
      const { data, meta } = res?.data;
      setDataTable(data || []);
      const pageCount = getTotalPage(meta?.total, queryParams?.size);
      setTableTotalPage(pageCount || 1);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams, tableParams]
  );
  const getChartData = async () => {
    try {
      const res = await axios.get(
        `${hardBaseUrl}/composer/song-usage/dsp/chart`,
        {
          headers,
          params: queryParams,
        }
      );
      const { data } = res?.data || [];
      if (data) {
        const labels = data[0].chart.map(item =>
          moment(item.date).format("MMM YY")
        );
        const datasets = data.map(item => ({
          label: item?.dsp?.name,
          data: item?.chart.map(chartItem => chartItem?.royalty),
          listener: item?.chart.map(chartItem => chartItem?.listener),
          color: item?.dsp?.color,
          showMark: false,
        }));
        setChartData({
          labels,
          datasets,
        });

        const totals = data.reduce(
          (acc, item) => {
            acc.revenue += item.revenue;
            acc.traffic += item.traffic;
            return acc;
          },
          { revenue: 0, traffic: 0 }
        );
        setChartReport(currentState => ({
          ...currentState,
          totalRevenue: totals.revenue,
          totalTraffic: totals.traffic,
        }));
      } else {
        setChartData({
          labels: [],
          datasets: [],
        });
        setChartReport({
          revenue: 0,
          traffic: 0,
          month: "",
          totalRevenue: 0,
          totalTraffic: 0,
        });
      }
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };
  const getDataCSV = async () => {
    try {
      const res = await axios.get(
        `${hardBaseUrl}/publisher/song-usage/download`,
        {
          headers,
          params: {
            ...queryParams,
            ...tableParams,
            original_publisher_id: localStorage.getItem("mainDsp") || 0,
          },
        }
      );
      setCsvArray(res?.data?.data);
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };

  const fetchData = async () => {
    setLoadingPage(true);
    const promises = [];
    promises.push(getOptionDsp());
    promises.push(getDataTable());
    promises.push(getChartData());

    try {
      await Promise.all(promises);
    } catch (error) {
      ModalError(error, "Error fetching data");
    } finally {
      setLoadingPage(false);
      isFirstLoad.current = false;
    }
  };

  useEffect(() => {
    if (!isFirstLoad.current) {
      if (prevQueryParams && !isEqual(prevQueryParams, queryParams)) {
        getChartData();
      }
      const isSearching = Boolean(tableParams?.search);
      if (isSearching) {
        debounceDataTable();
        return () => {
          debounceDataTable.cancel();
        };
      } else {
        getDataTable();
      }
    } else {
      fetchData();
    }
  }, [queryParams, tableParams]);

  return (
    <Page className={classes.root} title="Song Usage Review">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle title="Song Usage" breadcrumbData={breadcrumbData} />
          <Divider className={classes.divider} />
          <Box
            border="1px solid #ebebeb"
            borderRadius="5px"
            p="24px"
            mt="16px"
            bgcolor="#F9FAFB"
          >
            <Grid container spacing={1}>
              <Grid item>
                <AutoCompleteComponent
                  label="DSP"
                  options={optionDsp}
                  value={
                    optionDsp.find(
                      option => option.id === queryParams?.dsp_id
                    ) || null
                  }
                  onChange={value => handleChangeQueryParams(value, "dsp_id")}
                  size="small"
                  width={250}
                />
              </Grid>
              {typeWeb === "caris" && (
                <Grid item>
                  <SelectInput
                    label="Outside DSP"
                    value={""}
                    options={[{ id: "", label: "None" }]}
                    optionKey="id"
                    optionLabel="label"
                    width={250}
                  />
                </Grid>
              )}
              <Grid item>
                <SelectInput
                  label="Month"
                  value={queryParams?.month || ""}
                  onChange={e =>
                    handleChangeQueryParams(e?.target?.value, "month")
                  }
                  options={month}
                  optionKey="id"
                  optionLabel="name"
                  width={250}
                />
              </Grid>
              <Grid item>
                <YearPicker
                  label="Year"
                  value={queryParams?.year}
                  onChange={date => handleChangeQueryParams(date, "year")}
                  width={250}
                />
              </Grid>
            </Grid>
          </Box>
          <SectionLabel title="Trend of Song Usage" my="16px" />
          <Box
            border="1px solid #ebebeb"
            borderRadius="5px"
            padding="50px 20px"
            my={3}
          >
            <Box mb={2}>
              {chartData?.datasets.length > 0 && queryParams?.dsp_id && (
                <ArrayTagChip selectionsList={[selectedDSP]} value="name" />
              )}
            </Box>
            <SongUsageTrendChart
              chartData={chartData}
              onAxisClick={(_, data) => onClickChartAxis(data)}
            />
          </Box>
          <SongUsageTrendSummarytCard chartReport={chartReport} />
          <Grid container justifyContent="space-between" my="16px">
            <Grid item>
              <SearchTextInput
                placeholder="Song title, Composer"
                onChange={e =>
                  handleChangeTableParams(e?.target?.value, "search")
                }
                value={tableParams?.search}
              />
            </Grid>
            <Grid item>
              <Grid container columnSpacing={1}>
                <Grid item>
                  <PrimaryButton
                    label="Download PDF"
                    onClick={() =>
                      history.push({
                        pathname: "/admin/review-lagu/print-report",
                        state: {
                          dataTable,
                          chartData,
                          chartReport,
                          selectedDSP,
                        },
                      })
                    }
                    size="large"
                  />
                </Grid>
                <Grid item>
                  <CSVLink data={csvArray} filename="song_usage">
                    <PrimaryButton
                      label="Download CSV"
                      size="large"
                      onClick={getDataCSV}
                    />
                  </CSVLink>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <InnoTableV2
            isLoading={false}
            columns={columnTable}
            items={dataTable}
            page={tableParams?.page}
            rowsPerPage={tableParams?.size}
            totalPage={tableTotalPage}
            handleChangePage={(_, page) =>
              handleChangeTableParams(page, "page")
            }
            handleChangeRowsPerPage={e =>
              handleChangeTableParams(e?.target?.value, "size")
            }
            isHaveAction
            renderAction={item => (
              <PrimaryButton
                label="See Details"
                onClick={() =>
                  history.push({
                    pathname: `/pencipta/review-lagu/${item.song_id}`,
                    state: {
                      song: item,
                      queryParams,
                    },
                  })
                }
                width={140}
              />
            )}
          />
        </Container>
      )}
    </Page>
  );
};

const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
const breadcrumbData = [
  {
    label: "Home",
    link: "/pencipta/dashboard-pencipta",
  },
  {
    label: "Song Usage",
    active: true,
  },
];
const columnTable = [
  {
    name: "title",
    title: "Song title",
    renderText: item => item || "-",
  },
  {
    name: "iswc_code",
    title: "ISWC",
    renderText: item => item || "-",
  },
  {
    name: "composer_names",
    title: "Composer",
    renderText: item => <ArrayChip list={item} />,
  },
  {
    name: "listener",
    title: <Box textAlign="right">Traffic</Box>,
    renderText: item => <CurrencyDisplay value={item} />,
  },
  {
    name: "base_currency_revenue",
    title: <Box textAlign="right">Revenue</Box>,
    renderText: item => (
      <CurrencyDisplay value={item} prefix="Rp " decimalScale={2} />
    ),
  },
];

export default PenciptaReviewLagu;
