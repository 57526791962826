import { useState, useEffect } from "react";
import { useParams } from "react-router";
import axios from "axios";
import { hardBaseUrl } from "services/urlConstant";
import { Page, ModalError } from "components";
import { HeaderTitle } from "layouts";
import { Box, Container, Grid } from "@mui/material";
import { getErrors } from "utils";

function DetailPreview() {
    const { id } = useParams();
    const [preview, setPreview] = useState({});
    const token = localStorage.getItem("token");
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const getDetailPreview = async (id) => {
        try {
            const res = await axios.get(
                `${hardBaseUrl}/admin/annoucement/detail/${id}`,
                { headers }
            );
            setPreview(res?.data?.data || {});
        } catch (error) {
            new Error(error);
            ModalError(getErrors(error?.response));
        }
    };

    useEffect(() => {
        getDetailPreview(id);
    }, [id]);

    return (
        <Page>
            <Container maxWidth={false}>
                <HeaderTitle
                    title="Back to Announcement Detail"
                    breadcrumbData={[]}
                    backButton
                    titleStyle={{
                        fontSize: 18,
                        fontWeight: 400
                    }}
                />
                <Grid container>
                    <Grid item xs={7}>
                        <Box
                            dangerouslySetInnerHTML={{
                                __html: preview?.content,
                            }}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}

export default DetailPreview;