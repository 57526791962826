import { Divider } from "@material-ui/core";
import { Box, Container, Grid } from "@mui/material";
import axios from "axios";
import {
  FormLabel,
  ModalError,
  ModalSuccess,
  Page,
  PrimaryButton,
  SectionLabel,
  SkeletonComponent,
  TextInput,
} from "components";
import { HeaderTitle } from "layouts";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { globalStyles } from "styles";
import { getErrors } from "utils";
import { hardBaseUrl } from "../../../../services/urlConstant";

const FormCurrency = () => {
  const classes = globalStyles();
  const history = useHistory();
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const pageTitle = `${id ? "Edit" : "Add"} Currency`;
  const [loadingPage, setLoadingPage] = useState(false);
  const [payload, setPayload] = useState({
    currency_iso_code: "",
    currency_name: "",
    currency_symbol_code: "",
    description: "",
  });
  const handleChangePayload = (value, key) => {
    setPayload(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };

  const getDetail = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/currency/detail/${id}`, {
        headers,
      });
      setPayload(res?.data?.data);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const handlSubmit = async () => {
    const method = id ? axios.put : axios.post;

    try {
      setLoadingPage(true);
      await method(
        `${hardBaseUrl}/currency/${id ? `update/${id}` : "create"}`,
        payload,
        { headers }
      );
      ModalSuccess(`Currency has been ${id ? "updated" : "created"}`).then(() =>
        history.goBack()
      );
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    if (id) getDetail();
  }, [id]);

  return (
    <Page className={classes?.root} title={pageTitle}>
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title={pageTitle}
            breadcrumbData={breadcrumbData({ pageTitle })}
            backButton
          />
          <Divider className={classes?.divider} />
          <SectionLabel
            title="Currency"
            subTitle="Customize and manage the currency to make transaction"
          />
          <Box maxWidth="60%">
            <Box
              border="1px solid #ebebeb"
              borderRadius="5px"
              p="24px"
              mt="16px"
            >
              <Grid container direction="column" rowSpacing={1}>
                <Grid item>
                  <FormLabel label="Currency Code" required />
                  <TextInput
                    value={payload?.currency_iso_code}
                    onChange={e =>
                      handleChangePayload(e?.target?.value, "currency_iso_code")
                    }
                    placeholder="Currency Code"
                  />
                </Grid>
                <Grid item>
                  <FormLabel label="Currency Name" required />
                  <TextInput
                    value={payload?.currency_name}
                    onChange={e =>
                      handleChangePayload(e?.target?.value, "currency_name")
                    }
                    placeholder="Currency Name"
                  />
                </Grid>
                <Grid item>
                  <FormLabel label="Currency Symbol Code" required />
                  <TextInput
                    value={payload?.currency_symbol_code}
                    onChange={e =>
                      handleChangePayload(
                        e?.target?.value,
                        "currency_symbol_code"
                      )
                    }
                    placeholder="Currency Symbol Code"
                  />
                </Grid>
                <Grid item>
                  <FormLabel label="Description" />
                  <TextInput
                    value={payload?.description}
                    onChange={e =>
                      handleChangePayload(e?.target?.value, "description")
                    }
                    placeholder="Description"
                    multiline
                    rows={4}
                  />
                </Grid>
              </Grid>
            </Box>
            <Grid container justifyContent="right" mt="16px">
              <PrimaryButton
                label={id ? "Save" : "Add"}
                onClick={handlSubmit}
              />
            </Grid>
          </Box>
        </Container>
      )}
    </Page>
  );
};

const breadcrumbData = ({ pageTitle }) => [
  {
    label: "Parameter",
    link: "/admin/parameter/admin-currency",
  },
  {
    label: "Currency",
    link: "/admin/parameter/admin-currency",
  },
  {
    label: pageTitle,
    active: true,
  },
];

export default FormCurrency;
