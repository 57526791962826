import { Container, Divider } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import { Box, Grid, Typography } from "@mui/material";
import axios from "axios";
import {
  ArrayChip,
  ArrayTagChip,
  AutoCompleteComponent,
  CurrencyDisplay,
  ModalError,
  Page,
  PrimaryButton,
  PrimaryTextButton,
  SectionLabel,
  SelectInput,
  SkeletonComponent,
  SongUsageTrendChart,
  TableAction,
  YearPicker,
} from "components";
import { month } from "constants";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { isEqual } from "lodash";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { globalStyles } from "styles";
import { formatDate, getErrors } from "utils";
import { hardBaseUrl } from "../../../services/urlConstant";

const DashboardPencipta = () => {
  const classes = globalStyles();
  const history = useHistory();
  const isFirstLoad = useRef(true);
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [userName, setUserName] = useState(getFromLocalStorage("userName", ""));
  const [loadingPage, setLoadingPage] = useState(true);
  const [optionDsp, setOptionDsp] = useState([]);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [queryParams, setQueryParams] = useState({
    year: getFromLocalStorage("year", moment().year()),
    dsp_id: getFromLocalStorage("dsp_id", 0),
    month: getFromLocalStorage("month", 0),
  });
  const [dashboardSummary, setDashboardSummary] = useState({
    balance_number: 0,
    paid_amount: 0,
  });
  const [dashboardInformation, setDashboardInformation] = useState({
    songs: 0,
    traffic: 0,
    revenue: 0,
  });
  const [dataTableDashboard, setDataTableDashboard] = useState([]);
  const [tableParamsDashboard, setTableParamsDashboard] = useState({
    page: getFromLocalStorage("dashboard_page", 1),
    size: getFromLocalStorage("dashboard_size", 10),
  });
  const [tableTotalPageDashboard, setTableTotalPageDashboard] = useState(1);
  const [dataTableInvoice, setDataTableInvoice] = useState([]);
  const [tableParamsInvoice, setTableParamsInvoice] = useState({
    page: getFromLocalStorage("invoice_page", 1),
    per_page: getFromLocalStorage("invoice_per_page", 10),
  });
  const [tableTotalPageInvoice, setTableTotalPageInvoice] = useState(1);
  const prevQueryParams = usePrevious(queryParams);
  let selectedDSP = optionDsp?.find(
    dsp => dsp?.dsp_id === Number(queryParams?.dsp_id)
  );

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      [key]: value,
    }));
    localStorage.setItem(key, value);
  };
  const handleChangeTableParamsDashboard = (value, key) => {
    setTableParamsDashboard(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
    localStorage.setItem(`dashboard_${key}`, value);
    if (key !== "page") {
      localStorage.setItem("dashboard_page", 1);
    }
  };
  const handleChangeTableParamsInvoice = (value, key) => {
    setTableParamsInvoice(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
    localStorage.setItem(`invoice_${key}`, value);
    if (key !== "page") {
      localStorage.setItem("invoice_page", 1);
    }
  };
  const getUserDetail = async () => {
    try {
      const res = await axios.get(`${hardBaseUrl}/me`, {
        headers,
      });
      const { data } = res?.data;
      setUserName(data?.name);
      localStorage.setItem("userName", data?.name);
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };
  const getDefaultDSP = async () => {
    try {
      const res = await axios.get(
        `${hardBaseUrl}/dashboard/options/valuated_dsp`,
        {
          headers,
          params: {
            page: 1,
            size: 3,
            year: queryParams?.year,
          },
        }
      );
      const defaultDSP = res?.data?.data?.[0]?.dsp_id;
      handleChangeQueryParams(defaultDSP, "dsp_id");
      await fetchData(defaultDSP);
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };
  const getOptionDsp = async () => {
    try {
      const res = await axios.get(
        `${hardBaseUrl}/publisher/dashboard/option?dsp_flag=ACTIVE`,
        { headers }
      );
      const modifiedData = res?.data?.data?.dsp?.map(item => ({
        ...item,
        id: item?.dsp_id,
        label: item?.name,
      }));
      setOptionDsp(modifiedData);
      if (!queryParams?.dsp_id) {
        getDefaultDSP();
      } else {
        fetchData();
      }
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };
  const getDashboardSummary = async () => {
    try {
      const res = await axios.get(
        `${hardBaseUrl}/composer/transaction/payment/get-balance`,
        { headers }
      );
      setDashboardSummary(res?.data?.data);
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };
  const getDataTableDashboard = async defaultDSP => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/composer/dashboard/table`, {
        headers,
        params: {
          ...tableParamsDashboard,
          ...queryParams,
          ...(defaultDSP && {
            dsp_id: defaultDSP,
          }),
        },
      });
      const { data, meta } = res?.data;
      setDataTableDashboard(data || []);
      const pageCount = getTotalPage(meta?.total, tableParamsDashboard?.size);
      setTableTotalPageDashboard(pageCount || 1);
      setDashboardInformation(prev => ({
        ...prev,
        songs: meta?.total,
      }));
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const getDataTableInvoice = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(
        `${hardBaseUrl}/composer/transaction/payment`,
        {
          headers,
          params: tableParamsInvoice,
        }
      );
      const { payment_data, query } = res?.data?.data;
      setDataTableInvoice(payment_data || []);
      setTableTotalPageInvoice(query?.total_page || 1);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const getChartData = async defaultDSP => {
    try {
      const res = await axios.get(`${hardBaseUrl}/composer/dashboard/chart`, {
        headers,
        params: {
          ...queryParams,
          ...(defaultDSP && {
            dsp_id: defaultDSP,
          }),
        },
      });
      const { data } = res?.data || [];

      if (data?.length > 0) {
        const labels = data[0].chart.map(item =>
          moment(item.date).format("MMM YY")
        );
        const datasets = data.map(item => ({
          label: item?.dsp?.name,
          data: item?.chart.map(chartItem => chartItem?.base_currency_revenue),
          listener: item?.chart.map(chartItem => chartItem?.listener),
          color: item?.dsp?.color,
          showMark: false,
        }));
        setChartData({
          labels,
          datasets,
        });
        const totals = data.reduce(
          (acc, item) => {
            acc.revenue += item.revenue;
            acc.traffic += item.traffic;
            return acc;
          },
          { revenue: 0, traffic: 0 }
        );
        setDashboardInformation(prev => ({
          ...prev,
          traffic: totals?.traffic,
          revenue: totals.revenue,
        }));
      } else {
        setChartData({
          labels: [],
          datasets: [],
        });
        setDashboardInformation(prev => ({
          ...prev,
          traffic: 0,
          revenue: 0,
        }));
      }
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };
  const fetchData = async defaultDSP => {
    setLoadingPage(true);
    const promises = [];
    if (!userName) promises.push(getUserDetail());
    promises.push(getDashboardSummary());
    promises.push(getDataTableInvoice());
    promises.push(getChartData(defaultDSP));
    promises.push(getDataTableDashboard(defaultDSP));

    try {
      await Promise.all(promises);
    } catch (error) {
      ModalError(error, "Error fetching data");
    } finally {
      setLoadingPage(false);
      isFirstLoad.current = false;
    }
  };

  useEffect(() => {
    getOptionDsp();
  }, [queryParams?.dsp_id]);
  useEffect(() => {
    if (!isFirstLoad.current) {
      if (prevQueryParams && !isEqual(prevQueryParams, queryParams)) {
        if (tableParamsDashboard?.page !== 1) {
          handleChangeTableParamsDashboard(1, "page");
        }
        getChartData();
      }
      getDataTableDashboard();
    }
  }, [queryParams, tableParamsDashboard]);
  useEffect(() => {
    if (!isFirstLoad.current) {
      getDataTableInvoice();
    }
  }, [tableParamsInvoice]);

  return (
    <Page
      className={classes.root}
      title="Dashboard"
      data-testid="data-test-page-dashboard"
    >
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title={`Dashboard ${userName ? ` | ${userName}` : ""}`}
            breadcrumbData={breadcrumbData}
          />
          <Divider className={classes.divider} />
          <Grid container>
            <InformationCard
              title="Total Balance"
              value={dashboardSummary?.balance_number}
              prefix="Rp"
              length={2}
            />
            <InformationCard
              title="Paid Amount"
              value={dashboardSummary?.paid_amount}
              prefix="Rp"
              onClick={() => history.push("/pencipta/payment")}
              length={2}
            />
          </Grid>
          <Grid container justifyContent="right" spacing={1} my="16px">
            <Grid item>
              <AutoCompleteComponent
                label="DSP"
                options={optionDsp}
                value={
                  optionDsp.find(option => option.id === queryParams?.dsp_id) ||
                  null
                }
                onChange={value => {
                  handleChangeQueryParams(value, "dsp_id");
                }}
                size="small"
                width={210}
              />
            </Grid>
            <Grid item>
              <SelectInput
                label="Month"
                value={queryParams?.month || ""}
                onChange={e =>
                  handleChangeQueryParams(e?.target?.value, "month")
                }
                options={month}
                optionKey="id"
                optionLabel="name"
                width={150}
              />
            </Grid>
            <Grid item>
              <YearPicker
                label="Year"
                value={queryParams?.year}
                onChange={date => handleChangeQueryParams(date, "year")}
                width={150}
              />
            </Grid>
          </Grid>
          <Box
            border="1px solid #ebebeb"
            borderRadius="5px"
            padding="50px 20px"
            my="24px"
          >
            {chartData?.datasets.length > 0 && queryParams?.dsp_id && (
              <ArrayTagChip selectionsList={[selectedDSP]} value="name" />
            )}
            <Box width="100%" mt={2}>
              <SongUsageTrendChart chartData={chartData} />
            </Box>
          </Box>
          <Grid container my="16px">
            <InformationCard
              title="Total Songs"
              value={dashboardInformation?.songs}
              length={3}
            />
            <InformationCard
              title="Total Listener Traffic"
              value={dashboardInformation?.traffic}
              length={3}
            />
            <InformationCard
              title="Total Revenue"
              value={dashboardInformation?.revenue}
              prefix="Rp"
              length={3}
            />
          </Grid>
          <InnoTableV2
            isLoading={false}
            columns={columnTableDashboard}
            items={dataTableDashboard}
            page={tableParamsDashboard?.page}
            rowsPerPage={tableParamsDashboard?.size}
            totalPage={tableTotalPageDashboard}
            handleChangePage={(_, page) =>
              handleChangeTableParamsDashboard(page, "page")
            }
            handleChangeRowsPerPage={e =>
              handleChangeTableParamsDashboard(e?.target?.value, "size")
            }
            isHaveAction
            renderAction={item => (
              <PrimaryButton
                label="See Details"
                onClick={() =>
                  history.push({
                    pathname: `/pencipta/dashboard-pencipta/detail-lagu/${item?.song_id}`,
                    state: {
                      queryParams,
                    },
                  })
                }
                width={140}
              />
            )}
          />
          <SectionLabel title="Payment Claim" my="16px" />
          <InnoTableV2
            isLoading={false}
            columns={columnTableInvoice}
            items={dataTableInvoice}
            page={tableParamsInvoice?.page}
            rowsPerPage={tableParamsInvoice?.per_page}
            totalPage={tableTotalPageInvoice}
            handleChangePage={(_, page) =>
              handleChangeTableParamsInvoice(page, "page")
            }
            handleChangeRowsPerPage={e =>
              handleChangeTableParamsInvoice(e?.target?.value, "per_page")
            }
            isHaveAction
            renderAction={item => (
              <TableAction
                handleView={() =>
                  history.push(
                    `/pencipta/payment/${item?.publisher_transaction_id}`
                  )
                }
              />
            )}
          />
        </Container>
      )}
    </Page>
  );
};

const getFromLocalStorage = (key, defaultValue) => {
  const value = localStorage.getItem(key);
  return value ? Number(value) : defaultValue;
};
const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
const InformationCard = ({ title, value, prefix, length, onClick }) => (
  <Grid item xs={12 / length} borderLeft="2px solid #9545EB" px="16px">
    <Typography color="#687083" fontSize={14} textAlign="right">
      {title}
    </Typography>
    <Typography fontSize="24px">
      <CurrencyDisplay
        value={value}
        prefix={prefix}
        decimalScale={prefix && 2}
      />
    </Typography>
    {onClick && (
      <PrimaryTextButton
        label="View Summary"
        onClick={onClick}
        endIcon={<ChevronRight />}
      />
    )}
  </Grid>
);
const breadcrumbData = [
  {
    label: "Home",
    link: "/pencipta/dashboard-pencipta",
  },
  {
    label: "Dashboard",
    active: true,
  },
];
const columnTableDashboard = [
  {
    name: "title",
    title: "Song title",
    renderText: item => item || "-",
  },
  {
    name: "composer_names",
    title: "Composer/Author",
    renderText: item => <ArrayChip list={item} />,
  },
  {
    name: "listener",
    title: <Box textAlign="right">Listener Traffic</Box>,
    renderText: item => <CurrencyDisplay value={item} />,
  },
  {
    name: "base_currency_revenue",
    title: <Box textAlign="right">Revenue</Box>,
    renderText: item => (
      <CurrencyDisplay value={item} prefix="Rp" decimalScale={2} />
    ),
  },
];
const columnTableInvoice = [
  {
    name: "date",
    title: "Date",
    renderText: item => formatDate(item),
  },
  {
    name: "note",
    title: "Description",
    renderText: item => item || "-",
  },
  {
    name: "value",
    title: <Box textAlign="right">Value</Box>,
    renderText: item => (
      <CurrencyDisplay value={item} prefix="Rp" decimalScale={2} />
    ),
  },
  {
    name: "status",
    title: "Status",
    renderText: item =>
      item === "waiting_for_approval"
        ? "Waiting for Approval"
        : item === "approved"
        ? "Approved"
        : item === "rejected"
        ? "Rejected"
        : item === "paid"
        ? "Paid"
        : item,
  },
];

export default DashboardPencipta;
