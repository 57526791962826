import React, { useEffect, useState } from "react";

import {
  Box,
  Checkbox,
  Container,
  Divider,
  Grid,
  TextField,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { Page, PrimaryButton, SectionLabel } from "components";
import { HeaderTitle } from "layouts";
import { uniqWith } from "lodash";
import { useCallback } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import Swal from "sweetalert2";
import { hardBaseUrl } from "../../../../services/urlConstant";
const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  button: {
    padding: "10px 28px 10px 28px",
    backgroundColor: "black",
    color: "white",
    width: "213px",
    height: "40px",
    borderRadius: "6px",
    textTransform: "none",
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: "28px",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  formContainer: {
    border: "1px solid #D1D5DC",
    padding: "16px",
    gap: "16px",
    borderRadius: "8px",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  selectPadding: {
    height: "80px",
    "& .MuiInputBase-fullWidth": {
      height: "55px",
    },
  },
  title: {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "32px",
  },
  buttonCancel: {
    borderRadius: "6px",
    width: "129px",
    height: "40px",
    border: "1px solid #D1D5DC",
    textTransform: "none",
  },
  textDesc: {
    margin: ".5rem 0px",
    color: "#8f8f8f",
    fontSize: "14px",
  },
  rootSwitch: {
    width: "40px",
    height: "20px",
    padding: "0px",
    borderRadius: "10px",

    marginRight: "8px",
    position: "relative",
  },
  switchBase: {
    padding: "10px",
    color: "white",
    "&.Mui-checked": {},
    "&.Mui-checked + $track": {
      border: "none",
      color: "white",
      backgroundColor: "black",
      opacity: 1,
    },
  },
  buttonSave: {
    textTransform: "none",
    backgroundColor: "black",
    color: "white",
    width: "129px",
    height: "40px",
    marginLeft: "10px",
  },
  textSave: {
    color: "white",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
  },
  checked: {},
  thumb: {
    color: "white",
    position: "absolute",
    width: "16px",
    height: "16px",
  },
  track: {
    backgroundColor: "#E4E7EB",
    borderRadius: "25px",
    border: "0px solid #606060",
    height: "auto",
    opacity: 1,
  },
}));
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CreateOrEditDspRevenueType = () => {
  const { state } = useLocation();
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const [paramsListDropdown, setParamsListDropdown] = useState({
    dsp: {
      page: 1,
      size: 10,
      search: "",
    },
    revenueType: {
      page: 1,
      size: 10,
      search: "",
    },
  });
  const [listDropdown, setListDropdown] = useState({
    dsp: [],
    revenueType: [],
  });
  const [metaList, setMetaList] = useState({
    dsp: {},
    revenueType: {},
  });
  const [valueDropdown, setValueDropdown] = useState({
    dsp: {},
    revenueType: [],
  });
  const [position, setPosition] = useState(0);
  const [listboxNode, setListboxNode] = useState("");
  const pageTitle = `${id ? "Edit" : "Add"} DSP Revenue Type`;
  
  useEffect(() => {
    if (id) {
      const temp = state.dsp.dsp_revenue_types.map(el => ({
        ...el.revenue_type,
      }));

      setValueDropdown(prev => ({
        ...prev,
        revenueType: temp,
        dsp: state.dsp,
      }));
    }
  }, []);
  const saveData = e => {
    e.preventDefault();
    if (!valueDropdown.dsp || JSON.stringify(valueDropdown.dsp) === "{}") {
      Swal.fire({
        icon: "error",
        title: "Oops…",
        text: "DSP name can't be empty",
      });
      return;
    }
    if (!valueDropdown.revenueType.length) {
      Swal.fire({
        icon: "error",
        title: "Oops…",
        text: "Revenue type can't be empty",
      });
      return;
    }

    if (
      valueDropdown.dsp &&
      JSON.stringify(valueDropdown.dsp) !== "{}" &&
      valueDropdown?.revenueType?.length
    ) {
      const data = {
        dsp_id: valueDropdown?.dsp?.dsp_id,
        revenue_type_ids: valueDropdown.revenueType?.map(
          el => el.revenue_type_id
        ),
      };
      const url = `${hardBaseUrl}/dsp-revenue-type/${id ? "/update" : "create"
        }`;
      const token = localStorage.getItem("token");

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      axios({ url, method: id ? "PUT" : "POST", data, ...config })
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: `DSP revenue type ${id ? "updated" : "created"} successfully`,
          }).then(result => {
            if (result.isConfirmed) {
              history.push("/admin/konfigurasi/dsp-type-revenue");
            }
          });
        })
        .catch(err => {
          Swal.fire({
            icon: "error",
            title: "Oops…",
            text: err?.response?.data?.errors?.[0]?.message,
          });
        });
    }
  };
  const getDspList = useCallback(async () => {
    try {
      const response = await axios.get(hardBaseUrl + "/dsp", {
        params: {
          search: paramsListDropdown.dsp.search,
          page: paramsListDropdown.dsp.page,
          size: paramsListDropdown.dsp.size,
        },
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      });
      if (response.data.meta.http_status === 200) {
        setListDropdown(prev => ({
          ...prev,
          dsp: uniqWith(
            [...prev.dsp, ...response.data.data],
            (arr, otherArr) => {
              return arr.dsp_id === otherArr.dsp_id;
            }
          ),
        }));
        setMetaList(prev => ({ ...prev, dsp: response.data.meta }));
      }
    } catch (error) {
      console.info(error);
    }
  }, [paramsListDropdown.dsp]);
  const getRevenueType = useCallback(async () => {
    try {
      const response = await axios.get(hardBaseUrl + "/revenue-type/table", {
        params: {
          search: paramsListDropdown.revenueType.search,
          sort: "name",
          page: paramsListDropdown.revenueType.page,
          per_page: paramsListDropdown.revenueType.size,
        },
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      });
      if (response.data.meta.http_status === 200) {
        setListDropdown(prev => ({
          ...prev,
          revenueType: uniqWith(
            [...prev.revenueType, ...response.data.data],
            (arr, otherArr) => {
              return arr.revenue_type_id === otherArr.revenue_type_id;
            }
          ),
        }));
        setMetaList(prev => ({ ...prev, revenueType: response.data.meta }));
      }
    } catch (error) {
      console.info(error);
    }
  }, [paramsListDropdown.revenueType]);

  useEffect(() => {
    if (listboxNode !== "" && position) {
      listboxNode.scrollTop = position;
    }
  }, [position, listboxNode]);

  const fetchMoreData = type => {
    if (type === "dsp") {
      setParamsListDropdown({
        ...paramsListDropdown,
        dsp: {
          page: paramsListDropdown.dsp.page + 1,
          size: paramsListDropdown.dsp.size,
        },
      });
    } else {
      setParamsListDropdown({
        ...paramsListDropdown,
        revenueType: {
          page: paramsListDropdown.revenueType.page + 1,
          size: paramsListDropdown.revenueType.size,
        },
      });
    }
  };

  const handleScrollListBox = (event, type) => {
    setListboxNode(event.currentTarget);

    const x = listboxNode.scrollTop + listboxNode.clientHeight;

    if (listboxNode.scrollHeight - x <= 1) {
      setPosition(x);

      if (type === "dsp") {
        const modulusPagePerformer = metaList.dsp?.total % metaList?.dsp?.limit;
        const remainingCountPermorfer =
          modulusPagePerformer >= 1
            ? Math.floor(metaList?.dsp?.total / metaList?.dsp?.limit) + 1
            : metaList?.dsp?.total / metaList?.dsp?.limit;

        if (paramsListDropdown.dsp.page < remainingCountPermorfer) {
          setPosition(x);

          fetchMoreData("dsp");
        }
      }
      if (type === "revenueType") {
        const modulusPagePencipta =
          metaList.revenueType?.total % metaList?.revenueType?.limit;
        const remainingCountPencipta =
          modulusPagePencipta >= 1
            ? Math.floor(
              metaList?.revenueType?.total / metaList?.revenueType?.limit
            ) + 1
            : metaList?.revenueType?.total / metaList?.revenueType?.limit;
        if (paramsListDropdown.revenueType.page < remainingCountPencipta) {
          setPosition(x);

          fetchMoreData("revenueType");
        }
      }
    }
  };
  const onChangeDropdown = (e, data, type) => {
    if (type === "revenueType") {
      setValueDropdown(prev => ({
        ...prev,
        revenueType: [...data],
      }));
    } else {
      setValueDropdown(prev => ({ ...prev, dsp: data }));
    }
  };
  useEffect(() => {
    getDspList();
  }, [getDspList]);

  useEffect(() => {
    getRevenueType();
  }, [getRevenueType]);
  return (
    <Page className={classes.root} title={pageTitle}>
      <Container maxWidth={false}>
        <HeaderTitle
          title={pageTitle}
          breadcrumbData={breadcrumbData({ pageTitle })}
          backButton
        />
        <Divider className={classes.divider} />
        <Grid container>
          <Grid item xs={12} md={8} sm={8} lg={6} xl={6}>
            <SectionLabel
              title="DSP Type Revenue"
              subTitle="Customize and manage DSP Revenue Type for transaction."
              mb="16px"
            />
            <Grid
              container
              spacing={1}
              className={classes.formContainer}
            >
              <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                <Box>
                  <ThemeProvider theme={theme}>
                    <label>DSP Name</label>
                  </ThemeProvider>
                  <Autocomplete
                    options={listDropdown.dsp}
                    disableCloseOnSelect
                    getOptionLabel={option => option.name}
                    onChange={(e, data, reason) =>
                      onChangeDropdown(e, data, "dsp", reason)
                    }
                    value={valueDropdown.dsp}
                    defaultValue={id ? state.dsp : null}
                    getOptionSelected={(option, value) =>
                      option.dsp_id === value.dsp_id
                    }
                    fullWidth={true}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Select DSP Name"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                    autoHighlight
                    inputValue={undefined}
                    onInputChange={(e, value, reason) => {
                      if (reason !== "reset") {
                        e.persist();
                        setParamsListDropdown(prev => ({
                          ...prev,
                          dsp: {
                            ...prev.dsp,
                            search: e?.target?.value,
                          },
                        }));
                      }
                    }}
                    ListboxProps={{
                      onScroll: e => handleScrollListBox(e, "dsp"),
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                <Box>
                  <ThemeProvider theme={theme}>
                    <label>Revenue Type</label>
                  </ThemeProvider>

                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={listDropdown.revenueType}
                    disableCloseOnSelect
                    getOptionLabel={option => option.name}
                    onChange={(e, data) =>
                      onChangeDropdown(e, data, "revenueType")
                    }
                    value={valueDropdown.revenueType}
                    getOptionSelected={(option, value) =>
                      option.name === value.name
                    }
                    renderOption={(option, { selected }) => {
                      return (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={selected}
                            value={option.revenue_type_id}
                          />
                          {option.name}
                        </React.Fragment>
                      );
                    }}
                    fullWidth={true}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Select Revenue Type"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                    autoHighlight
                    inputValue={paramsListDropdown.revenueType.search || ""}
                    onInputChange={(e, value, reason) => {
                      if (reason !== "reset") {
                        setParamsListDropdown({
                          ...paramsListDropdown,
                          revenueType: {
                            ...paramsListDropdown.revenueType,
                            search: e.target.value,
                          },
                        });
                      }
                    }}
                    ListboxProps={{
                      onScroll: e => handleScrollListBox(e, "revenueType"),
                    }}
                  />
                </Box>
              </Grid>
            </Grid>

            <Box marginTop="20px" display="flex" justifyContent="flex-end">
              <PrimaryButton label="Save" onClick={e => saveData(e)} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

const breadcrumbData = ({ pageTitle }) => [
  {
    label: "Configuration",
    link: "/admin/konfigurasi/dsp-type-revenue",
  },
  {
    label: "DSP Type Revenue",
    link: "/admin/konfigurasi/dsp-type-revenue",
  },
  {
    label: pageTitle,
    active: true,
  },
];
export default CreateOrEditDspRevenueType;
