import { Divider } from "@material-ui/core";
import { Autocomplete, Box, Container, Grid, TextField } from "@mui/material";
import axios from "axios";
import {
  CurrencyNumberInput,
  FormLabel,
  ModalError,
  ModalSuccess,
  Page,
  PrimaryButton,
  SectionLabel,
  SkeletonComponent,
  TextInput,
} from "components";
import { HeaderTitle } from "layouts";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { hardBaseUrl } from "services/urlConstant";
import { globalStyles } from "styles";
import { getErrors } from "utils";

const FormParameterAgent = () => {
  const classes = globalStyles();
  const history = useHistory();
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const pageTitle = `${id ? "Edit" : "Add"} Agent`;

  const [loadingPage, setLoadingPage] = useState(false);
  const [optionComposer, setOptionComposer] = useState([]);
  const [payload, setPayload] = useState({
    name: "",
    email: "",
    contact_number: "",
    npwp: "",
    agent_fee: 0,
    composer_ids: [],
  });

  const handleChangePayload = (value, key) => {
    setPayload(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };

  const getOptionComposer = async () => {
    try {
      const res = await axios.get(`${hardBaseUrl}/publisher/composer`, {
        headers,
      });
      const modifiedData = res?.data?.data?.map(item => ({
        ...item,
        id: item?.composer_id,
        label: item?.sure_name,
      }));
      setOptionComposer(modifiedData);
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    } finally {
      setLoadingPage(false);
    }
  };
  const getDetail = async () => {
    try {
      const res = await axios.get(`${hardBaseUrl}/publisher/agent/${id}`, {
        headers,
      });
      const data = res?.data?.data;
      setPayload({
        name: data.name,
        email: data.email,
        contact_number: data.contact_number,
        npwp: data.npwp,
        agent_fee: data.percentage_agent_fee || 0,
        composer_ids: data.composers || [],
      });
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    }
  };

  const handleSubmit = async () => {
    const method = id ? axios.put : axios.post;
    const composers = [];
    payload.composer_ids.map(value => {
      composers.push(value.composer_id);
    });
    const newPayload = {
      ...payload,
      composer_ids: composers || [],
    };
    try {
      setLoadingPage(true);
      await method(
        `${hardBaseUrl}/publisher/agent${id ? `/${id}` : ""}`,
        newPayload,
        { headers }
      );
      ModalSuccess(`Successfully ${id ? "update" : "save"} agent`).then(() =>
        history.goBack()
      );
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };

  const fetchData = async () => {
    setLoadingPage(true);
    const promises = [getOptionComposer()];
    if (id) promises.push(getDetail());

    try {
      await Promise.all(promises);
    } catch (error) {
      ModalError(error, "Error fetching data");
    } finally {
      setLoadingPage(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Page className={classes.root} title={pageTitle}>
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title={pageTitle}
            breadcrumbData={breadcrumbData({ pageTitle })}
            backButton
          />
          <Divider className={classes?.divider} />
          <SectionLabel
            title="Agent Information"
            subTitle={`To ${id ? "edit" : "add"} agent's data.`}
          />
          <Box width="70%">
            <Box
              border="1px solid #ebebeb"
              borderRadius="5px"
              p="24px"
              my="16px"
            >
              <Grid container columnSpacing={2} rowSpacing={1}>
                <Grid item xs={12} md={6}>
                  <FormLabel label="Agent Name" required />
                  <TextInput
                    placeholder="Agent Name"
                    value={payload?.name}
                    onChange={e =>
                      handleChangePayload(e?.target?.value, "name")
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormLabel label="Telephone Number" required />
                  <TextInput
                    placeholder="Telephone Number"
                    value={payload?.contact_number}
                    onChange={e =>
                      handleChangePayload(e?.target?.value, "contact_number")
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormLabel label="Email" required />
                  <TextInput
                    placeholder="Email"
                    value={payload?.email}
                    onChange={e =>
                      handleChangePayload(e?.target?.value, "email")
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormLabel label="NPWP" />
                  <TextInput
                    placeholder="NPWP"
                    value={payload?.npwp}
                    onChange={e =>
                      handleChangePayload(e?.target?.value, "npwp")
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormLabel label="Agent Fee" required />
                  <CurrencyNumberInput
                    value={payload?.agent_fee}
                    onChange={value => handleChangePayload(value, "agent_fee")}
                    endAdornment="%"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormLabel label="Composer" required />
                  <Autocomplete
                    size="small"
                    multiple
                    options={optionComposer}
                    getOptionLabel={option => option.sure_name}
                    value={payload?.composer_ids}
                    onChange={(_, newValue) => {
                      handleChangePayload(newValue, "composer_ids");
                    }}
                    disablePortal
                    renderInput={params => (
                      <TextField size="small" {...params} />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
            <Grid container justifyContent="right">
              <PrimaryButton label="Save" onClick={handleSubmit} />
            </Grid>
          </Box>
        </Container>
      )}
    </Page>
  );
};

const breadcrumbData = ({ pageTitle }) => [
  {
    label: "Parameter",
    link: "/admin/parameter/agent",
  },
  {
    label: "Agent",
    link: "/admin/parameter/agent",
  },
  {
    label: pageTitle,
    active: true,
  },
];
export default FormParameterAgent;
