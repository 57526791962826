import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  Container,
  Divider,
  Grid,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  Card,
  CardContent,
  Button,
  Backdrop,
  Box,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";
import { Page, StatusChip } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
import NumberFormat from "react-number-format";
import { Pagination } from "@material-ui/lab";
import { HeaderTitle } from "layouts";
const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  content: {
    padding: 0,
  },
  fontSize14: {
    fontSize: "14px"
  },
  inner: {
    minWidth: 700,
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  formControl: {},
  mypadding: {
    "& .MuiSelect-outlined": {
      paddingTop: "10.5px",
      paddingBottom: "10.5px",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  select: {
    backgroundColor: "transparent",
    border: "none",
    outline: "none",
    marginTop: "4px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#687083",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },
  title: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "28px",
  },
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
  },
  headText: {
    whiteSpace: "nowrap",
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  myinput: {
    "& .MuiOutlinedInput-input": {
      paddingTop: "11px",
      paddingLeft: "12px",
      paddingRight: "12px",
      paddingBottom: "11px",
    },
  },
  backdrop1: {
    zIndex: 100,
    color: "#fff",
  },
  mydateinput: {
    "& .MuiOutlinedInput-root": {
      paddingTop: "0px",
      paddingBottom: "0px",
      height: "44px",
    },
  },
  inputNum: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px 6px 6px 0px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderLeft: "1px solid #d1d5dc",
      borderRight: "1px solid #d1d5dc",
      borderTop: "1px solid #d1d5dc",
      borderBottom: "1px solid #d1d5dc",
    },
    "& .MuiOutlinedInput-input": {
      paddingTop: "10.5px",
      paddingBottom: "10.5px",
    },
    "& input.Mui-disabled": {
      color: "black",
    },
  },
  activeChip: {
    backgroundColor: "#8DE5AB",
    color: "#34774C",
    fontSize: "14px",
    fontWeight: "500",
  },
  activeChipIndicator: {
    color: "#34774C",
    fontSize: "12px",
  },
  progresChip: {
    backgroundColor: "#f5e340",
    color: "#364052",
    fontSize: "14px",
    fontWeight: "500",
  },
  progresChipIndicator: {
    color: "#c9be5b",
    fontSize: "12px",
  },
  blackButton: {
    backgroundColor: "#111827",
    color: "#FFFFFF",
  },
  nonActiveChip: {
    backgroundColor: "#fa7878",
    color: "#d40202",
    fontSize: "14px",
    fontWeight: "500",
  },
  nonActiveChipIndicator: {
    color: "#d40202",
    fontSize: "12px",
  },
  font16: {
    fontSize: "16px",
  },
  font14: {
    color: "#8f8f8f",
    fontSize: "14px",
  },
  card1: {
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
    boxShadow: "none",
  },
}));

const DetailUpload = props => {
  const classes = useStyles();
  const token = localStorage.getItem("token");
  const [client, setClient] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [listError, setListError] = useState([]);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState({
    loadingPage: false,
    loadingTable: false,
  });
  const { loadingPage } = loading;

  const getDateFormat = date => {
    let newDate = new Date(date);
    let dateOnly = newDate.getDate();
    if (newDate.getDate() < 10) {
      dateOnly = `0${newDate.getDate()}`;
    }
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let monthIs = monthNames[newDate.getMonth()];
    let formatDate = dateOnly + " " + monthIs + " " + newDate.getFullYear(); //prints expected format.
    return formatDate;
  };

  const getMonthYear = date => {
    let newDate = new Date(date);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let monthIs = monthNames[newDate.getMonth()];
    let monthYearOnly = monthIs + " " + newDate.getFullYear();
    return monthYearOnly;
  };

  const handleChangePage = (_, data) => {
    setPage(data || 1);
  };

  useEffect(() => {
    const getData = async () => {
      const urlClientList = `${hardBaseUrl}/process/detail/${props.match.params.id}`;
      setLoading(currentState => ({
        ...currentState,
        loadingPage: true,
      }));
      await axios
        .get(urlClientList, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(async res => {
          const data = res?.data?.data || {};
          setClient(data);
          setActiveTab(data?.process_files[0]?.id);
          setLoading(currentState => ({
            ...currentState,
            loadingPage: false,
          }));
        })
        .catch(err => {
          new Error(err);
          setLoading(currentState => ({
            ...currentState,
            loadingPage: false,
          }));
        });
    };
    getData();
  }, []);

  useEffect(() => {
    const getUrlListError = async process_file_id => {
      const urlListError = `${hardBaseUrl}/process/error_detail`;
      setLoading(currentState => ({
        ...currentState,
        loadingTable: true,
      }));
      const response = await axios.get(urlListError, {
        params: {
          page,
          size,
          sort: "id",
          DESC: "",
          process_id: props.match.params.id,
          search: "",
          process_file_id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setListError(response?.data || []);
      setLoading(currentState => ({
        ...currentState,
        loadingTable: false,
      }));
    };
    getUrlListError(activeTab);
  }, [activeTab, page, size]);
  const pageCount = Math.ceil(listError?.meta?.total / size);
  const statusTypeMap = {
    Finish: "success",
    Error: "danger",
    Started: "warning",
    Initialize: "warning",
    Running: "warning",
  };
  const breadcrumbData = [
    {
      label: "Home",
      link: "/admin/dashboard",
    },
    {
      label: "Upload Data",
      link: "/admin/upload",
    },
    {
      label: "View DSP",
      active: true,
    },
  ];

  return (
    <Page className={classes.root} title="Upload DSP Data">
      <Backdrop className={classes.backdrop1} open={loadingPage}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth={false}>
        <HeaderTitle
          title="View Upload DSP Data"
          breadcrumbData={breadcrumbData}
          backButton
        />
        <Divider className={classes.divider} />
        <Grid container>
          <Box>
            <ThemeProvider theme={theme}>
              <span className={classes.font16}>
                <b>Detail of Upload DSP Data</b>
              </span>
            </ThemeProvider>
          </Box>
        </Grid>
        <Grid container>
          <Box>
            <ThemeProvider theme={theme}>
              <p className={classes.font14}>
                Features to view detail of Upload DSP Data
              </p>
            </ThemeProvider>
          </Box>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={8} lg={8} xl={8}>
            <Card id="card-of-success" className={classes.card1}>
              <CardContent>
                {client?.status === "DONE" ||
                  client?.status === "Waiting" ||
                  client?.status === "In Progress" ||
                  client?.status === "Success" ||
                  client?.status === "Finish" ? (
                  <>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12} lg={12} xl={12}>
                        <Typography className={classes.fontSize14}>
                          File Name
                        </Typography>
                        <Typography className={classes.fontSize14}>
                          {client?.process_files?.find(
                            file => file?.id === activeTab
                          )?.filename || client?.process_name}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={6} lg={6} xl={6}>
                        <Typography className={classes.fontSize14}>
                          Upload ID
                        </Typography>
                        <Typography className={classes.fontSize14}>
                          {client?.id}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6} lg={6} xl={6}>
                        <Typography className={classes.fontSize14}>
                          DSP
                        </Typography>
                        <Typography className={classes.fontSize14}>
                          {client?.header?.dsp?.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6} lg={6} xl={6}>
                        <Typography className={classes.fontSize14}>
                          Upload Date
                        </Typography>
                        <Typography className={classes.fontSize14}>
                          {getDateFormat(client?.created_at)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6} lg={6} xl={6}>
                        <Typography className={classes.fontSize14}>
                          Period
                        </Typography>
                        <Typography className={classes.fontSize14}>
                          {`${getMonthYear(
                            client?.header?.start_date
                          )} - ${getMonthYear(client?.header?.end_date)}`}
                        </Typography>
                      </Grid>

                      <Grid item xs={6} md={12} lg={12} xl={12}>
                        <StatusChip
                          label={client?.status}
                          type={statusTypeMap[client?.status] || ""}
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={6} xl={6}>
                        <Typography className={classes.fontSize14}>
                          Total Revenue
                        </Typography>
                        <NumberFormat
                          displayType="text"
                          value={client?.header?.revenue}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          prefix="Rp "
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={6} xl={6}>
                        <Typography className={classes.fontSize14}>
                          Number of Record
                        </Typography>
                        <NumberFormat
                          displayType="text"
                          value={client?.record_count}
                          thousandSeparator=","
                          thousandsGroupStyle="thousand"
                        />
                      </Grid>

                      <Grid item xs={6} md={6} lg={6} xl={6}>
                        <Typography className={classes.fontSize14}>
                          Publisher Revenue
                        </Typography>
                        <Typography className={classes.fontSize14}>
                          <NumberFormat
                            displayType="text"
                            value={client?.base_currency_publisher_revenue}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            prefix="Rp "
                          />
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={6} lg={6} xl={6}>
                        <Typography className={classes.fontSize14}>
                          Exchange Rate
                        </Typography>
                        <NumberFormat
                          displayType="text"
                          value={client?.header?.properties?.exchange_rate
                          }
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          prefix="Rp "
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={6} xl={6}>
                        <Typography className={classes.fontSize14}>
                          Composer Revenue
                        </Typography>
                        <Typography className={classes.fontSize14}>
                          <NumberFormat
                            value={client.base_currency_composer_revenue || 0}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            prefix="Rp "
                          />
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={6} lg={6} xl={6}>
                        <Typography className={classes.fontSize14}>
                          Transfer Fee
                        </Typography>
                        <NumberFormat
                          value={
                            client?.header?.properties?.transfer_rate || 0
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          prefix="Rp "
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Typography className={classes.fontSize14}>
                          Currency
                        </Typography>
                        <Typography className={classes.fontSize14}>
                          {client?.header?.original_currency?.currency_name}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid container spacing={1}>
                          {(client?.process_files || []).map(
                            ({ id }, index) => {
                              const active = activeTab === id;
                              return (
                                <Grid item key={id}>
                                  <Button
                                    onClick={() => {
                                      setPage(1);
                                      setActiveTab(id);
                                    }}
                                    className={
                                      active ? classes?.blackButton : ""
                                    }
                                    variant="outlined"
                                  >
                                    {`File ${index + 1}`}
                                  </Button>
                                </Grid>
                              );
                            }
                          )}
                        </Grid>
                      </Grid>
                      {listError?.data && (
                        <Grid item xs={12} md={12} lg={12} xl={12}>
                          <Card>
                            <CardContent className={classes.content}>
                              <Box className={classes.inner}>
                                <Table size="small">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>
                                        <Typography
                                          variant="subtitle2"
                                          className={classes.headText}
                                        >
                                          Line Number
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography
                                          variant="subtitle2"
                                          className={classes.headText}
                                        >
                                          Error Detail
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {listError?.data?.length > 0 ? (
                                      <>
                                        {listError?.data?.map(
                                          (customer, idx) => (
                                            <TableRow
                                              key={`${customer.id}-${idx}`}
                                            >
                                              <TableCell>
                                                {customer.line_number}
                                              </TableCell>
                                              <TableCell>
                                                {customer?.error_description}
                                              </TableCell>
                                            </TableRow>
                                          )
                                        )}
                                      </>
                                    ) : (
                                      <TableCell rowSpan={2} colSpan={2}>
                                        <Box display="flex" justifyContent="center">No Data</Box>
                                      </TableCell>
                                    )}
                                  </TableBody>
                                </Table>
                              </Box>
                            </CardContent>
                          </Card>
                          <Grid item>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Box
                                padding="1rem 0px 0px"
                                display="flex"
                                justifyContent="flex-end"
                                alignItems="center"
                              >
                                <Typography>Item per page:</Typography>
                                <Box marginLeft="10px">
                                  <Box className={classes.pageSize}>
                                    <select
                                      className={classes.select}
                                      value={size}
                                      onChange={e => setSize(e?.target?.value)}
                                    >
                                      {[10, 20, 50].map(pageSize => {
                                        return (
                                          <option
                                            key={pageSize}
                                            value={pageSize}
                                          >
                                            {pageSize}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </Box>
                                </Box>
                              </Box>
                              <Box
                                padding="1rem 0px 0px"
                                display="flex"
                                justifyContent="flex-end"
                              >
                                <Pagination
                                  count={pageCount}
                                  shape="rounded"
                                  className={classes.pagination}
                                  onChange={handleChangePage}
                                  page={page}
                                />
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12} lg={12} xl={12}>
                        <Typography className={classes.fontSize14}>
                          File Name
                        </Typography>
                        <Typography className={classes.fontSize14}>
                          {client?.process_files?.find(
                            file => file?.id === activeTab
                          )?.filename || client?.process_name}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={6} lg={6} xl={6}>
                        <Typography className={classes.fontSize14}>
                          Upload ID
                        </Typography>
                        <Typography className={classes.fontSize14}>
                          {client?.id}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6} lg={6} xl={6}>
                        <Typography className={classes.fontSize14}>
                          DSP
                        </Typography>
                        <Typography className={classes.fontSize14}>
                          {client?.header?.dsp?.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6} lg={6} xl={6}>
                        <Typography className={classes.fontSize14}>
                          Upload Date
                        </Typography>
                        <Typography className={classes.fontSize14}>
                          {getDateFormat(client?.created_at)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6} lg={6} xl={6}>
                        <Typography className={classes.fontSize14}>
                          Period
                        </Typography>
                        <Typography className={classes.fontSize14}>
                          {`${getMonthYear(
                            client?.header?.start_date
                          )} - ${getMonthYear(client?.header?.end_date)}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12} xl={12}>
                        <StatusChip
                          label={client?.status}
                          type={statusTypeMap[client?.status] || ""}
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={6} xl={6}>
                        <Typography className={classes.fontSize14}>
                          Total Revenue
                        </Typography>
                        <NumberFormat
                          displayType="text"
                          value={client?.header?.revenue}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          prefix="Rp "
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={6} xl={6}>
                        <Typography className={classes.fontSize14}>
                          Number of Record
                        </Typography>
                        <NumberFormat
                          displayType="text"
                          value={client?.record_count}
                          thousandSeparator=","
                          thousandsGroupStyle="thousand"
                        />
                      </Grid>
                      <Grid item xs={6} md={6} lg={6} xl={6}>
                        <Typography className={classes.fontSize14}>
                          Publisher Revenue
                        </Typography>
                        <Typography className={classes.fontSize14}>
                          <NumberFormat
                            displayType="text"
                            value={client?.base_currency_publisher_revenue}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            prefix="Rp "
                          />
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={6} lg={6} xl={6}>
                        <Typography className={classes.fontSize14}>
                          Exchange Rate
                        </Typography>
                        <NumberFormat
                          displayType="text"
                          value={client?.header?.properties?.exchange_rate}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          prefix="Rp "
                        />
                      </Grid>
                      <Grid item>
                        <Grid container spacing={1}>
                          {(client?.process_files || [])?.map(
                            ({ id }, index) => {
                              const active = activeTab === id;
                              return (
                                <Grid item key={id}>
                                  <Button
                                    onClick={() => {
                                      setPage(1);
                                      setActiveTab(id);
                                    }}
                                    className={
                                      active ? classes?.blackButton : ""
                                    }
                                    variant="outlined"
                                  >
                                    {`File ${index + 1}`}
                                  </Button>
                                </Grid>
                              );
                            }
                          )}
                        </Grid>
                      </Grid>
                      {client?.process_files && (
                        <Grid item xs={12} md={12} lg={12} xl={12}>
                          <Card>
                            <CardContent className={classes.content}>
                              <Box className={classes.inner}>
                                <Table size="small">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>
                                        <Typography
                                          variant="subtitle2"
                                          className={classes.headText}
                                        >
                                          Line Number
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography
                                          variant="subtitle2"
                                          className={classes.headText}
                                        >
                                          Error Detail
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {listError?.data?.map((customer, idx) => (
                                      <TableRow key={`${customer.id}-${idx}`}>
                                        <TableCell>
                                          {customer?.line_number}
                                        </TableCell>
                                        <TableCell>
                                          {customer?.error_description}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </Box>
                            </CardContent>
                          </Card>
                          <Grid item>
                            <Box
                              padding="1rem 0px 0px"
                              display="flex"
                              justifyContent="flex-end"
                            >
                              <Pagination
                                count={pageCount}
                                shape="rounded"
                                className={classes.pagination}
                                onChange={handleChangePage}
                                page={page}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} lg={4} xl={4}></Grid>
        </Grid>
      </Container>
    </Page>
  );
};
export default DetailUpload;
