import {
  FormControl,
  Grid,
  InputAdornment,
  Dialog,
  DialogContent,
  OutlinedInput,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/styles";
import * as Sentry from "@sentry/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { hardBaseUrl } from "services/urlConstant";
import { getErrors } from "utils";
import { InnoTableV2 } from "inno-ui";

const useStyles = makeStyles({
  iconSearch: {
    color: "#9AA2B1"
  }
});

const columns = [
  {
    name: "song_title",
    title: "Contract Number",
    width: 100
  },
  {
    name: "composer_names",
    title: "Song Title",
    width: 100
  },
];

export default function AddSongModal({
  open,
  handleClose,
  startDate = "",
  endDate = "",
  items,
  dsp_id = "",
  composer_id = "",
}) {
  const classes = useStyles();
  const [dataSong, setDataSong] = useState({
    data: [],
    meta: {
      total: 0,
    },
  });
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [selected, setSelected] = useState([]);
  const [dataSearch, setDataSearch] = useState("");

  useEffect(() => {
    const getSongList = () => {
      const token = localStorage.getItem("token");
      const urlSongList = `${hardBaseUrl}/publisher/transaction-song?page=${page +
        1}&size=${size}&dsp_id=${dsp_id}&start_date=${startDate}&end_date=${endDate}&composer_id=${composer_id}&search=${dataSearch}`;
      axios
        .get(urlSongList, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(res => {
          setDataSong(res.data);
        })
        .catch(err => {
          let errMessage = getErrors(err.response);
          Sentry.captureMessage(
            "Error catch /publisher/transaction-song src/components/AddSongModal.js : " +
            errMessage
          );
        });
    };
    getSongList();
  }, [page, size, dataSearch]);

  useEffect(() => {
    setSelected(items);
  }, [items]);

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(selected)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      maxWidth
    >
      <DialogContent>
        <Grid container spacing={2} direction="column">
          <Grid item xs={5}>
            <FormControl fullWidth variant="outlined">
              <OutlinedInput
                placeholder="Search"
                id="outlined-adornment-amount"
                fullWidth={false}
                onChange={e => setDataSearch(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon className={classes.classes} />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item>
            <InnoTableV2
              columns={columns}
              items={dataSong?.data || []}
              isUsingCheckbox
              isLoading={false}
              page={page}
              rowsPerPage={size}
              handleChangePage={setPage}
              handleChangeRowsPerPage={setSize}
              loadingColor=""
            />

          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
