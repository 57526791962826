import { Divider } from "@material-ui/core";
import { Box, Container, Grid } from "@mui/material";
import axios from "axios";
import {
  AutoCompleteComponent,
  CustomTable,
  FormLabel,
  ModalError,
  ModalSuccess,
  Page,
  PrimaryButton,
  SectionLabel,
  SkeletonComponent,
  TextInput,
} from "components";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { hardBaseUrl } from "services/urlConstant";
import { globalStyles } from "styles";
import { getErrors } from "utils";

const FormParameterPerformer = ({ userLogin }) => {
  const classes = globalStyles();
  const history = useHistory();
  const { id } = useParams();
  const isFirstLoad = useRef(true);
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const pageTitle = `${id ? "Edit" : "Add"} Performer`;
  const userRole = userLogin?.role?.type;
  const formPublisher =
    userRole === "association" || (userRole === "society" && !id);

  const [loadingPage, setLoadingPage] = useState(false);
  const [optionPublisher, setOptionPublisher] = useState([]);
  const [payload, setPayload] = useState({
    first_name: "",
    nik: "",
    publisher_id: userLogin?.publisher?.publisher_id || 0,
  });
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    size: 10,
    search: "",
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);

  const handleChangePayload = (value, key) => {
    setPayload(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };

  const getOptionPublisher = async () => {
    try {
      const res = await axios.get(`${hardBaseUrl}/publisher`, {
        headers,
      });
      const modifiedData = res?.data?.data?.map(item => ({
        ...item,
        id: item?.publisher_id,
        label: item?.name,
      }));
      setOptionPublisher(modifiedData);
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    } finally {
      setLoadingPage(false);
    }
  };
  const getDetail = async () => {
    try {
      const res = await axios.get(`${hardBaseUrl}/publisher/performer/${id}`, {
        headers,
      });
      setPayload(res?.data?.data);
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    }
  };
  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/performers/${id}/song`, {
        headers,
        params: queryParams,
      });
      const { data, meta } = res?.data;
      setDataTable(data);
      const pageCount = getTotalPage(meta?.total, queryParams?.size);
      setTableTotalPage(pageCount || 1);
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    } finally {
      setLoadingPage(false);
    }
  };
  const handleSubmit = async () => {
    const method = id ? axios.put : axios.post;
    const { nik, first_name, publisher_id } = payload;
    const modifiedPayload = {
      nik,
      first_name,
      publisher_id,
      is_active_flag: true,
    };
    try {
      setLoadingPage(true);
      await method(
        `${hardBaseUrl}/publisher/performer${id ? `/${id}` : ""}`,
        modifiedPayload,
        { headers }
      );
      ModalSuccess(
        `Successfully ${id ? "update" : "save"} performer`
      ).then(() => history.goBack());
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };

  const fetchData = async () => {
    setLoadingPage(true);
    const promises = id ? [getDetail(), getDataTable()] : [];
    if (formPublisher) promises.push(getOptionPublisher());

    try {
      await Promise.all(promises);
    } catch (error) {
      ModalError(error, "Error fetching data");
    } finally {
      setLoadingPage(false);
      isFirstLoad.current = false;
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (id && !isFirstLoad?.current) {
      getDataTable();
    }
  }, [queryParams]);

  return (
    <Page className={classes.root} title={pageTitle}>
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title={pageTitle}
            breadcrumbData={breadcrumbData({ pageTitle })}
            backButton
          />
          <Divider className={classes?.divider} />
          <SectionLabel
            title="Performer Information"
            subTitle={
              id
                ? "To edit performer's data"
                : "Please fill out the form below to add a new performer"
            }
          />
          <Box width="70%">
            <Box
              border="1px solid #ebebeb"
              borderRadius="5px"
              p="24px"
              my="16px"
            >
              <Grid container columnSpacing={2} rowSpacing={1}>
                <Grid item xs={12} md={6}>
                  <FormLabel label="NIK" />
                  <TextInput
                    placeholder="NIK"
                    value={payload?.nik}
                    onChange={e => handleChangePayload(e?.target?.value, "nik")}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormLabel label="Perfomer Name" required />
                  <TextInput
                    placeholder="Perfomer Name"
                    value={payload?.first_name}
                    onChange={e =>
                      handleChangePayload(e?.target?.value, "first_name")
                    }
                  />
                </Grid>
                {formPublisher && (
                  <Grid item xs={12} md={6}>
                    <FormLabel label="Publisher" required />
                    <AutoCompleteComponent
                      placeholder="Publisher"
                      options={optionPublisher}
                      value={
                        optionPublisher.find(
                          option => option.id === Number(payload?.publisher_id)
                        ) || null
                      }
                      onChange={value =>
                        handleChangePayload(value, "publisher_id")
                      }
                      size="small"
                      width="100%"
                      disabled={id}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
            {id && (
              <Box my="16px">
                <SectionLabel
                  title="Song Detail"
                  subTitle="See song detail's of Performer"
                  my="16px"
                />
                <CustomTable
                  columnTable={[
                    {
                      name: "song_title",
                      title: "Song",
                      renderText: item => item || "-",
                    },
                  ]}
                  rowClick={item =>
                    history.push({
                      pathname: `/admin/parameter/lagu/edit/${item?.song_id}`,
                      state: {
                        customer: item,
                      },
                    })
                  }
                  data={dataTable}
                  page={queryParams?.page}
                  pageSize={queryParams?.size}
                  pageCount={tableTotalPage}
                  handleChangePage={(_, page) =>
                    handleChangeQueryParams(page, "page")
                  }
                  handleChangePageSize={e =>
                    handleChangeQueryParams(e?.target?.value, "size")
                  }
                />
              </Box>
            )}
            <Grid container justifyContent="right">
              <PrimaryButton label="Save" onClick={handleSubmit} />
            </Grid>
          </Box>
        </Container>
      )}
    </Page>
  );
};

const breadcrumbData = ({ pageTitle }) => [
  {
    label: "Parameter",
    link: "/admin/parameter/performer",
  },
  {
    label: "Performer",
    link: "/admin/parameter/performer",
  },
  {
    label: pageTitle,
    active: true,
  },
];
export default FormParameterPerformer;
