import { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  Box,
  Divider
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { HeaderTitle } from "layouts";
import { useParams } from "react-router-dom";
import { Page } from "components";
import { InnoImage } from "inno-ui";
import { hardBaseUrl } from "services/urlConstant";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  label: {
    fontWeight: 500,
    fontSize: "14px",
    color: "#364052",
    fontStyle: "normal",
    lineHeight: "20px",
    marginBottom: "10px",
  },
  flatDivider: {
    margin: "10px 0px 30px 0px"
  },
  imageWrapper: {
    borderRadius: 5,
    backgroundColor: "#F9FAFB",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    alignItems: "center",
    padding: "1rem"
  },
  imgPreview: {
    maxWidth: "150px",
    maxHeight: "150px",
  },
  body: {
    fontWeight: 400,
    fontSize: "16px",
    color: "#111827",
    lineHeight: "24px",
    marginBottom: "10px",
  },
  title: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
    lineHeight: "28px",
  },
  subTitle: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#6e6e6e",
  },
  breadCrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  fieldTitle: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
    textAlign: "left",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none"
  },
  fieldSubTitle: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    textAlign: "left",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none"
  },
  button: {
    marginBottom: "20px",
    marginTop: "20px",
    backgroundColor: "black",
    color: "white",
    width: "135px",
    height: "40px",
    textTransform: "none",
    "&:hover": {
      color: "white",
      backgroundColor: "black",
    },
  },
  font24: {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "32px",
  },
  hr: {
    border: "1px solid #E4E7EB",
    marginTop: "32px",
  },
  mt32: {
    marginTop: "32px",
  },
  textStyle: {
    marginTop: "5px",
    color: "#8f8f8f",
    fontSize: "14px",
  },
  card: {
    border: "1px solid #9AA2B1",
    borderRadius: "6px",
    boxShadow: "none",
  },
  divContainer: {
    width: "48px",
    height: "24px",
    marginTop: "5px",
    marginBottom: "10px",
  },
  imgStyle: {
    width: "160px",
    height: "160px",
    objectFit: "cover",
    border: "1px solid #D1D5DC",
  },
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
  },
  mt45: {
    marginTop: "45px",
  },
  mt20: { marginTop: "20px" },
}));

function DetailDSP() {
  const classes = useStyles();
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const [dataDSP, setDataDSP] = useState({
    clientName: "",
    logo: "",
    color: "",
    person_name: "",
    email: "",
    phone: "",
    publisherId: "",
    clientType: "",
    domicile: "",
    clientClassification: "",
    address: "",
    transfer_fee: "",
    currency: ""
  });
  const {
    clientName,
    logo,
    color,
    person_name,
    email, phone,
    clientType,
    domicile,
    clientClassification,
    address,
    transfer_fee,
    currency
  } = dataDSP;

  const fetchListClient = async (clientId) => {
    try {
      const url = `${hardBaseUrl}/publisher/classification/index`;
      const response = await axios.get(url, { headers });
      const data = response?.data?.data || [];
      return data.find((item) => item?.client_classification_id === clientId) || "-";
    } catch (err) {
      new Error(err);
      return "-";
    }
  };

  const fetchDetail = async () => {
    try {
      const url = `${hardBaseUrl}/dsp/${id}`;
      const response = await axios.get(url, { headers });
      const data = response?.data?.data;
      const clientType = await fetchListClient(data?.client_classification?.client_classification_id);
      setDataDSP((dataDsp) => ({
        ...dataDsp,
        clientName: data?.name,
        domicile: data?.domicile,
        logo: data?.profile_image,
        color: data?.color,
        clientType,
        person_name: data?.person_name,
        email: data?.person_email,
        phone: data?.person_phone,
        publisherId: data?.publisherId,
        clientClassification: data?.client_classification?.classification_name,
        address: data?.address,
        transfer_fee: data?.transaction_fee,
        currency: data?.fee_currency?.currency_iso_code,
      }));
    } catch (err) {
      new Error(err);
    }
  };

  useEffect(() => {
    fetchDetail();
  }, []);

  const breadcrumbData = [
    {
      label: "Home",
    },
    {
      label: "Parameter",
      link: "/admin/parameter",
    },
    {
      label: "DSP",
      link: "/admin/parameter/dsp",
    },
    {
      label: "Detail DSP",
      active: true
    }
  ];

  return (
    <Page className={classes.root} title="Detail Client">
      <Container maxWidth={false}>
        <HeaderTitle backButton title="Detail DSP" breadcrumbData={breadcrumbData} />
        <Divider className={classes?.flatDivider} />
        <Grid container spacing={3}>
          <Grid item>
            <Box display="grid" gridGap={10}>
              <Typography variant="h5" className={classes.title}>
                DSP Information
              </Typography>
              <Typography variant="subtitle1" className={classes.subTitle}>
                Information about DSP that has been joined to us
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Box display="grid" gridGap={5}>
                  <Typography className={classes.fieldTitle}>
                    Client Type
                  </Typography>
                  <Typography className={classes.fieldSubTitle}>
                    {clientType?.classification_name}
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box display="grid" gridGap={5}>
                  <Typography className={classes.fieldTitle}>
                    Client Classification
                  </Typography>
                  <Typography className={classes.fieldSubTitle}>
                    {clientClassification}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box display="grid" gridGap={5}>
                      <Typography className={classes.fieldTitle}>
                        Client Name
                      </Typography>
                      <Typography className={classes.fieldSubTitle}>
                        {clientName}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box display="grid" gridGap={5}>
                      <Typography className={classes.fieldTitle}>
                        Domicile
                      </Typography>
                      <Typography className={classes.fieldSubTitle}>
                        {domicile}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="grid" gridGap={5}>
                      <Typography className={classes.fieldTitle}>
                        Color Identify
                      </Typography>
                      <Box width={50} height={25} bgcolor={color} />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="grid" gridGap={5}>
                      <Typography className={classes.fieldTitle}>
                        Logo
                      </Typography>
                      <Box className={classes.imageWrapper} bgcolor="#F9FAFB">
                        {logo &&
                          <InnoImage
                            src={logo}
                            alt="Preview"
                            className={classes.imgPreview}
                            styleImageContainer={{
                              border: "1px solid #D1D5DC",
                              borderRadius: "6px",
                              padding: 0,
                            }}
                            styleImage={{
                              maxWidth: "150px",
                              maxHeight: "150px",
                            }}
                            fill={true}
                          />
                        }
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Box display="grid" gridGap={5}>
              <Typography variant="h5" className={classes.title}>
                PIC
              </Typography>
              <Typography variant="h5" className={classes.subTitle}>
                Information about PIC
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box display="grid" gridGap={5}>
                      <Typography className={classes.fieldTitle}>
                        PIC Name
                      </Typography>
                      <Typography className={classes.fieldSubTitle}>
                        {person_name}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box display="grid" gridGap={5}>
                      <Typography className={classes.fieldTitle}>
                        Phone Number
                      </Typography>
                      <Typography className={classes.fieldSubTitle}>
                        {phone}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="grid" gridGap={5}>
                      <Typography className={classes.fieldTitle}>
                        Email
                      </Typography>
                      <Typography className={classes.fieldSubTitle}>
                        {email}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="grid" gridGap={5}>
                      <Typography className={classes.fieldTitle}>
                        Address
                      </Typography>
                      <Typography className={classes.fieldSubTitle}>
                        {address}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Box display="grid" gridGap={5}>
              <Typography variant="h5" className={classes.title}>
                Transfer Fee & Currency
              </Typography>
              <Typography variant="h5" className={classes.subTitle}>
                To Settings Transfer Fee & Currency of Client
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box display="grid" gridGap={5}>
                      <Typography className={classes.fieldTitle}>
                        Currency
                      </Typography>
                      <Typography className={classes.fieldSubTitle}>
                        {currency}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box display="grid" gridGap={5}>
                      <Typography className={classes.fieldTitle}>
                        Transfer Fee
                      </Typography>
                      <Typography className={classes.fieldSubTitle}>
                        {transfer_fee}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default DetailDSP;